import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import EmpDashHeader from '../Components/EmpDashHeader';
import EmpDashSideBar from '../Components/EmpDashSideBar';
import { Container, Row, Col } from 'react-bootstrap';
import { GoArrowUp } from "react-icons/go";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { colourOptions } from '../Functions/data.ts';
import { TfiBag } from "react-icons/tfi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { CiClock2 } from "react-icons/ci";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import companyLogo from '../Assets/Images/feactureImg.png';
import { SlHome } from "react-icons/sl";
import { LuUser2 } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineBell } from "react-icons/ai";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import CandiDashSideMenu from '../Components/CandiDashSideMenu';
import logo from '../Assets/Images/logo.png';
import { DashCopyRightFooter } from '../Components/Footer.jsx';

const MyProfile = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <EmpDashHeader />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>
                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <CandiDashSideMenu />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>My Profile!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/candidate/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/my/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> My Profile </span></NavLink></li>
                                        <li><NavLink to={'/my/resume'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> My Resume </span></NavLink></li>
                                        <li><NavLink to={'/job/recommendation'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Recommendation </span></NavLink></li>
                                        <li><NavLink to={'/applied/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Applied Jobs </span></NavLink></li>
                                        <li><NavLink to={'/Job/Alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Alerts </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/jobs'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Jobs </span></NavLink></li>
                                        <li><NavLink to={'/cv/manager'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> CV Manager </span></NavLink></li>
                                        <li><NavLink to={'/candidate/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        {/* Candidate Profile */}
                        <div className="empdash-profile-sec">

                            <h5 className='empdash-profile-heading'>My Profile</h5>

                            {/* Uploading browse Logo */}
                            <div className='dash-uploading-cover-outer'>

                                <label htmlFor="upload" className="dash-upload-file">
                                    <input type="file" className='dash-upload-file-btn' name="attachments[]" id="upload" accept='image/*' required />
                                    <span id='upload' className='dash-browse-logo'>
                                        <GoArrowUp className='dash-upload-i' />
                                        <span style={{ color: 'black' }}> Browse Logo </span>
                                    </span>
                                </label>
                                <div className="dash-upload-caution">
                                    Max file size is 1MB, Minimum dimension: 330x300 And Suitable files are .jpg & .png
                                </div>

                            </div>

                            {/* Candidate details inputs */}
                            <form>
                                <Row>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="userName">Full Name</label>
                                        <input type="text" name="userName" id="userName" placeholder='Full Name' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="jobtitle">Job Title</label>
                                        <input type="text" name="jobTitle" id="jobtitle" placeholder='UI Designer' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="userName">Phone</label>
                                        <input type="text" name="" id="userName" placeholder='Phone' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="userEmail">Email address</label>
                                        <input type="email" name="userEmail" id="userEmail" placeholder='Email address' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="website">Website</label>
                                        <input type="text" name="website" id="website" placeholder='Website' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={3} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="currentSalary">Current Salary($)</label>
                                        <Form.Select aria-label="Default select example" id='currentSalary' className="b-dtls-name">
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                        </Form.Select>
                                    </Col>
                                    <Col lg={3} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="expectedSalary">Expected Salary($)</label>
                                        <Form.Select aria-label="Default select example" id='expectedSalary' className="b-dtls-name">
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                        </Form.Select>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="experience">Experience</label>
                                        <input type="text" name="experience" id="experience" placeholder='experience' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="age">Age</label>
                                        <Form.Select aria-label="Default select example" id='age' className="b-dtls-name">
                                            <option value="">23-27 Years</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                            <option value="">50-70k</option>
                                        </Form.Select>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="educationLevels">Education Levels</label>
                                        <input type="text" name="educationLevels" id="educationLevels" placeholder='educationLevels' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="languages">Languages</label>
                                        <input type="text" name="languages" id="languages" placeholder='languages' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="categories">Categories</label>
                                        <Select
                                            defaultValue={[colourOptions[2], colourOptions[3]]}
                                            isMulti
                                            id='categories'
                                            name="colors"
                                            options={colourOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="searchListing">Allow In Search & Listing</label>
                                        <Form.Select aria-label="Default select example" id='searchListing' className="b-dtls-name">
                                            <option value="">Yes</option>
                                            <option value="">No</option>
                                        </Form.Select>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="description">Description</label>
                                        <textarea type="text" id='description' className='b-dtls-message' placeholder='Write your message...' required />
                                    </Col>
                                    <Col lg={3} md={3} sm={3} className="b-thoughts">
                                        <button type='submit' className='b-dtls-send-msg dash-save-btn'>Save</button>
                                    </Col>
                                </Row>
                            </form>

                        </div>

                        {/* Social Network */}
                        <div className="empdash-s-network-sec">

                            <h5 className='empdash-profile-heading'>Social Network</h5>
                            <form>
                                <Row>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="Facebook">Facebook</label>
                                        <input type="text" name="" id="Facebook" placeholder='www.facebook.com/company' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="Twitter">Twitter</label>
                                        <input type="email" name="userEmail" id="Twitter" placeholder='Twitter' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="linkedin">Linkedin</label>
                                        <input type="text" name="" id="linkedin" placeholder='Linkedin' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="Google-Plus">Google Plus</label>
                                        <input type="email" name="Google Plus" id="" placeholder='Google-Plus' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={3} md={3} sm={3} className="b-thoughts">
                                        <button type='submit' className='b-dtls-send-msg dash-save-btn'>Save</button>
                                    </Col>
                                </Row>
                            </form>

                        </div>

                        {/* Contact Information */}
                        <div className='empdash-contact-sec'>

                            <h5 className='empdash-profile-heading'>Contact Information</h5>

                            <form>
                                <Row>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="country">Country</label>
                                        <Form.Select aria-label="Default select example" id='country' className="b-dtls-name">
                                            <option value="">Pakistan</option>
                                            <option value="">India</option>
                                            <option value="">Bangladesh</option>
                                            <option value="">Srilanka</option>
                                            <option value="">Turkey</option>
                                            <option value="">America</option>
                                        </Form.Select>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="city">City</label>
                                        <Form.Select aria-label="Default select example" id='city' className="b-dtls-name">
                                            <option value="">Pakistan</option>
                                            <option value="">India</option>
                                            <option value="">Bangladesh</option>
                                            <option value="">Srilanka</option>
                                            <option value="">Turkey</option>
                                            <option value="">America</option>
                                        </Form.Select>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="Complete-Address">Complete Address</label>
                                        <input type="text" name="" id="Complete-Address" placeholder='Complete Address' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="Find-On-Map">Find On Map</label>
                                        <input type="text" name="Find-On-Map" id="Find-On-Map" placeholder='Find On Map' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={3} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="Latitude">Latitude</label>
                                        <input type="email" name="Latitude" id="Latitude" placeholder='Latitude' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={3} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="Longitude">Longitude</label>
                                        <input type="email" name="userEmail" id="" placeholder='Longitude' className='b-dtls-name' required />
                                    </Col>
                                    <Col lg={3} md={3} sm={3} className="b-thoughts">
                                        <button type='submit' className='dash-loc-search-btn'>Search Location</button>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} className="b-thoughts">
                                        {/* ----------------- Google Map -------------- */}
                                        <div className="dash-contact-map">

                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.0360503765!2d-74.30932697674075!3d40.
                                                69753996663266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62
                                                !2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1712404123957!5m2!1sen!2s"
                                                width="600" height="450" style={{ border: 0 }} allowFullScreen loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            >
                                            </iframe>

                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} className="b-thoughts">
                                        <button type='submit' className='b-dtls-send-msg dash-save-btn'>Save</button>
                                    </Col>

                                </Row>
                            </form>

                        </div>

                    </div>

                    <DashCopyRightFooter />

                </Col>
            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default MyProfile