import GridViewIcon from "@mui/icons-material/GridView";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AiOutlineClose } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { IoAddOutline, IoSearchOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { PiShoppingBagOpen } from "react-icons/pi";
import { RiFilter3Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import accountImg from "../Assets/Images/accCreateImg.svg";
import { default as candidate } from "../Assets/Images/candidate-1.png";
import logo from "../Assets/Images/logo.png";
import { CopyRightFooter, FooterSection } from "../Components/Footer";
import NavBar from "../Components/Header";
const jobInfoData = [
  { icon: <PiShoppingBagOpen className="i-display" />, text: "Segment" },
  { icon: <PiShoppingBagOpen className="i-display" />, text: "Segment" },
  { icon: <PiShoppingBagOpen className="i-display" />, text: "Segment" },
  { icon: <PiShoppingBagOpen className="i-display" />, text: "Segment" },
];

const jobOtherInfoData = [
  { className: "time", text: "Time" },
  { className: "privacy", text: "Private" },
  { className: "required", text: "Urgent" },
];
const JobCard = React.memo(({ accountImg, name }) => {
  return (
    <section className="job-card">
      <div className="card-content ps-20 ">
        <span className="comp-logo top-2">
          <img src={accountImg} alt="company logo" />
        </span>
        <h4>
          <Link to={"/job/single"} className="card-title">
            {name}
          </Link>
        </h4>
        <ul className="job-info">
          {jobInfoData.map((info, index) => (
            <li key={index} className="info-list">
              {info.icon}
              <span>{info.text}</span>
            </li>
          ))}
        </ul>
        <ul className="job-other-info">
          {jobOtherInfoData.map((info, index) => (
            <li key={index} className={info.className}>
              {info.text}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
});
const CustomCard = ({ layout, candiImg, name }) => (
  <>
    {layout === "grid" ? (
      <Col lg={6} md={6} sm={12} className="cards-col">
        <div
          className={`company-inner-card ${
            layout === "grid" ? "grid" : "list"
          }`}
        >
          <div className="comp-center-details">
            <div className="card-featu">Featured</div>
            <div className="details-sec">
              <img src={candiImg} alt="Company Logo" />
              <Link to={"/candidates/Single"} className="candidate-name">
                {name}
              </Link>
              <span className="candi-prof">UI Designer</span>
              <div className="candi-flex space-x-7">
                <div className="candi-flex-tags space-x-2">
                  <CiLocationOn className="candi-card-i" />
                  <span className="candi-details">London, UK</span>
                </div>
                <div className="candi-flex-tags space-x-2">
                  <LiaMoneyBillWaveSolid className="candi-card-i" />
                  <span className="candi-details">$99 / hour</span>
                </div>
              </div>
            </div>
          </div>
          {/* <ul className="candidates-tags">
       
            <li className="candi-tag">
              <Link to={"/"} className="candi-link">
                App
              </Link>
            </li>
            <li className="candi-tag">
              <Link to={"/"} className="candi-link">
                Design
              </Link>
            </li>
            <li className="candi-tag">
              <Link to={"/"} className="candi-link">
                Digital
              </Link>
            </li>
          </ul> */}
          <ul className="job-other-info candidates-tags">
            {jobOtherInfoData.map((info, index) => (
              <li key={index} className={info.className}>
                {info.text}
              </li>
            ))}
          </ul>
          <Link to={"/candidates/Single"} className="v-pro-btn j-post_btn">
            View Profile
          </Link>
        </div>
      </Col>
    ) : (
      <section className="job-card">
        <div className="card-content ps-20 ">
          <span className="comp-logo top-2">
            <img src={candiImg} alt="company logo" />
          </span>
          <h4>
            <Link to={"/job/single"} className="card-title">
              {name}
            </Link>
          </h4>
          <ul className="job-info">
            {jobInfoData.map((info, index) => (
              <li key={index} className="info-list">
                {info.icon}
                <span>{info.text}</span>
              </li>
            ))}
            <li
              className="info-list "
              style={{ position: "absolute", right: "10px" }}
            >
              <Link className="j-post_btn" to={"/candidates/Single"}>
                {" "}
                View Profile
              </Link>
            </li>
          </ul>
          <ul className="job-other-info">
            {jobOtherInfoData.map((info, index) => (
              <li key={index} className={info.className}>
                {info.text}
              </li>
            ))}
          </ul>
        </div>
      </section>
    )}
  </>
);
const Workers = () => {
  let [quantityJob, setQuantityJob] = useState("all");
  let [sideFilterToggle, setSideFilterToggle] = useState(false);
  const [value1, setValue1] = useState([20, 5000]);
  const navigate = useNavigate();
  const valuetext = (value) => {
    return `$${value}`;
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };
  let viewProfile = () => {
    navigate("/candidates/Single");
  };
  const [layout, setLayout] = useState("grid");

  const toggleLayout = () => {
    setLayout((prevLayout) => (prevLayout === "grid" ? "list" : "grid"));
  };
  const cards = [
    <>
      <CustomCard layout={layout} candiImg={candidate} name={"Clorus"} />
      <CustomCard layout={layout} candiImg={accountImg} name={"Flora"} />
      <CustomCard layout={layout} candiImg={candidate} name={"Yoori"} />
      <CustomCard layout={layout} candiImg={accountImg} name={"Joy"} />
      <CustomCard layout={layout} candiImg={candidate} name={"John"} />
    </>,
  ];
  return (
    <div>
      {/* ----------------- Header start -------------- */}
      <NavBar />
      {/* *************** Header end ***************** */}

      {/* <section className='search-box'>

        <Container>
          <SearchBar />
        </Container>

      </section> */}

      <section className="job-sec">
        <Container>
          {/* ---------- FILTER SIDEBAR START -------- */}

          {sideFilterToggle && (
            <div className="flex-filter-outer">
              {/* ---------- Menu filter START -------- */}
              <div className="f-j-filter-header">
                <Link to={"/"}>
                  <img src={logo} alt="Logo" className="nav__logo" />
                </Link>

                <div
                  className="close-inner"
                  onClick={() => setSideFilterToggle(false)}
                >
                  <AiOutlineClose />
                </div>
              </div>

              {/* ---------- Job type START -------- */}
              <section className="job-typ">
                <span className="f-titles">Job type</span>
                <ul className="filter-list">
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="freelancer"
                        label="Freelancer"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="fulltime"
                        label="Full Time"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="parttime"
                        label="Part time"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="temporary"
                        label="Temporary"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                </ul>
              </section>
              {/* ---------- Date Posted START -------- */}
              <section className="job-typ">
                <span className="f-titles">Date Posted</span>
                <ul className="filter-list">
                  <li className="job-list flex-start">
                    <div className="radio-sec space-x-2">
                      <input
                        type="radio"
                        name="datePosted"
                        value=""
                        id="all"
                        className="radio-crsr m-0"
                      />
                      <label htmlFor="all" className="radio-crsr">
                        All
                      </label>
                    </div>
                  </li>
                  <li className="job-list">
                    <div className="radio-sec space-x-2">
                      <input
                        type="radio"
                        name="datePosted"
                        value=""
                        id="lasthour"
                        className="radio-crsr m-0"
                      />
                      <label htmlFor="lasthour" className="radio-crsr">
                        LastHour
                      </label>
                    </div>
                  </li>
                  <li className="job-list flex-start">
                    <div className="radio-sec space-x-2">
                      <input
                        type="radio"
                        name="datePosted"
                        value=""
                        id="last24"
                        className="radio-crsr m-0"
                      />
                      <label htmlFor="last24" className="radio-crsr">
                        Last24Hour
                      </label>
                    </div>
                  </li>
                  <li className="job-list flex-start">
                    <div className="radio-sec space-x-2">
                      <input
                        type="radio"
                        name="datePosted"
                        value=""
                        id="last7"
                        className="radio-crsr m-0"
                      />
                      <label htmlFor="last7" className="radio-crsr">
                        Last7Days
                      </label>
                    </div>
                  </li>
                  <li className="job-list flex-start">
                    <div className="radio-sec space-x-2">
                      <input
                        type="radio"
                        name="datePosted"
                        value=""
                        id="last14"
                        className="radio-crsr m-0"
                      />
                      <label htmlFor="last14" className="radio-crsr">
                        Last14Days
                      </label>
                    </div>
                  </li>
                  <li className="job-list flex-start">
                    <div className="radio-sec space-x-2">
                      <input
                        type="radio"
                        name="datePosted"
                        value=""
                        id="last30"
                        className="radio-crsr m-0"
                      />
                      <label htmlFor="last30" className="radio-crsr">
                        Last30Days
                      </label>
                    </div>
                  </li>
                </ul>
              </section>
              {/* ---------- Experience Level SECTION START -------- */}
              <section className="job-typ">
                <span className="f-titles">Experience Level</span>
                <ul className="filter-list">
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="fresh"
                        label="Fresh"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="year1"
                        label="1 Year"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="year2"
                        label="2 Year"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="year3"
                        label="3 Year"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                  <li className="job-list flex-start">
                    <div>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="year4"
                        label="4 Year"
                      />
                    </div>
                    {/* <span>Freelancer</span> */}
                  </li>
                  <li className="job-list flex-start">
                    <Link to="/" className="v-more space-x-3">
                      <div className="v-more-icon">
                        <IoAddOutline />
                      </div>
                      <span>View More</span>
                    </Link>
                  </li>
                </ul>
              </section>
              {/* ---------- Salary SECTION START -------- */}
              <section className="range">
                <span className="f-titles">Salary</span>
                <Box sx={{ width: "100%" }}>
                  <Slider
                    getAriaLabel={() => "Minimum distance"}
                    value={value1}
                    onChange={handleChange1}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    disableSwap
                  />
                </Box>
              </section>
              {/* ---------- TAGS SECTION START -------- */}
              <section className="tags">
                <span className="f-titles tag-h">Tags</span>

                <ul className="filter-list flex-tag">
                  <li className="job-list tags-li">App</li>
                  <li className="job-list tags-li">Administrative</li>
                  <li className="job-list tags-li">Android</li>
                  <li className="job-list tags-li">Wordpress</li>
                  <li className="job-list tags-li">Design</li>
                  <li className="job-list tags-li">React</li>
                </ul>
                <br />
                <br />
              </section>

              {/* ---------- ADS SECTION START -------- */}
              <div className="below-filter">
                <h5 className="f-titles">Recruiting?</h5>
                <p className="ads-txt">
                  Advertise your jobs to millions of monthly users and search
                  15.8 million CVs in our database.
                </p>
                <button className="Recruiting-btn">Start Recruiting Now</button>
                <div className="ads-side-img">
                  {/* <img src={addsImg} alt="Ads Img" className='ads-img' /> */}
                </div>
              </div>
              <br />
              <br />
            </div>
          )}

          {/* ************* FILTER SIDEBAR END **************** */}

          <Row>
            {/* ---------- SIDE FILTER START -------- */}
            <Col lg={4} className="space-y-10 side-hide">
              <div className="flex-filter">
                {/* ---------- Job type START -------- */}
                <section className="job-typ">
                                <span className='f-titles'>Search by keywords</span>
                                <ul className="filter-list">

                                    <li className="job-list flex-start">
                                        <div className="candi-list-filter-inp">
                                            <IoSearchOutline id="location-icon" style={{ fontSize: 20 }} />
                                            <input
                                                type="text"
                                                placeholder='Job title, keywords or company '
                                                className="candi-list-filter-inp-txt ml-2 box-txt"
                                                name="location"
                                            // onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                        {/* <span>Freelancer</span> */}
                                    </li>

                                </ul>
                            </section>
                            {/* ---------- Location START -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Location</span>
                                <ul className="filter-list">

                                    <li className="job-list flex-start">
                                        <div className="candi-list-filter-inp">
                                            <CiLocationOn id="location-icon" />
                                            <input
                                                type="text"
                                                placeholder='Job title, keywords or company '
                                                className="candi-list-filter-inp-txt ml-2 box-txt"
                                                name="location"
                                            // onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                        {/* <span>Freelancer</span> */}
                                    </li>

                                </ul>
                                <p className='radius-txt'>Radius around selected destination</p>
                                <Box sx={{ width: '95%' }}>
                                    <Slider
                                        getAriaLabel={() => 'Minimum distance'}
                                        value={value1}
                                        onChange={handleChange1}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                        disableSwap
                                    />
                                </Box>

                            </section>
                <section className="job-typ">
                  <span className="f-titles">Job type</span>
                  <ul className="filter-list">
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="freelancer"
                          label="Freelancer"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="fulltime"
                          label="Full Time"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="parttime"
                          label="Part time"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="temporary"
                          label="Temporary"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                  </ul>
                </section>
                {/* ---------- Date Posted START -------- */}
                <section className="job-typ">
                  <span className="f-titles">Date Posted</span>
                  <ul className="filter-list">
                    <li className="job-list flex-start">
                      <div className="radio-sec space-x-2">
                        <input
                          type="radio"
                          name="datePosted"
                          value=""
                          id="all"
                          className="radio-crsr m-0"
                        />
                        <label htmlFor="all" className="radio-crsr">
                          All
                        </label>
                      </div>
                    </li>
                    <li className="job-list">
                      <div className="radio-sec space-x-2">
                        <input
                          type="radio"
                          name="datePosted"
                          value=""
                          id="lasthour"
                          className="radio-crsr m-0"
                        />
                        <label htmlFor="lasthour" className="radio-crsr">
                          LastHour
                        </label>
                      </div>
                    </li>
                    <li className="job-list flex-start">
                      <div className="radio-sec space-x-2">
                        <input
                          type="radio"
                          name="datePosted"
                          value=""
                          id="last24"
                          className="radio-crsr m-0"
                        />
                        <label htmlFor="last24" className="radio-crsr">
                          Last24Hour
                        </label>
                      </div>
                    </li>
                    <li className="job-list flex-start">
                      <div className="radio-sec space-x-2">
                        <input
                          type="radio"
                          name="datePosted"
                          value=""
                          id="last7"
                          className="radio-crsr m-0"
                        />
                        <label htmlFor="last7" className="radio-crsr">
                          Last7Days
                        </label>
                      </div>
                    </li>
                    <li className="job-list flex-start">
                      <div className="radio-sec space-x-2">
                        <input
                          type="radio"
                          name="datePosted"
                          value=""
                          id="last14"
                          className="radio-crsr m-0"
                        />
                        <label htmlFor="last14" className="radio-crsr">
                          Last14Days
                        </label>
                      </div>
                    </li>
                    <li className="job-list flex-start">
                      <div className="radio-sec space-x-2">
                        <input
                          type="radio"
                          name="datePosted"
                          value=""
                          id="last30"
                          className="radio-crsr m-0"
                        />
                        <label htmlFor="last30" className="radio-crsr">
                          Last30Days
                        </label>
                      </div>
                    </li>
                  </ul>
                </section>
                {/* ---------- Experience Level SECTION START -------- */}
                <section className="job-typ">
                  <span className="f-titles">Experience Level</span>
                  <ul className="filter-list">
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="fresh"
                          label="Fresh"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="year1"
                          label="1 Year"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="year2"
                          label="2 Year"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="year3"
                          label="3 Year"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                    <li className="job-list flex-start">
                      <div>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="year4"
                          label="4 Year"
                        />
                      </div>
                      {/* <span>Freelancer</span> */}
                    </li>
                    <li className="job-list flex-start">
                      <Link to="/" className="v-more space-x-3">
                        <div className="v-more-icon">
                          <IoAddOutline />
                        </div>
                        <span>View More</span>
                      </Link>
                    </li>
                  </ul>
                </section>
                {/* ---------- Salary SECTION START -------- */}
                <section className="range">
                  <span className="f-titles">Salary</span>
                  <Box sx={{ width: "100%" }}>
                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={value1}
                      onChange={handleChange1}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      disableSwap
                      min={20}
                      max={5000}
                    />
                  </Box>

                  <span className="f-j-salary-num">
                    ${value1[0]} - ${value1[1]}
                  </span>
                </section>
                {/* ---------- TAGS SECTION START -------- */}
                <section className="tags">
                  <span className="f-titles tag-h">Tags</span>

                  <ul className="filter-list flex-tag">
                    <li className="job-list tags-li">App</li>
                    <li className="job-list tags-li">Administrative</li>
                    <li className="job-list tags-li">Android</li>
                    <li className="job-list tags-li">Wordpress</li>
                    <li className="job-list tags-li">Design</li>
                    <li className="job-list tags-li">React</li>
                  </ul>
                  <br />
                  <br />
                </section>
              </div>

              {/* ---------- ADS SECTION START -------- */}
              <div className="below-filter">
                <h5 className="f-titles">Recruiting?</h5>
                <p className="ads-txt">
                  Advertise your jobs to millions of monthly users and search
                  15.8 million CVs in our database.
                </p>
                <button className="Recruiting-btn">Start Recruiting Now</button>
                <div className="ads-side-img">
                  {/* <img src={addsImg} alt="Ads Img" className='ads-img' /> */}
                </div>
              </div>
            </Col>

            {/* ---------- JOB TABS START -------- */}
            <Col lg={8} md={12} sm={12} className="">
              <div className="job-tabs">
                <div className="job-filter">
                  <button
                    className="filter-btn"
                    onClick={() => setSideFilterToggle(true)}
                  >
                    <RiFilter3Line className="filter-icon" />
                    <span>Filter</span>
                  </button>

                  <div className="job-filter">
                    <Form.Select
                      aria-label="Default select example"
                      className="select-filter"
                    >
                      <option>Sort by (default)</option>
                      <option value="new">Newest</option>
                      <option value="old">Oldest</option>
                    </Form.Select>

                    <Form.Select
                      aria-label="Default select example"
                      className="select-filter"
                      onChange={(e) => setQuantityJob(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="15">15 per page</option>
                      <option value="20">20 per pageo</option>
                      <option value="30">30 per page</option>
                    </Form.Select>
                    <GridViewIcon
                      className={`mt-2 icon ${
                        layout === "grid" ? "active" : ""
                      }`}
                      onClick={() => setLayout("grid")}
                      style={{
                        color: `${layout === "grid" ? "blue" : ""}`,
                      }}
                    />
                    <ViewAgendaIcon
                      className={`mt-2 icon ${
                        layout === "list" ? "active" : ""
                      }`}
                      onClick={() => setLayout("list")}
                      style={{
                        color: `${layout === "list" ? "blue" : ""}`,
                      }}
                    />
                  </div>
                </div>

                <div className="jobs-q">
                  <span>
                    Show <span className="qty-txt">{quantityJob}</span> jobs
                  </span>
                </div>

                <div className="job-tabs-list space-y-8">
                  {/* ----------- JOB CARD START ------------ */}
                  {/* <Row className="comp-card-row">
                    <Col lg={6} md={6} sm={12} className="cards-col">
                      <CandidateCard layout={layout} candiImg={prend3} name={"Clorus"} />
                    </Col>
                    <Col lg={6} md={6} sm={12} className="cards-col">
                      <CandidateCard layout={layout} candiImg={prend2} name={"John"} />
                    </Col>
                  </Row> */}

                  {layout === "grid" ? (
                    <Row className="comp-card-row">{cards}</Row>
                  ) : (
                    cards
                  )}
                  {/* <JobCard accountImg={accountImg} name={"Clorus"} />
                  <JobCard accountImg={candidate} name={"John"} />
                  <JobCard accountImg={prend2} name={"Yoori"} />
                  <JobCard accountImg={prend3} name={"Joy"} />
                  <JobCard accountImg={candidate} name={"Flora"} /> */}
                  {/* <section className='job-card'>

                    <div className='card-content' style={{paddingLeft:'70px'}}>
                      <span className='comp-logo' style={{top:4}}>
                        <img src={accountImg} alt="company logo" />
                      </span>
                      <h4>
                        <Link to={'/job/single'} className='card-title'>
                          Software Engineer (Android), Libraries
                        </Link>
                      </h4>

                      <ul className='job-info'>
                        <li className="info-list">
                          <PiShoppingBagOpen className='i-display' />
                          <span>Segment</span>
                        </li>
                        <li className="info-list">
                          <PiShoppingBagOpen className='i-display' />
                          <span>Segment</span>
                        </li>
                        <li className="info-list">
                          <PiShoppingBagOpen className='i-display' />
                          <span>Segment</span>
                        </li>
                        <li className="info-list">
                          <PiShoppingBagOpen className='i-display' />
                          <span>Segment</span>
                        </li>
                      </ul>

                      <ul className='job-other-info'>

                        <li className="time">Time</li>
                        <li className="privacy">Private</li>
                        <li className="required">Urgent</li>

                      </ul>

                    </div>

                  </section> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ----------------- Footer start -------------- */}
      <section className="footer-sec">
        <FooterSection />
        <CopyRightFooter />
      </section>
      {/* *************** Footer end ***************** */}
    </div>
  );
};

export default Workers;
