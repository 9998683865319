import { Input } from "@material-tailwind/react";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CiClock2 } from "react-icons/ci";
import { GoBookmark } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { MdDone } from "react-icons/md";
import { PiShoppingBagOpen } from "react-icons/pi";
import { Link, useNavigate } from 'react-router-dom';
import accountImg from '../Assets/Images/accCreateImg.svg';
import candidate from '../Assets/Images/candidate-1.png';
import articleImg1 from '../Assets/Images/h81.jpg';
import jobSearch from '../Assets/Images/j-s-card-three.svg';
import prend1 from '../Assets/Images/prend.jpg';
import prend2 from '../Assets/Images/prend2.jpg';
import prend3 from '../Assets/Images/prend3.jpg';
import resumeImg from '../Assets/Images/work-1.png';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const Home = () => {

  let navigate = useNavigate();

  const moveOnSearch = _ => {
    navigate('/workers')
  }

  const moveOnRegister = _ => {
    navigate('/register')
  }
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Example breakpoint for mobile screens
    };

    handleResize(); // Call on initial render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div>
      <Container>
        <NavBar />
      </Container>

      <div className='after-nav'>

        {/* ------------- Banner section Start ----------- */}
        <div className='banner '>
          <Container className='md:flex md:justify-start'>


            <div className='inner-banner space-y-10'>


              <div className="card " style={{width:'23rem'}}>
                <div class=" flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 pt-0 ">
                <h5 className='text-dark text-center mt-5'>Find Your Worker</h5>
                  <div class="mt-3 sm:mx-auto sm:w-full lg:w-full sm:max-w-sm ">
                    <form class="space-y-6" >
                      <div className="row">
                        <div className="col-12 mt-3">
                          <Input type="text" name="" id="" placeholder='Location' className='form-control' />
                        </div>
                        <div className="w-72 mt-3">
                          <select id="" label='Category' className=
                            ' form-select'>
                            <option value="" selected>Category</option>
                            <option value="">Category 1</option>
                            <option value="">Category 2</option>
                            <option value="">Category 3</option>
                          </select>
                        </div>
                        <div className="col-6 mt-3">
                          <input type="number" name="" id="" placeholder='$Min Salary' className='form-control' />

                        </div>
                        <div className="col-6 mt-3">
                          <input type="number" name="" id="" placeholder='$Max Salary' className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                          <select id="" className='form-select '>
                            <option value="">opt1</option>
                            <option value="">opt2</option>
                            <option value="">opt3</option>
                          </select>

                        </div>  <div className="col-6 mt-3">
                          <select id="" className='form-select '>
                            <option value="">opt1</option>
                            <option value="">opt2</option>
                            <option value="">opt3</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <Link to={'/workers'} className='nav__link'><button type="button" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Search</button></Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='banner-foot'>

                {/* <Row style={{ width: '100%' }}>

                  <Col lg={3} md={6} sm={5}>

                    <div className='small-card'>
                      <MdCardGiftcard className='small-card-icon' />
                      <span className='small-card-txt'>
                        <h1 className='small-card-h1'>3560</h1>
                        <h1 className='small-card-h2'>Available Jobs</h1>
                      </span>
                    </div>
                  </Col>

                  <Col lg={3} md={6} sm={5}>

                    <div className='small-card'>
                      <FaRegUserCircle className='small-card-icon' />
                      <span className='small-card-txt'>
                        <h1 className='small-card-h1'>8563</h1>
                        <h1 className='small-card-h2'>Employers</h1>
                      </span>

                    </div>

                  </Col>
                  <Col lg={3} md={6} sm={5}>

                    <div className='small-card'>
                      <IoDocumentTextOutline className='small-card-icon' />
                      <span className='small-card-txt'>
                        <h1 className='small-card-h1'>6852</h1>
                        <h1 className='small-card-h2'>Categories</h1>
                      </span>
                    </div>

                  </Col>
                  <Col lg={3} md={6} sm={5}>

                    <div className='small-card'>
                      <BsCup className='small-card-icon' />
                      <span className='small-card-txt'>
                        <h1 className='small-card-h1'>420</h1>
                        <h1 className='small-card-h2'>Cities</h1>
                      </span>

                    </div>

                  </Col>

                </Row> */}
              </div>

            </div>
            <Container className={isMobile ? 'hidden' : 'md:flex md:justify-between p-5'}>
              <h1 className="mt-20  text-white md:text-[50px] text-center">
                Discover the Finest Talent in Your <span className="md:text-[42px]">Area Search, Evaluate, and Hire with Ease! </span>
                <Link to={'/signin'} className='nav__link ' >
                  <button type="button" className="flex w-[200px] justify-center rounded-2xl bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:mt-8 sm:mt-3" style={{marginLeft:'38%'}}>
                    Get Started
                  </button>
                </Link>
              </h1>
            </Container>
          </Container>
        </div><br /><br />
        {/* ************** Banner section End ************** */}

        {/* ------------- Three card section Start ----------- */}
        <section className='banner-three'>

          <h1>How it works</h1>
          <p className='disc-work'>Job for anyone, anywhere</p> <br />

          <div className='banner-three-card'>

            <Container>

              <Row>
                <Col lg={4} md={6} sm={12} >
                  <div className='card-body p-4'><br />

                    <div className='card-section'>
                      <img src={jobSearch} alt="Job Search logo" />
                    </div><br />
                    <h5>Find Worker</h5>
                    <p className='job-p-txt'>
                    Finding workers, often referred to as recruitment or hiring, is the process of identifying,  within an organization.
                    </p><br />

                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} >
                  <div className='card-body p-4'><br />

                    <div className='card-section'>
                      <img src={accountImg} alt="Accoiunt create tmage" />
                    </div><br />
                    <h5>View Profile</h5>
                    <p className='job-p-txt'>
                    Viewing a profile is assessing someone's professional or personal information.</p><br />
                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} >
                  <div className='card-body p-4'><br />

                    <div className='card-section'>
                      <img src={resumeImg} alt="ResumeLogo" />
                    </div><br />
                    <h5>Hire</h5>
                    <p className='job-p-txt'>
                    Hiring is selecting and appointing suitable candidates for job vacancies.</p><br />
                  </div>
                </Col>
              </Row>

            </Container>


          </div>

        </section>

        {/* ************** Three card section End ************* */}

        <br />

        {/* ------------- Featured Jobs section Start ----------- */}

        <section className='feature-jobs-bg'>

          <Container><br /><br /><br />

            <div className='feature-sec'>

              <h1>Featured Workers</h1>
              <p>Know your worth and find the worker that qualify your life</p> <br /><br />

              <div className='feature-card'>

                <div className='card-content' style={{ paddingLeft: 72 }}>

                  <span className='comp-logo' style={{ width: 53 }}>
                    <img src={candidate} alt="Company logo" />
                  </span>
                  <h4>
                    <Link to={'/employers/single'} className='card-title'>
                      Software Engineer (Android), Libraries
                    </Link>
                  </h4>

                  <ul className='job-info'>
                    <li className="info-list">
                      <PiShoppingBagOpen className='i-display' />
                      <span>Segment</span>
                    </li>
                    <li className="job-info-list">
                      <IoLocationOutline className='i-display' />
                      <span>London, UK</span>
                    </li>
                    <li className="job-info-list">
                      <CiClock2 className='i-display' />
                      <span>11 hours ago</span>
                    </li>
                    <li className="job-info-list">
                      <LiaMoneyBillWaveSolid className='i-display' />
                      <span>$35k - $45k</span>
                    </li>
                  </ul>

                </div>

                <div className='feature-side-home space-y-2'>

                  <div className='bookmark-icon'>
                    <GoBookmark />
                  </div>

                  <ul className='job-other-info'>

                    <li className="time">Time</li>
                    <li className="privacy">Private</li>
                    <li className="required">Urgent</li>

                  </ul>

                </div>

                <div className='h-bm-outer-show-hide'>
                  <GoBookmark />
                </div>

              </div><br />

              <div className='feature-card'>

                <div className='card-content' style={{ paddingLeft: 72 }}>

                  <span className='comp-logo' style={{ width: 53 }}>
                    <img src={prend1} alt="Company logo" />
                  </span>
                  <h4>
                    <Link to={'/employers/single'} className='card-title'>
                      Software Engineer (Android), Libraries
                    </Link>
                  </h4>

                  <ul className='job-info'>
                    <li className="info-list">
                      <PiShoppingBagOpen className='i-display' />
                      <span>Segment</span>
                    </li>
                    <li className="job-info-list">
                      <IoLocationOutline className='i-display' />
                      <span>London, UK</span>
                    </li>
                    <li className="job-info-list">
                      <CiClock2 className='i-display' />
                      <span>11 hours ago</span>
                    </li>
                    <li className="job-info-list">
                      <LiaMoneyBillWaveSolid className='i-display' />
                      <span>$35k - $45k</span>
                    </li>
                  </ul>

                </div>

                <div className='feature-side-home space-y-2'>

                  <div className='bookmark-icon'>
                    <GoBookmark />
                  </div>

                  <ul className='job-other-info'>

                    <li className="time">Time</li>
                    <li className="privacy">Private</li>
                    <li className="required">Urgent</li>

                  </ul>

                </div>

                <div className='h-bm-outer-show-hide'>
                  <GoBookmark />
                </div>

              </div><br />

              <div className='feature-card'>

                <div className='card-content' style={{ paddingLeft: 72 }}>

                  <span className='comp-logo' style={{ width: 53 }}>
                    <img src={prend2} alt="Company logo" />
                  </span>
                  <h4>
                    <Link to={'/employers/single'} className='card-title'>
                      Software Engineer (Android), Libraries
                    </Link>
                  </h4>

                  <ul className='job-info'>
                    <li className="info-list">
                      <PiShoppingBagOpen className='i-display' />
                      <span>Segment</span>
                    </li>
                    <li className="job-info-list">
                      <IoLocationOutline className='i-display' />
                      <span>London, UK</span>
                    </li>
                    <li className="job-info-list">
                      <CiClock2 className='i-display' />
                      <span>11 hours ago</span>
                    </li>
                    <li className="job-info-list">
                      <LiaMoneyBillWaveSolid className='i-display' />
                      <span>$35k - $45k</span>
                    </li>
                  </ul>

                </div>

                <div className='feature-side-home space-y-2'>

                  <div className='bookmark-icon'>
                    <GoBookmark />
                  </div>

                  <ul className='job-other-info'>

                    <li className="time">Time</li>
                    <li className="privacy">Private</li>
                    <li className="required">Urgent</li>

                  </ul>

                </div>

                <div className='h-bm-outer-show-hide'>
                  <GoBookmark />
                </div>

              </div><br />

              <div className='feature-card'>

                <div className='card-content' style={{ paddingLeft: 72 }}>

                  <span className='comp-logo' style={{ width: 53 }}>
                    <img src={prend3} alt="Company logo" />
                  </span>
                  <h4>
                    <Link to={'/employers/single'} className='card-title'>
                      Software Engineer (Android), Libraries
                    </Link>
                  </h4>

                  <ul className='job-info'>
                    <li className="info-list">
                      <PiShoppingBagOpen className='i-display' />
                      <span>Segment</span>
                    </li>
                    <li className="job-info-list">
                      <IoLocationOutline className='i-display' />
                      <span>London, UK</span>
                    </li>
                    <li className="job-info-list">
                      <CiClock2 className='i-display' />
                      <span>11 hours ago</span>
                    </li>
                    <li className="job-info-list">
                      <LiaMoneyBillWaveSolid className='i-display' />
                      <span>$35k - $45k</span>
                    </li>
                  </ul>

                </div>

                <div className='feature-side-home space-y-2'>

                  <div className='bookmark-icon'>
                    <GoBookmark />
                  </div>

                  <ul className='job-other-info'>

                    <li className="time">Time</li>
                    <li className="privacy">Private</li>
                    <li className="required">Urgent</li>

                  </ul>

                </div>

                <div className='h-bm-outer-show-hide'>
                  <GoBookmark />
                </div>

              </div><br />

              <div className='feature-card'>

                <div className='card-content' style={{ paddingLeft: 72 }}>

                  <span className='comp-logo' style={{ width: 53 }}>
                    <img src={candidate} alt="Company logo" />
                  </span>
                  <h4>
                    <Link to={'/employers/single'} className='card-title'>
                      Software Engineer (Android), Libraries
                    </Link>
                  </h4>

                  <ul className='job-info'>
                    <li className="info-list">
                      <PiShoppingBagOpen className='i-display' />
                      <span>Segment</span>
                    </li>
                    <li className="job-info-list">
                      <IoLocationOutline className='i-display' />
                      <span>London, UK</span>
                    </li>
                    <li className="job-info-list">
                      <CiClock2 className='i-display' />
                      <span>11 hours ago</span>
                    </li>
                    <li className="job-info-list">
                      <LiaMoneyBillWaveSolid className='i-display' />
                      <span>$35k - $45k</span>
                    </li>
                  </ul>

                </div>

                <div className='feature-side-home space-y-2'>

                  <div className='bookmark-icon'>
                    <GoBookmark />
                  </div>

                  <ul className='job-other-info'>

                    <li className="time">Time</li>
                    <li className="privacy">Private</li>
                    <li className="required">Urgent</li>

                  </ul>

                </div>

                <div className='h-bm-outer-show-hide'>
                  <GoBookmark />
                </div>

              </div><br />

              <button className='j-search-btn'>
                Load More Listing
              </button>

            </div><br /><br /><br />

          </Container>
        </section>

        {/* ************** Featured Jobs section End ************* */}

        {/* ------------- Featured Cities section Start ----------- */}

        <Container>

          <section className='feature-city'><br /><br />

            <h1>Featured Cities</h1>
            <p>Lorem ipsum dolor sit amet elit, sed do eiusmod tempor</p>
            <br />

            <Row>

              <Col lg={4} md={12} sm={12}>

                <div className='feature-block'>
                  <div className='inner-box'>
                    <figure className='city-img'>
                    </figure>
                    <div className='overlay-box'>
                      <div className='content'>

                        <h5>New York</h5>
                        <span>12 jobs</span>
                        <Link className='overlay-link'></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </Col>

              <Col lg={8} md={12} sm={12}>
                <Row>
                  <Col lg={6} md={6} sm={12}>

                    <div className='feature-block'>
                      <div className='inner-box'>
                        <figure className='city-img2'>
                        </figure>
                        <div className='overlay-box'>
                          <div className='content'>

                            <h5>New York</h5>
                            <span>12 jobs</span>
                            <Link className='overlay-link'></Link>

                          </div>
                        </div>
                      </div>
                    </div>

                  </Col>

                  <Col lg={6} md={6} sm={12}>

                    <div className='feature-block'>
                      <div className='inner-box'>
                        <figure className='city-img3'>
                        </figure>
                        <div className='overlay-box'>
                          <div className='content'>

                            <h5>New York</h5>
                            <span>12 jobs</span>
                            <Link className='overlay-link'></Link>

                          </div>
                        </div>
                      </div>
                    </div>

                  </Col>
                  <Col lg={6} md={6} sm={12}>

                    <div className='feature-block'>
                      <div className='inner-box'>
                        <figure className='city-img2'>
                        </figure>
                        <div className='overlay-box'>
                          <div className='content'>

                            <h5>New York</h5>
                            <span>12 jobs</span>
                            <Link className='overlay-link'></Link>

                          </div>
                        </div>
                      </div>
                    </div>

                  </Col>

                  <Col lg={6} md={6} sm={12}>

                    <div className='feature-block'>
                      <div className='inner-box'>
                        <figure className='city-img3'>
                        </figure>
                        <div className='overlay-box'>
                          <div className='content'>

                            <h5>New York</h5>
                            <span>12 jobs</span>
                            <Link className='overlay-link'></Link>

                          </div>
                        </div>
                      </div>
                    </div>

                  </Col>

                </Row>
              </Col>

            </Row>

          </section>

        </Container> <br /><br />

        {/* ************** Featured Cities section End ************* */}

        {/* ------------- Your dream jobs are waiting Start ----------- */}

        {/* <div className='foot-above-img'>

          <h1>Your Dream Jobs Are Waiting</h1><br />
          <p style={{ color: 'white' }}>Over 1 million interactions, 50,000 success stories Make yours now.</p><br />

          <div className='foot-flow space-x-5'>

            <button className='b-light-btn px-4' onClick={moveOnSearch}>
              Search job
            </button>
            <button className='b-warn-btn px-4' onClick={moveOnRegister}>
              Apply job now
            </button>

          </div>

        </div><br /><br /><br /> */}

        {/* ************** Your dream jobs are waiting End ************* */}

        {/* ------------- Pricing plans Start ----------- */}
        <Container>
          <div className='h-pricing-sec mb-5'>
            <div className='faq-sec-heading'>
              <h1>Pricing Packages</h1>
              <p>Lorem ipsum dolor sit amet elit, sed do eiusmod tempor.</p>
            </div>
            <Row>
              <Col lg={4} md={6} sm={12} className="mt-3">
                <div className="pricing-card-outer">

                  <span className='p-card-title'>Basic</span>
                  <h2 className='p-plan-price'>$199</h2>
                  <span className='p-plan-year'> / monthly</span>

                  <ul className='p-plan-ul'>
                    <li> <MdDone /> <span> 30 job posting </span> </li>
                    <li> <MdDone /> <span> 3 featured job </span> </li>
                    <li> <MdDone /> <span> Job displayed for 15 days </span> </li>
                    <li> <MdDone /> <span> Premium Support 24/7 </span> </li>
                  </ul>

                  <button className='v-pro-btn' style={{ height: 45 }}>Add to Cart</button>

                </div>
              </Col>
              <Col lg={4} md={6} sm={12} className="mt-3">
                <div className="pricing-card-outer">

                  <span className='p-card-title'>Basic</span>
                  <h2 className='p-plan-price'>$199</h2>
                  <span className='p-plan-year'> / monthly</span>

                  <ul className='p-plan-ul'>
                    <li> <MdDone /> <span> 30 job posting </span> </li>
                    <li> <MdDone /> <span> 3 featured job </span> </li>
                    <li> <MdDone /> <span> Job displayed for 15 days </span> </li>
                    <li> <MdDone /> <span> Premium Support 24/7 </span> </li>
                  </ul>

                  <button className='v-pro-btn' style={{ height: 45 }}>Add to Cart</button>

                </div>
              </Col>
              <Col lg={4} md={6} sm={12} className="mt-3">
                <div className="pricing-card-outer">

                  <span className='p-card-title'>Basic</span>
                  <h2 className='p-plan-price'>$199</h2>
                  <span className='p-plan-year'> / monthly</span>

                  <ul className='p-plan-ul'>
                    <li> <MdDone /> <span> 30 job posting </span> </li>
                    <li> <MdDone /> <span> 3 featured job </span> </li>
                    <li> <MdDone /> <span> Job displayed for 15 days </span> </li>
                    <li> <MdDone /> <span> Premium Support 24/7 </span> </li>
                  </ul>

                  <button className='v-pro-btn' style={{ height: 45 }}>Add to Cart</button>

                </div>
              </Col>
            </Row>
          </div>

        </Container>
        {/* ************** Pricing plans End ************* */}

        <hr className='hr-clr' /><br /><br />

        {/* ------------- Article card section Start ----------- */}
        <Container>

          <div className='article-sec space-y-3'>

            <h3>Recent News Articles</h3>
            <p className='article-p'>Fresh job related news content posted each day.</p>

            <div className='article-row'>
              <Row className='mt-5'>

                <Col lg={4} md={6} sm={12}>

                  <div className='article-cards'>
                    <img src={articleImg1} alt="" className='img-fluid img-brdr' />

                    <div className='card-b-text space-y-1 mt-3'>

                      <Link to={'/'} className='article-date space-x-2'>
                        <span>August 31, 2021</span>
                        <li>12 comments</li>
                      </Link>

                      <Link to={'/'} className='article-link'>
                        Attract sales and profit
                      </Link>

                      <p className='card-p-txt'>
                        A job ravenously while Far much that one rank beheld after outside....
                      </p>

                      <Link className='read-more space-x-5 mt-3'>
                        <span className='read-more-txt'>Read more </span>
                        <IoIosArrowForward />
                      </Link>

                    </div>

                  </div>

                </Col>

                <Col lg={4} md={6} sm={12}>

                  <div className='article-cards'>
                    <img src={articleImg1} alt="" className='img-fluid img-brdr' />

                    <div className='card-b-text space-y-1 mt-3'>

                      <Link to={'/'} className='article-date space-x-2'>
                        <span>August 31, 2021</span>
                        <li>12 comments</li>
                      </Link>

                      <Link to={'/'} className='article-link'>
                        Attract sales and profit
                      </Link>

                      <p className='card-p-txt'>
                        A job ravenously while Far much that one rank beheld after outside....
                      </p>

                      <Link className='read-more space-x-5 mt-3'>
                        <span className='read-more-txt'>Read more </span>
                        <IoIosArrowForward />
                      </Link>

                    </div>

                  </div>

                </Col>

                <Col lg={4} md={6} sm={12}>

                  <div className='article-cards'>
                    <img src={articleImg1} alt="" className='img-fluid img-brdr' />

                    <div className='card-b-text space-y-1 mt-3'>

                      <Link to={'/'} className='article-date space-x-2'>
                        <span>August 31, 2021</span>
                        <li>12 comments</li>
                      </Link>

                      <Link to={'/'} className='article-link'>
                        Attract sales and profit
                      </Link>

                      <p className='card-p-txt'>
                        A job ravenously while Far much that one rank beheld after outside....
                      </p>

                      <Link className='read-more space-x-5 mt-3'>
                        <span className='read-more-txt'>Read more </span>
                        <IoIosArrowForward />
                      </Link>

                    </div>

                  </div>

                </Col>

              </Row>

            </div>

          </div>

        </Container> <br /><br /><br />
        {/* ************** Article card section End ************* */}

        {/* ------------- Search job section Start ----------- */}

        <div className='search-bg'>
          <Container>

            <div className='search-flex'>
              <div className='search-txt space-y-4'>
                <span className='emp-h-txt'>Let employers find you</span>
                <p className='search-p'>Advertise your jobs to millions of monthly users and search 15.8 million CVs in our database.</p>
              </div>

              <button className='j-search-btn py-2.5' onClick={moveOnSearch}>
                Search job
              </button>
            </div>

          </Container>
        </div>

        {/* ************** Search job section End ************* */}

        <FooterSection />
        <CopyRightFooter />

      </div>

    </div>
  )
}

export default Home