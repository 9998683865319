import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AiOutlineBell } from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { CiClock2 } from "react-icons/ci";
import { FiBookmark } from "react-icons/fi";
import { GoLock } from "react-icons/go";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoMdLogOut } from "react-icons/io";
import { IoDocumentTextOutline, IoLocationOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { LuUser2 } from "react-icons/lu";
import { PiPackage, PiShoppingBagOpen, PiShoppingBagOpenDuotone } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SlHome } from "react-icons/sl";
import { TfiBag } from "react-icons/tfi";
import { Link, NavLink } from 'react-router-dom';
import companyLogo from '../Assets/Images/feactureImg.png';
import logo from '../Assets/Images/logo.png';
import CandiDashSideMenu from '../Components/CandiDashSideMenu';
import DashLineChart from '../Components/DashLineChart';

import { DashCopyRightFooter } from '../Components/Footer';
import NavBar from '../Components/Header';

const CandiDashboard = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <NavBar />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>
                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <CandiDashSideMenu />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>Howdy, Jerome!!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/candidate/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/my/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> My Profile </span></NavLink></li>
                                        <li><NavLink to={'/my/resume'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> My Resume </span></NavLink></li>
                                        <li><NavLink to={'/job/recommendation'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Recommendation </span></NavLink></li>
                                        <li><NavLink to={'/applied/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Applied Jobs </span></NavLink></li>
                                        <li><NavLink to={'/Job/Alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Alerts </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/jobs'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Jobs </span></NavLink></li>
                                        <li><NavLink to={'/cv/manager'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> CV Manager </span></NavLink></li>
                                        <li><NavLink to={'/candidate/packages'} className='dashSideLink space-x-5'><PiPackage className='dash-link-icon-d' /> <span className='dash-link-txt'> Packages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        {/* Overview Cards */}
                        <Row style={{ marginBottom: '30px' }}>
                            <Col lg={6} md={6} sm={12}>

                                <div className='empdash-overviwe-cards'>
                                    <span className='empdash-p-j-icon dash-blue'><TfiBag className='dash-bag-i' /></span>
                                    <div className='empdash-p-j-sec'>
                                        <span className="empdash-p-j-num-blue">22</span>
                                        <span className="empdash-p-j-txt">Posted Jobs</span>
                                    </div>
                                </div>

                            </Col>
                            <Col lg={6} md={6} sm={12}>

                                <div className='empdash-overviwe-cards'>
                                    <span className='empdash-p-j-icon dash-green'><FiBookmark className='dash-bag-i' /></span>
                                    <div className='empdash-p-j-sec'>
                                        <span className="empdash-p-j-num-green">32</span>
                                        <span className="empdash-p-j-txt">Shortlist</span>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                        {/* Profile view and Notifications */}
                        <Row style={{ marginBottom: '30px' }}>
                            {/* Dashboard line chart */}
                            <Col lg={7} md={7} sm={12}>

                                <div className="empdash-chart-sec">

                                    <div className="emp-chart-head">
                                        <h5>Your Profile Views</h5>
                                        <Form.Select aria-label="Default select example" className='select-filter'>
                                            <option>Last 6 months</option>
                                            <option>Last 6 months</option>
                                            <option>Last 6 months</option>
                                            <option>Last 6 months</option>
                                            <option>Last 6 months</option>
                                        </Form.Select>
                                    </div>

                                    <div className="emp-chart">
                                        <DashLineChart />
                                    </div>

                                </div>
                            </Col>

                            {/* Notifications */}
                            <Col lg={5} md={5} sm={12}>
                                <div className="dash-notif-sec space-y-8">

                                    <h5>Notifications</h5>
                                    <ul className='dash-noti-ul'>

                                        <li className="dash-noti-list">
                                            <span className='dash-noti-bag-i'><TfiBag /></span>
                                            <p className='dash-noti-txt'>
                                                <span className='dash-noti-black-txt'> Henry Wilson </span>
                                                <span className='dash-noti-gray-txt'> applied for a job</span>
                                                <span className='dash-noti-blue-txt'> Product Designer</span>
                                            </p>
                                        </li>
                                        <li className="dash-noti-list">
                                            <span className='dash-noti-bag-i'><TfiBag /></span>
                                            <p className='dash-noti-txt'>
                                                <span className='dash-noti-black-txt'> Raul Costa </span>
                                                <span className='dash-noti-gray-txt'> applied for a job</span>
                                                <span className='dash-noti-blue-txt'> Product Manager, Risk</span>
                                            </p>
                                        </li>
                                        <li className="dash-noti-list">
                                            <span className='dash-noti-bag-i'><TfiBag /></span>
                                            <p className='dash-noti-txt'>
                                                <span className='dash-noti-black-txt'> Henry Wilson </span>
                                                <span className='dash-noti-gray-txt'> applied for a job</span>
                                                <span className='dash-noti-blue-txt'> Product Designer</span>
                                            </p>
                                        </li>
                                        <li className="dash-noti-list">
                                            <span className='dash-noti-bag-i'><TfiBag /></span>
                                            <p className='dash-noti-txt'>
                                                <span className='dash-noti-black-txt'> Henry Wilson </span>
                                                <span className='dash-noti-gray-txt'> applied for a job</span>
                                                <span className='dash-noti-blue-txt'> Product Designer</span>
                                            </p>
                                        </li>
                                        <li className="dash-noti-list">
                                            <span className='dash-noti-bag-i'><TfiBag /></span>
                                            <p className='dash-noti-txt'>
                                                <span className='dash-noti-black-txt'> Henry Wilson </span>
                                                <span className='dash-noti-gray-txt'> applied for a job</span>
                                                <span className='dash-noti-blue-txt'> Product Designer</span>
                                            </p>
                                        </li>
                                        <li className="dash-noti-list">
                                            <span className='dash-noti-bag-i'><TfiBag /></span>
                                            <p className='dash-noti-txt'>
                                                <span className='dash-noti-black-txt'> Henry Wilson </span>
                                                <span className='dash-noti-gray-txt'> applied for a job</span>
                                                <span className='dash-noti-blue-txt'> Product Designer</span>
                                            </p>
                                        </li>

                                    </ul>

                                </div>
                            </Col>

                        </Row>

                        {/* Jobs Applied Recently */}
                        <div className="dash-job-apply-recent-sec">
                            <h5>Jobs Applied Recently</h5>

                            <Row>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                </Col>

                            </Row>

                        </div>

                    </div>

                    <DashCopyRightFooter/>

                </Col>
            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default CandiDashboard