import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AiOutlineUser } from "react-icons/ai";
import { CiCalendar, CiClock2 } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6";
import { FiBookmark, FiPlus } from "react-icons/fi";
import { GoPlay } from "react-icons/go";
import { IoLogoInstagram } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { LiaLanguageSolid, LiaMoneyBillWaveSolid } from "react-icons/lia";
import { PiHourglassLowFill, PiStudent } from "react-icons/pi";
import { RiCoinsLine } from "react-icons/ri";
import candiLogo from '../Assets/Images/candidate-1.png';
import singleImg1 from '../Assets/Images/employers-single-1.png';
import singleImg2 from '../Assets/Images/employers-single-2.png';
import singleImg3 from '../Assets/Images/employers-single-3.png';
import singleImg4 from '../Assets/Images/employers-single-4.png';
import videoImg from '../Assets/Images/video-img.jpg';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';
import {
  handleZoom,
  handleZoom1,
  handleZoom2,
  handleZoom3,
  handleZoomOut,
  handleZoomOut1,
  handleZoomOut2,
  handleZoomOut3,
} from '../Functions/candiSingle';

import Modal from 'react-bootstrap/Modal';



const CandidatesSingle = () => {

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <div>

      {/* ----------------- Header start -------------- */}
      <NavBar />
      {/* *************** Header end ***************** */}

      {/* ----------------- Employers single start -------------- */}
      <div className='content-body'>
        <Container>
          <Row>

            <Col className='space-y-4 s-emp-left-side-col' lg={8} md={8} sm={12}>

              <section className='candi-banner'>

                <div className='candi-s-job-content'>

                  <span className='candi-single-company-logo'>
                    <img src={candiLogo} alt="company logo" />
                  </span>
                  <h4 className='candi-title'>Salman Khan</h4>

                  <ul className='candi-info-banner'>
                    <li className="candi-info-list-1">
                      <span>UI Designer</span>
                    </li>
                    <li className="candi-info-list">
                      <IoLocationOutline className='candi-display-i' />
                      <span>London, UK</span>
                    </li>
                    <li className="candi-info-list">
                      <LiaMoneyBillWaveSolid className='candi-display-i' />
                      <span>$99 / hour</span>
                    </li>
                    <li className="candi-info-list">
                      <CiClock2 className='candi-display-i' />
                      <span>Member Since,Aug 19, 2020</span>
                    </li>
                  </ul>

                  <ul className='other-info-job'>

                    <li className="candi-skill">App</li>
                    <li className="candi-skill">Design</li>
                    <li className="candi-skill">Digital</li>

                  </ul>

                </div>

              </section>

              {/* ---------- About discription ---------- */}
              <section className='s-emp-discrip space-y-8'>

                <p className='s-emp-discrip-p s-emp-txt'>
                  Hello my name is Nicole Wells and web developer from Portland. In pharetra orci dignissim,
                  blandit mi semper, ultricies diam. Suspendisse malesuada suscipit nunc non volutpat.
                  Sed porta nulla id orci laoreet tempor non consequat enim. Sed vitae aliquam velit.
                  Aliquam ante erat, blandit at pretium et, accumsan ac est. Integer vehicula rhoncus molestie.
                  Morbi ornare ipsum sed sem condimentum, et pulvinar tortor luctus. Suspendisse condimentum lorem ut
                  elementum aliquam.
                </p>
                <p className='s-emp-discrip-p s-emp-txt'>
                  As a Product Designer, you will work within a Product Delivery Team fused with UX,
                  engineering, product and data talent. You will help the team design beautiful interfaces that
                  applications for AML, KYC and Sanctions List management workflows. This role is ideal if you are looking
                  to segue your career into the FinTech or Big Data arenas.
                </p>

              </section>

              {/* ----------- Candidate about video ----------- */}
              <div className="video-outer">
                <span className='candi-h'>Candidates About</span>
                <div className="video-box">
                  <figure className='candi-about-img'>
                    <div className="play-now" role='button'>
                      <img src={videoImg} alt="video banner" />
                      <div className="v-play-btn"><GoPlay className='play-icon' aria-hidden="true" /></div>
                    </div>
                  </figure>
                </div>

              </div>

              {/* ----------- Candidate Resume ----------- */}
              <div className='cv-education'>

                <div className="edu-title">
                  <h4 className='candi-h'>Education</h4>
                </div>

                <div className="cv-block">
                  <div className="block-inner-redish">
                    <span className='initial-letter redish redish-bg'>M</span>
                    <div className="cv-title-box">
                      <div className="place-name">
                        <h3>Bachlors in Fine Arts</h3>
                        <span className='cv-info-title redish'>Modern College</span>
                      </div>
                      <div className="from-to-box">
                        <span className='edu-year redish redish-bg'>2012-2014</span>
                      </div>
                    </div>
                    <p className='edu-txt'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                      Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                      Alias amet odit excepturi nostrum aliquam facilis.
                    </p>
                  </div>
                </div>

                <div className="cv-block">
                  <div className="block-inn">
                    <span className='initial-letter redish redish-bg'>H</span>
                    <div className="cv-title-box">
                      <div className="place-name">
                        <h3>Bachlors in Fine Arts</h3>
                        <span className='cv-info-title redish'>Modern College</span>
                      </div>
                      <div className="from-to-box">
                        <span className='edu-year redish redish-bg'>2012-2014</span>
                      </div>
                    </div>
                    <p className='edu-txt'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                      Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                      Alias amet odit excepturi nostrum aliquam facilis.
                    </p>
                  </div>
                </div>

              </div>

              <div className='cv-education'>

                <div className="edu-title">
                  <h4 className='candi-h'>Work & Experience</h4>
                </div>

                <div className="cv-block">
                  <div className="block-inner-blueish">
                    <span className='initial-letter blueish blueish-bg'>M</span>
                    <div className="cv-title-box">
                      <div className="place-name">
                        <h3>Bachlors in Fine Arts</h3>
                        <span className='cv-info-title blueish'>Modern College</span>
                      </div>
                      <div className="from-to-box">
                        <span className='edu-year blueish blueish-bg'>2012-2014</span>
                      </div>
                    </div>
                    <p className='edu-txt'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                      Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                      Alias amet odit excepturi nostrum aliquam facilis.
                    </p>
                  </div>
                </div>

                <div className="cv-block">
                  <div className="block-inn">
                    <span className='initial-letter blueish blueish-bg'>H</span>
                    <div className="cv-title-box">
                      <div className="place-name">
                        <h3>Bachlors in Fine Arts</h3>
                        <span className='cv-info-title blueish'>Modern College</span>
                      </div>
                      <div className="from-to-box">
                        <span className='edu-year blueish blueish-bg'>2012-2014</span>
                      </div>
                    </div>
                    <p className='edu-txt'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                      Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                      Alias amet odit excepturi nostrum aliquam facilis.
                    </p>
                  </div>
                </div>

              </div>

              <div className='cv-education'>

                <div className="edu-title">
                  <h4 className='candi-h'>Awards</h4>
                </div>

                <div className="cv-block">
                  <div className="block-inner-yellowish">
                    <span className='initial-letter yellowish yellowish-bg'>M</span>
                    <div className="cv-title-box">
                      <div className="place-name">
                        <h3>Bachlors in Fine Arts</h3>
                        <span className='cv-info-title yellowish'>Modern College</span>
                      </div>
                      <div className="from-to-box">
                        <span className='edu-year yellowish yellowish-bg'>2012-2014</span>
                      </div>
                    </div>
                    <p className='edu-txt'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                      Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                      Alias amet odit excepturi nostrum aliquam facilis.
                    </p>
                  </div>
                </div>

                <div className="cv-block">
                  <div className="block-inn">
                    <span className='initial-letter yellowish yellowish-bg'>H</span>
                    <div className="cv-title-box">
                      <div className="place-name">
                        <h3>Bachlors in Fine Arts</h3>
                        <span className='cv-info-title yellowish'>Modern College</span>
                      </div>
                      <div className="from-to-box">
                        <span className='edu-year yellowish yellowish-bg'>2012-2014</span>
                      </div>
                    </div>
                    <p className='edu-txt'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                      Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                      Alias amet odit excepturi nostrum aliquam facilis.
                    </p>
                  </div>
                </div>

              </div>


              {/* --------- Last table images --------- */}
              <Row>

                <Col className='s-emp-img' lg={3} md={6} sm={12} style={{padding:10}}>
                  <div className='img-container' onMouseEnter={handleZoom} onMouseOut={handleZoomOut}>
                    <img src={singleImg1} alt="employerImg" className='emp-s-lightbox-img'/>
                    <div className='candi-plus' id='plusIcon'><FiPlus className="plus-area" /></div>
                  </div>
                </Col>
                <Col className='s-emp-img' lg={3} md={6} sm={12} style={{padding:10}}>
                  <div className='img-container' onMouseEnter={handleZoom1} onMouseOut={handleZoomOut1}>
                    <img src={singleImg2} alt="employerImg" />
                    <div className='candi-plus' id='plusIcon1'><FiPlus className="plus-area" /></div>
                  </div>
                </Col>
                <Col className='s-emp-img' lg={3} md={6} sm={12} style={{padding:10}}>
                  <div className='img-container' onMouseEnter={handleZoom2} onMouseOut={handleZoomOut2}>
                    <img src={singleImg3} alt="employerImg" />
                    <div className='candi-plus' id='plusIcon2'><FiPlus className="plus-area" /></div>
                  </div>
                </Col>
                <Col className='s-emp-img' lg={3} md={6} sm={12} style={{padding:10}}>
                  <div className='img-container' onMouseEnter={handleZoom3} onMouseOut={handleZoomOut3}>
                    <img src={singleImg4} alt="employerImg" />
                    <div className='candi-plus' id='plusIcon3'><FiPlus className="plus-area" /></div>
                  </div>
                </Col>
              </Row>

            </Col>

            <Col className='space-y-10 s-emp-right-side-col' lg={4} md={4} sm={12}>

              {/* ---------- Apply for job and bookmark buttons -------- */}
              <div className='emp-msg-bm-btn'>
                {/* <div className='apply-for-btn'></div> */}
                <a href='../Assets/Images/sample.pdf' className='msg-btn-s-emp' download="sample.pdf" style={{textAlign:'center'}}>Download CV</a>
                <button className='bm-btn-s-emp'><FiBookmark /></button>

                {/* ----------- APPLY JOB MODAL -------------- */}
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Send message to Stripe</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input type="text" name="" id="" placeholder='Your Name' className='s-emp-name' />
                    <textarea type="text" className='s-emp-message' placeholder='Message' required />
                    <div className='s-emp-term'>
                      <a href="#" className='s-emp-terms-link'>
                        <span className='s-emp-terms-txt'> You accept our </span> <p className='s-emp-msg-hover-txt'> &nbsp; Terms and Conditions and Privacy Policy</p>
                      </a>
                    </div>

                  </Modal.Body>
                  <Modal.Footer>
                    <button className='s-emp-send-msg' onClick={handleCloseModal}> Send message </button>
                  </Modal.Footer>
                </Modal>

              </div>

              {/* ---------- Job Details start -------- */}
              <div className='flex-single-candi candi-overview'>

                {/* ---------- Job Overview START -------- */}
                <section className="job-typ">
                  <ul className="filter-list overview-rows">

                    <li className="job-list flex-start">
                      <div className='o-content'>
                        <CiCalendar className='o-icon' />
                        <div className='o-date'>
                          <span className='overview-h'>Date Posted:</span>
                          <span className='overview-txt'>Posted 1 hours ago</span>
                        </div>
                      </div>
                    </li>

                    <li className="job-list flex-start">
                      <div className='o-content'>
                        <PiHourglassLowFill className='o-icon' />
                        <div className='o-date'>
                          <span className='overview-h'>Date Posted:</span>
                          <span className='overview-txt'>Posted 1 hours ago</span>
                        </div>
                      </div>
                    </li>

                    <li className="job-list flex-start">
                      <div className='o-content'>
                        <LiaLanguageSolid className='o-icon' />
                        <div className='o-date'>
                          <span className='overview-h'>Date Posted:</span>
                          <span className='overview-txt'>Posted 1 hours ago</span>
                        </div>
                      </div>
                    </li>

                    <li className="job-list flex-start">
                      <div className='o-content'>
                        <AiOutlineUser className='o-icon' />
                        <div className='o-date'>
                          <span className='overview-h'>Date Posted:</span>
                          <span className='overview-txt'>Posted 1 hours ago</span>
                        </div>
                      </div>
                    </li>

                    <li className="job-list flex-start">
                      <div className='o-content'>
                        <PiStudent className='o-icon' />
                        <div className='o-date'>
                          <span className='overview-h'>Date Posted:</span>
                          <span className='overview-txt'>Posted 1 hours ago</span>
                        </div>
                      </div>
                    </li>

                    <li className="job-list flex-start">
                      <div className='o-content'>
                        <RiCoinsLine className='o-icon' />
                        <div className='o-date'>
                          <span className='overview-h'>Date Posted:</span>
                          <span className='overview-txt'>Posted 1 hours ago</span>
                        </div>
                      </div>
                    </li>

                    <li className="job-list flex-start">
                      <div className='o-content'>
                        <LiaMoneyBillWaveSolid className='o-icon' />
                        <div className='o-date'>
                          <span className='overview-h'>Date Posted:</span>
                          <span className='overview-txt'>Posted 1 hours ago</span>
                        </div>
                      </div>
                    </li>

                  </ul>
                </section>

              </div>

              {/* ---------- Social media start -------- */}
              <div className='flex-single-candi candi-media'>

                <div className='seg-company-info'>

                  <span className='f-titles tag-h'>Social media:</span>
                  <div className='social-icons'>
                    <FaFacebookF className='social-i' />
                    <FaTwitter className='social-i' />
                    <IoLogoInstagram className='social-i' />
                    <FaLinkedinIn className='social-i' />
                  </div>

                </div>

              </div>

              {/* ---------- Professional Skills start -------- */}
              <div className='flex-single-candi candi-proff'>

                <section className='tags'>
                  <span className='f-titles tag-h'>Professional Skills</span>

                  <ul className='filter-list flex-tag'>
                    <li className='job-list tags-li'>App</li>
                    <li className='job-list tags-li'>Administrative</li>
                    <li className='job-list tags-li'>Android</li>
                    <li className='job-list tags-li'>Wordpress</li>
                    <li className='job-list tags-li'>Design</li>
                    <li className='job-list tags-li'>React</li>
                  </ul><br /><br />

                </section>

              </div>

              {/* ---------- Contact Us start -------- */}
              <div className='flex-single-candi candi-contact'>

                <section className=''>

                  <form action="">

                    <span className='f-titles tag-h'>Contact Us</span>
                    <input type="text" name="" id="" required placeholder='Your Name' className='candi-name' />
                    <input type="email" name="" id="" required placeholder='Email Address' className='candi-email' />
                    <textarea type="text" className='candi-message' required placeholder='Message' />
                    <button type='submit' className='s-emp-send-msg' onClick={handleCloseModal}> Send message </button>

                  </form>

                </section>

              </div>


            </Col>

          </Row>
        </Container>
      </div> <br /> <br /><br />
      {/* *************** Employers single end ***************** */}

      {/* ----------------- Footer start -------------- */}
      <section className='footer-sec'>
        <FooterSection />
        <CopyRightFooter />
      </section>
      {/* *************** Footer end ***************** */}

    </div>
  )
}

export default CandidatesSingle