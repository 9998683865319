import React from 'react';
import { Route, Routes } from "react-router-dom";
import PageNotFound from '../Components/404';
import ForgetPassword from '../Components/ForgetPassword';
import SignIn from '../Components/SignIn';
import SignUp from '../Components/SignUp';
import AboutUs from '../Container/AboutUs';
import AllApplicants from '../Container/AllApplicants';
import AllNotifications from '../Container/AllNotifications';
import AppliedJobs from '../Container/AppliedJobs';
import BlogDetails from '../Container/BlogDetails';
import BlogList from '../Container/BlogList';
import CandiAllNotifications from '../Container/CandiAllNotifications';
import CandiChangePassword from '../Container/CandiChangePassword';
import CandiDashboard from '../Container/CandiDashboard';
import CandiMessages from '../Container/CandiMessages';
import CandidatePackages from '../Container/CandidatePackages';
import Candidates from '../Container/CandidatesList';
import CandidatesSingle from '../Container/CandidatesSingle';
import ChangePassword from '../Container/ChangePassword';
import CompanyProfile from '../Container/CompanyProfile';
import Contact from '../Container/Contact';
import CvManager from '../Container/CvManager';
import EmployerDashboard from '../Container/EmployerDashboard';
import Employers from '../Container/EmployersList';
import EmployersSingle from '../Container/EmployersSingle';
import Faq from '../Container/Faq';
import JobSingle from '../Container/FindSingle';
import HelpAlertJobs from '../Container/HelpAlertJobs';
import HelpAndSupport from '../Container/HelpAndSupport';
import HelpApplyJobs from '../Container/HelpApplyJobs';
import HelpCandidates from '../Container/HelpCandidates';
import HelpContact from '../Container/HelpContact';
import HelpCvManager from '../Container/HelpCvManager';
import HelpEmployers from '../Container/HelpEmployers';
import HelpFindJobs from '../Container/HelpFindJobs';
import HelpJobPost from '../Container/HelpJobPost';
import HelpResumeAlerts from '../Container/HelpResumeAlerts';
import Home from '../Container/Home';
import JobAlerts from '../Container/JobAlerts';
import JobRecommendation from '../Container/JobRecommendation';
import ManageJobs from '../Container/ManageJobs';
import Messages from '../Container/Messages';
import MyProfile from '../Container/MyProfile';
import MyResume from '../Container/MyResume';
import Packages from '../Container/Packages';
import PostNewJob from '../Container/PostNewJob';
import Pricing from '../Container/Pricing';
import RessumeAlerts from '../Container/RessumeAlerts';
import ShortlistedJobs from '../Container/ShortlistedJobs';
import ShortlistedResumes from '../Container/ShortlistedResumes';
import Terms from '../Container/Terms';
import Workers from '../Container/Workers';
// import  '../All css/style.css';

const Navigation = () => {
  return (
    <div>
        <Routes>
          <Route path='/' element={<Home/>} ></Route>
          <Route path='/workers' element={<Workers/>} ></Route>
          <Route path='/job/single' element={<JobSingle/>} ></Route>
          <Route path='/employers/list' element={<Employers/>} ></Route> 
          <Route path='/employers/single' element={<EmployersSingle/>} ></Route>
          <Route path='/candidates/list' element={<Candidates/>} ></Route> 
          <Route path='/candidates/Single' element={<CandidatesSingle/>} ></Route> 
          <Route path='/blog/list' element={<BlogList/>} ></Route> 
          <Route path='/blog/details' element={<BlogDetails/>} ></Route> 
          <Route path='/about' element={<AboutUs/>} ></Route> 
          <Route path='/faq' element={<Faq/>} ></Route> 
          <Route path='/terms' element={<Terms/>} ></Route> 
          <Route path='/contact' element={<Contact/>} ></Route> 
          <Route path='/pricing' element={<Pricing/>} ></Route> 
          <Route path='/help&support' element={<HelpAndSupport/>} ></Route> 
          <Route path='/help&support/contactus' element={<HelpContact/>} ></Route> 
          <Route path='/signin' element={<SignIn/>} ></Route> 
          <Route path='/forgetpassword' element={<ForgetPassword/>} ></Route> 
          <Route path='/register' element={<SignUp/>} ></Route> 
          <Route path='/employer/dashboard' element={<EmployerDashboard/>} ></Route> 
          <Route path='/company/profile' element={<CompanyProfile/>} ></Route> 
          <Route path='/post/job' element={<PostNewJob/>} ></Route> 
          <Route path='/job/recommendation' element={<JobRecommendation/>} ></Route> 
          <Route path='/manage/jobs' element={<ManageJobs/>} ></Route> 
          <Route path='/all/applicants' element={<AllApplicants/>} ></Route> 
          <Route path='/shortlisted/resumes' element={<ShortlistedResumes/>} ></Route> 
          <Route path='/packages' element={<Packages/>} ></Route> 
          <Route path='/resume/alerts' element={<RessumeAlerts/>} ></Route> 
          <Route path='/change/password' element={<ChangePassword/>} ></Route> 
          <Route path='/candidate/change/password' element={<CandiChangePassword/>} ></Route> 
          <Route path='/candidate/dashboard' element={<CandiDashboard/>} ></Route> 
          <Route path='/my/profile' element={<MyProfile/>} ></Route> 
          <Route path='/my/resume' element={<MyResume/>} ></Route> 
          <Route path='/applied/jobs' element={<AppliedJobs/>} ></Route> 
          <Route path='/Job/Alerts' element={<JobAlerts/>} ></Route> 
          <Route path='/shortlisted/jobs' element={<ShortlistedJobs/>} ></Route> 
          <Route path='/cv/manager' element={<CvManager/>} ></Route> 
          <Route path='/candidate/packages' element={<CandidatePackages/>} ></Route> 
          <Route path='/candidate/messages' element={<CandiMessages/>} ></Route> 
          <Route path='/messages' element={<Messages/>} ></Route> 
          <Route path='/all/notifications' element={<AllNotifications/>} ></Route> 
          <Route path='/candidate/all/notifications' element={<CandiAllNotifications/>} ></Route> 
          <Route path='/help&support/applyjobs' element={<HelpApplyJobs/>} ></Route> 
          <Route path='/help&support/alertjobs' element={<HelpAlertJobs/>} ></Route> 
          <Route path='/help&support/candidates' element={<HelpCandidates/>} ></Route> 
          <Route path='/help&support/employers' element={<HelpEmployers/>} ></Route> 
          <Route path='/help&support/jobposts' element={<HelpJobPost/>} ></Route> 
          <Route path='/help&support/workers' element={<HelpFindJobs/>} ></Route> 
          <Route path='/help&support/resumealerts' element={<HelpResumeAlerts/>} ></Route> 
          <Route path='/help&support/cvmanager' element={<HelpCvManager/>} ></Route> 

          <Route path='/*' element={<PageNotFound/>} ></Route> 

        </Routes>
    </div>
  )
}

export default Navigation