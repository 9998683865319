import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AiOutlineBell } from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { GoEye, GoLock } from "react-icons/go";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoMdLogOut } from "react-icons/io";
import { IoDocumentTextOutline, IoLocationOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineDone } from "react-icons/md";
import { PiPackage, PiShoppingBagOpenDuotone } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SlHome } from "react-icons/sl";
import { TfiBag } from "react-icons/tfi";
import { TiDeleteOutline } from "react-icons/ti";
import { VscSend } from "react-icons/vsc";
import { Link, NavLink } from 'react-router-dom';
import userImg from '../Assets/Images/candidate-1.png';
import logo from '../Assets/Images/logo.png';
import DashLineChart from '../Components/DashLineChart';
import EmpDashSideBar from '../Components/EmpDashSideBar';

import { DashCopyRightFooter } from '../Components/Footer';
import NavBar from '../Components/Header';


const EmployerDashboard = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <div className='empdash-bg'>
      {/* --------------- Header start --------------- */}
      <NavBar />
      {/* ****************** Header end ****************** */}

      {/* --------------- Dashboard body start --------------- */}
      <Row>
        {/* --------------- SideBar start --------------- */}
        <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
          <EmpDashSideBar />
        </Col>

        {/* --------------- Dashboard details start --------------- */}
        <Col lg={9} md={12} sm={12} className='dash-body-set'>
          <div className="emp-dash-main">

            <h2 className='emp-dash-main-h'>Dashboard Home!</h2>
            <p className='emp-dash-main-p'>Ready to jump back in?</p>

            {/* Offcanva Button */}
            <button className='dash-menu-btn me-2' onClick={toggleShow}>
              <HiOutlineMenuAlt3 />
              <span>Menu</span>
            </button>

            {/* Offcanva */}
            <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
              <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                <Link to={'/'} className='dash-filter-side-menu-logo'>
                  <img src={logo} alt="Logo" className='nav__logo' />
                </Link>

              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className='dash-dashSideBar'>
                  <ul>
                    <li><NavLink to={'/employer/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                    <li><NavLink to={'/company/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> Company Profile </span></NavLink></li>
                    <li><NavLink to={'/post/job'} className='dashSideLink space-x-5'><VscSend className='dash-link-icon-d' /> <span className='dash-link-txt'> Post A New Job </span></NavLink></li>
                    <li><NavLink to={'/manage/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Manage Jobs </span></NavLink></li>
                    <li><NavLink to={'/all/applicants'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> All Applicants </span></NavLink></li>
                    <li><NavLink to={'/shortlisted/resumes'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Resumes </span></NavLink></li>
                    <li><NavLink to={'/packages'} className='dashSideLink space-x-5'><PiPackage className='dash-link-icon-d' /> <span className='dash-link-txt'> Packages </span></NavLink></li>
                    <li><NavLink to={'/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                    <li><NavLink to={'/resume/alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Resume Alerts </span></NavLink></li>
                    <li><NavLink to={'/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                    <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                    <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                  </ul>
                </div>

              </Offcanvas.Body>
            </Offcanvas>

            {/* Overview Cards */}
            <Row style={{ marginBottom: '20px' }}>
              <Col lg={3} md={6} sm={12} style={{ marginBottom: '10px' }}>

                <div className='empdash-overviwe-cards'>
                  <span className='empdash-p-j-icon dash-blue'><TfiBag className='dash-bag-i' /></span>
                  <div className='empdash-p-j-sec'>
                    <span className="empdash-p-j-num-blue">22</span>
                    <span className="empdash-p-j-txt">Posted Jobs</span>
                  </div>
                </div>

              </Col>
              <Col lg={3} md={6} sm={12}>

                <div className='empdash-overviwe-cards'>
                  <span className='empdash-p-j-icon dash-red'><IoDocumentTextOutline className='dash-bag-i' /></span>
                  <div className='empdash-p-j-sec'>
                    <span className="empdash-p-j-num-red">9382</span>
                    <span className="empdash-p-j-txt">Job Alerts</span>
                  </div>
                </div>

              </Col>
              <Col lg={3} md={6} sm={12}>

                <div className='empdash-overviwe-cards'>
                  <span className='empdash-p-j-icon dash-yellow'><BiMessageDetail className='dash-bag-i' /></span>
                  <div className='empdash-p-j-sec'>
                    <span className="empdash-p-j-num-yellow">74</span>
                    <span className="empdash-p-j-txt">Messages</span>
                  </div>
                </div>

              </Col>
              <Col lg={3} md={6} sm={12} style={{ marginBottom: '10px' }}>

                <div className='empdash-overviwe-cards'>
                  <span className='empdash-p-j-icon dash-green'><FiBookmark className='dash-bag-i' /></span>
                  <div className='empdash-p-j-sec'>
                    <span className="empdash-p-j-num-green">32</span>
                    <span className="empdash-p-j-txt">Shortlist</span>
                  </div>
                </div>

              </Col>
            </Row>

            {/* Profile view and Notifications */}
            <Row style={{ marginBottom: '30px' }}>
              {/* Dashboard line chart */}
              <Col lg={7} md={7} sm={12}>

                <div className="empdash-chart-sec">

                  <div className="emp-chart-head">
                    <h5>Your Profile Views</h5>
                    <Form.Select aria-label="Default select example" className='select-filter'>
                      <option>Last 6 months</option>
                      <option>Last 6 months</option>
                      <option>Last 6 months</option>
                      <option>Last 6 months</option>
                      <option>Last 6 months</option>
                    </Form.Select>
                  </div>

                  <div className="emp-chart">
                    <DashLineChart />
                  </div>

                </div>
              </Col>

              {/* Notifications */}
              <Col lg={5} md={5} sm={12}>
                <div className="dash-notif-sec space-y-8">

                  <h5>Notifications</h5>
                  <ul className='dash-noti-ul'>

                    <li className="dash-noti-list">
                      <span className='dash-noti-bag-i'><TfiBag /></span>
                      <p className='dash-noti-txt'>
                        <span className='dash-noti-black-txt'> Henry Wilson </span>
                        <span className='dash-noti-gray-txt'> applied for a job</span>
                        <span className='dash-noti-blue-txt'> Product Designer</span>
                      </p>
                    </li>
                    <li className="dash-noti-list">
                      <span className='dash-noti-bag-i'><TfiBag /></span>
                      <p className='dash-noti-txt'>
                        <span className='dash-noti-black-txt'> Raul Costa </span>
                        <span className='dash-noti-gray-txt'> applied for a job</span>
                        <span className='dash-noti-blue-txt'> Product Manager, Risk</span>
                      </p>
                    </li>
                    <li className="dash-noti-list">
                      <span className='dash-noti-bag-i'><TfiBag /></span>
                      <p className='dash-noti-txt'>
                        <span className='dash-noti-black-txt'> Henry Wilson </span>
                        <span className='dash-noti-gray-txt'> applied for a job</span>
                        <span className='dash-noti-blue-txt'> Product Designer</span>
                      </p>
                    </li>
                    <li className="dash-noti-list">
                      <span className='dash-noti-bag-i'><TfiBag /></span>
                      <p className='dash-noti-txt'>
                        <span className='dash-noti-black-txt'> Henry Wilson </span>
                        <span className='dash-noti-gray-txt'> applied for a job</span>
                        <span className='dash-noti-blue-txt'> Product Designer</span>
                      </p>
                    </li>
                    <li className="dash-noti-list">
                      <span className='dash-noti-bag-i'><TfiBag /></span>
                      <p className='dash-noti-txt'>
                        <span className='dash-noti-black-txt'> Henry Wilson </span>
                        <span className='dash-noti-gray-txt'> applied for a job</span>
                        <span className='dash-noti-blue-txt'> Product Designer</span>
                      </p>
                    </li>
                    <li className="dash-noti-list">
                      <span className='dash-noti-bag-i'><TfiBag /></span>
                      <p className='dash-noti-txt'>
                        <span className='dash-noti-black-txt'> Henry Wilson </span>
                        <span className='dash-noti-gray-txt'> applied for a job</span>
                        <span className='dash-noti-blue-txt'> Product Designer</span>
                      </p>
                    </li>

                  </ul>

                </div>
              </Col>

            </Row>

            {/* Recent Applicants */}
            <div className="dash-job-apply-recent-sec">
              <h5>Recent Applicants</h5>

              <Row>

                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                  <section className='rel-card'>

                    <div className='card-content dash-card-cont'>

                      <span className='comp-logo dash-user-img-card'>
                        <img src={userImg} alt="company logo" />
                      </span>

                      <div className='dash-card-partition'>
                        <h4>
                          <Link to={'/'} className='dash-u-title card-title'>
                            Darlene Robertson
                          </Link>
                        </h4>

                        <ul className='job-info'>
                          <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                            <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                          </li>
                          <li className="dash-card-info-list">
                            <IoLocationOutline className='i-display' />
                            <span className='dash-u-info-txt'>London, UK</span>
                          </li>
                          <li className="dash-card-info-list">
                            <LiaMoneyBillWaveSolid className='i-display' />
                            <span className='dash-u-info-txt'>$44 / hour</span>
                          </li>
                        </ul>

                        <ul className='dash-card-tag-ul empdash-card-tags'>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                        </ul>

                        <div className="empdash-card-btn-sec">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><GoEye /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><MdOutlineDone /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                          </OverlayTrigger>
                        </div>

                      </div>

                    </div>

                  </section>

                </Col>

                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                  <section className='rel-card'>

                    <div className='card-content dash-card-cont'>

                      <span className='comp-logo dash-user-img-card'>
                        <img src={userImg} alt="company logo" />
                      </span>

                      <div className='dash-card-partition'>
                        <h4>
                          <Link to={'/'} className='dash-u-title card-title'>
                            Darlene Robertson
                          </Link>
                        </h4>

                        <ul className='job-info'>
                          <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                            <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                          </li>
                          <li className="dash-card-info-list">
                            <IoLocationOutline className='i-display' />
                            <span className='dash-u-info-txt'>London, UK</span>
                          </li>
                          <li className="dash-card-info-list">
                            <LiaMoneyBillWaveSolid className='i-display' />
                            <span className='dash-u-info-txt'>$44 / hour</span>
                          </li>
                        </ul>

                        <ul className='dash-card-tag-ul empdash-card-tags'>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                        </ul>

                        <div className="empdash-card-btn-sec">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><GoEye /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><MdOutlineDone /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                          </OverlayTrigger>
                        </div>

                      </div>

                    </div>

                  </section>

                </Col>

                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                  <section className='rel-card'>

                    <div className='card-content dash-card-cont'>

                      <span className='comp-logo dash-user-img-card'>
                        <img src={userImg} alt="company logo" />
                      </span>

                      <div className='dash-card-partition'>
                        <h4>
                          <Link to={'/'} className='dash-u-title card-title'>
                            Darlene Robertson
                          </Link>
                        </h4>

                        <ul className='job-info'>
                          <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                            <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                          </li>
                          <li className="dash-card-info-list">
                            <IoLocationOutline className='i-display' />
                            <span className='dash-u-info-txt'>London, UK</span>
                          </li>
                          <li className="dash-card-info-list">
                            <LiaMoneyBillWaveSolid className='i-display' />
                            <span className='dash-u-info-txt'>$44 / hour</span>
                          </li>
                        </ul>

                        <ul className='dash-card-tag-ul empdash-card-tags'>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                        </ul>

                        <div className="empdash-card-btn-sec">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><GoEye /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><MdOutlineDone /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                          </OverlayTrigger>
                        </div>

                      </div>

                    </div>

                  </section>

                </Col>

                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                  <section className='rel-card'>

                    <div className='card-content dash-card-cont'>

                      <span className='comp-logo dash-user-img-card'>
                        <img src={userImg} alt="company logo" />
                      </span>

                      <div className='dash-card-partition'>
                        <h4>
                          <Link to={'/'} className='dash-u-title card-title'>
                            Darlene Robertson
                          </Link>
                        </h4>

                        <ul className='job-info'>
                          <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                            <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                          </li>
                          <li className="dash-card-info-list">
                            <IoLocationOutline className='i-display' />
                            <span className='dash-u-info-txt'>London, UK</span>
                          </li>
                          <li className="dash-card-info-list">
                            <LiaMoneyBillWaveSolid className='i-display' />
                            <span className='dash-u-info-txt'>$44 / hour</span>
                          </li>
                        </ul>

                        <ul className='dash-card-tag-ul empdash-card-tags'>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                        </ul>

                        <div className="empdash-card-btn-sec">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><GoEye /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><MdOutlineDone /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                          </OverlayTrigger>
                        </div>

                      </div>

                    </div>

                  </section>

                </Col>

                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                  <section className='rel-card'>

                    <div className='card-content dash-card-cont'>

                      <span className='comp-logo dash-user-img-card'>
                        <img src={userImg} alt="company logo" />
                      </span>

                      <div className='dash-card-partition'>
                        <h4>
                          <Link to={'/'} className='dash-u-title card-title'>
                            Darlene Robertson
                          </Link>
                        </h4>

                        <ul className='job-info'>
                          <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                            <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                          </li>
                          <li className="dash-card-info-list">
                            <IoLocationOutline className='i-display' />
                            <span className='dash-u-info-txt'>London, UK</span>
                          </li>
                          <li className="dash-card-info-list">
                            <LiaMoneyBillWaveSolid className='i-display' />
                            <span className='dash-u-info-txt'>$44 / hour</span>
                          </li>
                        </ul>

                        <ul className='dash-card-tag-ul empdash-card-tags'>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                        </ul>

                        <div className="empdash-card-btn-sec">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><GoEye /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><MdOutlineDone /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                          </OverlayTrigger>
                        </div>

                      </div>

                    </div>

                  </section>

                </Col>

                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                  <section className='rel-card'>

                    <div className='card-content dash-card-cont'>

                      <span className='comp-logo dash-user-img-card'>
                        <img src={userImg} alt="company logo" />
                      </span>

                      <div className='dash-card-partition'>
                        <h4>
                          <Link to={'/'} className='dash-u-title card-title'>
                            Darlene Robertson
                          </Link>
                        </h4>

                        <ul className='job-info'>
                          <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                            <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                          </li>
                          <li className="dash-card-info-list">
                            <IoLocationOutline className='i-display' />
                            <span className='dash-u-info-txt'>London, UK</span>
                          </li>
                          <li className="dash-card-info-list">
                            <LiaMoneyBillWaveSolid className='i-display' />
                            <span className='dash-u-info-txt'>$44 / hour</span>
                          </li>
                        </ul>

                        <ul className='dash-card-tag-ul empdash-card-tags'>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                          <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                        </ul>

                        <div className="empdash-card-btn-sec">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><GoEye /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><MdOutlineDone /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                            <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                          </OverlayTrigger>
                        </div>

                      </div>

                    </div>

                  </section>

                </Col>

                {/* <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

               --------------------------- FOR CANDIDATE DASHBOARD--------------------

      <section className='rel-card'>

        <div className='card-content'>

          <span className='comp-logo'>
            <img src={companyLogo} alt="company logo" />
          </span>
          <h4>
            <Link to={'/'} className='card-title'>
              Software Engineer (Android), Libraries
            </Link>
          </h4>

          <ul className='job-info'>
            <li className="info-list">
              <PiShoppingBagOpen className='i-display' />
              <span>Segment</span>
            </li>
            <li className="job-info-list">
              <IoLocationOutline className='i-display' />
              <span>London, UK</span>
            </li>
            <li className="job-info-list">
              <CiClock2 className='i-display' />
              <span>11 hours ago</span>
            </li>
            <li className="job-info-list">
              <LiaMoneyBillWaveSolid className='i-display' />
              <span>$35k - $45k</span>
            </li>
          </ul>

          <ul className='job-other-info'>

            <li className="time">Time</li>
            <li className="privacy">Private</li>
            <li className="required">Urgent</li>

          </ul>

        </div>

        <div className='rel-card-bm'>
          <button className='rel-bm-i'><FiBookmark /></button>
        </div>


      </section>

    </Col>
    <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

      <section className='rel-card'>

        <div className='card-content'>

          <span className='comp-logo'>
            <img src={companyLogo} alt="company logo" />
          </span>
          <h4>
            <Link to={'/'} className='card-title'>
              Software Engineer (Android), Libraries
            </Link>
          </h4>

          <ul className='job-info'>
            <li className="info-list">
              <PiShoppingBagOpen className='i-display' />
              <span>Segment</span>
            </li>
            <li className="job-info-list">
              <IoLocationOutline className='i-display' />
              <span>London, UK</span>
            </li>
            <li className="job-info-list">
              <CiClock2 className='i-display' />
              <span>11 hours ago</span>
            </li>
            <li className="job-info-list">
              <LiaMoneyBillWaveSolid className='i-display' />
              <span>$35k - $45k</span>
            </li>
          </ul>

          <ul className='job-other-info'>

            <li className="time">Time</li>
            <li className="privacy">Private</li>
            <li className="required">Urgent</li>

          </ul>

        </div>

        <div className='rel-card-bm'>
          <button className='rel-bm-i'><FiBookmark /></button>
        </div>


      </section>

    </Col>

    <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

      <section className='rel-card'>

        <div className='card-content'>

          <span className='comp-logo'>
            <img src={companyLogo} alt="company logo" />
          </span>
          <h4>
            <Link to={'/'} className='card-title'>
              Software Engineer (Android), Libraries
            </Link>
          </h4>

          <ul className='job-info'>
            <li className="info-list">
              <PiShoppingBagOpen className='i-display' />
              <span>Segment</span>
            </li>
            <li className="job-info-list">
              <IoLocationOutline className='i-display' />
              <span>London, UK</span>
            </li>
            <li className="job-info-list">
              <CiClock2 className='i-display' />
              <span>11 hours ago</span>
            </li>
            <li className="job-info-list">
              <LiaMoneyBillWaveSolid className='i-display' />
              <span>$35k - $45k</span>
            </li>
          </ul>

          <ul className='job-other-info'>

            <li className="time">Time</li>
            <li className="privacy">Private</li>
            <li className="required">Urgent</li>

          </ul>

        </div>

        <div className='rel-card-bm'>
          <button className='rel-bm-i'><FiBookmark /></button>
        </div>


      </section>

    </Col>
    <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

      <section className='rel-card'>

        <div className='card-content'>

          <span className='comp-logo'>
            <img src={companyLogo} alt="company logo" />
          </span>
          <h4>
            <Link to={'/'} className='card-title'>
              Software Engineer (Android), Libraries
            </Link>
          </h4>

          <ul className='job-info'>
            <li className="info-list">
              <PiShoppingBagOpen className='i-display' />
              <span>Segment</span>
            </li>
            <li className="job-info-list">
              <IoLocationOutline className='i-display' />
              <span>London, UK</span>
            </li>
            <li className="job-info-list">
              <CiClock2 className='i-display' />
              <span>11 hours ago</span>
            </li>
            <li className="job-info-list">
              <LiaMoneyBillWaveSolid className='i-display' />
              <span>$35k - $45k</span>
            </li>
          </ul>

          <ul className='job-other-info'>

            <li className="time">Time</li>
            <li className="privacy">Private</li>
            <li className="required">Urgent</li>

          </ul>

        </div>

        <div className='rel-card-bm'>
          <button className='rel-bm-i'><FiBookmark /></button>
        </div>


      </section>

    </Col> */}

              </Row>

            </div>

          </div>

          <DashCopyRightFooter />

        </Col>
      </Row>
      {/* ****************** Dashboard body end ****************** */}

    </div>
  )
}

export default EmployerDashboard;