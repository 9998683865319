import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { RiFilter3Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import blogImg from '../Assets/Images/blogImg1.jpg';
import recentImg from '../Assets/Images/employers-single-2.png';
import recentImg2 from '../Assets/Images/employers-single-4.png';
import logo from '../Assets/Images/logo.png';
import recentImg3 from '../Assets/Images/video-img.jpg';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';
import { handleBlogOut, handleBlogZoom, handleRecentOut, handleRecentZoom } from '../Functions/blogList';


const BlogList = () => {

    let [sideFilterToggle, setSideFilterToggle] = useState(false);

    return (
        <div>

            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Employe banner start -------------- */}
            <section className='emp-banner'>

                <Container>

                    <div className='emp-banner-inner space-y-4'>
                        <h1 className='emp-page-title'>Blog</h1>
                        <p className='emp-banner-txt space-x-2'>
                            <Link to={'/'} className='emp-home-link'>Home</Link> /
                            <span className='company-title-txt'>Blog</span>
                        </p>
                    </div>

                </Container>

            </section>

            {/* *************** Employe banner end ***************** */}
            <section className="job-sec">

                <Container>

                    {/* ---------- FILTER SIDEBAR START -------- */}
                    {sideFilterToggle && (

                        <div className='flex-filter-outer-candi'>

                            {/* ---------- Menu filter START -------- */}
                            <div className='f-j-filter-header'>
                                <Link to={'/'}>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                                <div className='close-inner' onClick={() => setSideFilterToggle(false)}>
                                    <AiOutlineClose />
                                </div>
                            </div>
                            {/* ---------- Search by keywords -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Search by keywords</span>
                                <ul className="filter-list">

                                    <li className="job-list flex-start">
                                        <div className="candi-list-filter-inp">
                                            <IoSearchOutline id="location-icon" style={{ fontSize: 20 }} />
                                            <input
                                                type="text"
                                                placeholder='Keywords'
                                                className="candi-list-filter-inp-txt ml-2 box-txt"
                                                name="location"
                                            // onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                        {/* <span>Freelancer</span> */}
                                    </li>

                                </ul>
                            </section>
                            {/* ---------- Categories -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Categories</span>
                                <ul className="b-category-list">
                                    <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                    <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                    <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                    <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                    <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                    <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                    <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                    <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                </ul>

                            </section>
                            {/* ---------- Recents post -------- */}
                            <section className="recents-post space-y-4">
                                <span className='f-titles'>Recents Post</span>

                                <div className="blog-recent-card" onMouseEnter={handleRecentZoom} onMouseOut={handleRecentOut}>
                                    <div className="recnet-img">
                                        <img src={recentImg2} id='recentImg' alt="recents images" />
                                    </div>
                                    <div className='recent-info'>
                                        <Link to={'/'} className='recent-info-link'>Overworked Newspaper Editor</Link> <br />
                                        <span className='recent-info-txt'>August 9, 2021</span>
                                    </div>
                                </div>

                                <div className="blog-recent-card">
                                    <div className="recnet-img">
                                        <img src={recentImg3} alt="recents images" />
                                    </div>
                                    <div className='recent-info'>
                                        <Link to={'/'} className='recent-info-link'>Overworked Newspaper Editor</Link> <br />
                                        <span className='recent-info-txt'>August 9, 2021</span>
                                    </div>
                                </div>

                                <div className="blog-recent-card">
                                    <div className="recnet-img">
                                        <img src={recentImg} alt="recents images" />
                                    </div>
                                    <div className='recent-info'>
                                        <Link to={'/'} className='recent-info-link'>Overworked Newspaper Editor</Link> <br />
                                        <span className='recent-info-txt'>August 9, 2021</span>
                                    </div>
                                </div>

                            </section>
                            {/* ---------- Tags -------- */}
                            <section className='tags'>
                                <span className='f-titles tag-h'>Tags</span>

                                <ul className='filter-list flex-tag'>
                                    <li className='job-list tags-li'>App</li>
                                    <li className='job-list tags-li'>Administrative</li>
                                    <li className='job-list tags-li'>Android</li>
                                    <li className='job-list tags-li'>Wordpress</li>
                                    <li className='job-list tags-li'>Design</li>
                                    <li className='job-list tags-li'>React</li>
                                </ul><br /><br />

                            </section>

                        </div>

                    )}
                    {/* ************* FILTER SIDEBAR END **************** */}

                    <Row>

                        {/* ---------- Blog cards START -------- */}
                        <Col lg={8} md={12} sm={12} className='' >

                            <div className='space-y-8'>

                                <button className='filter-btn' onClick={() => setSideFilterToggle(true)}>
                                    <RiFilter3Line className='filter-icon' />
                                    <span>Filter</span>
                                </button>

                                <div className="blog-card" id='blog1' onMouseEnter={handleBlogZoom} onMouseOut={handleBlogOut}>
                                    <div className="blog-img">
                                        <img id='blogImg' src={blogImg} alt="recents images" />
                                    </div>
                                    <div className='blog-info space-y-4'>

                                        <Link to={'/blog/details'} className='blog-date space-x-4'>
                                            <span>August 31, 2021</span>
                                            <li>12 comments</li>
                                        </Link>

                                        <Link to={'/blog/details'} className='blog-link'>
                                            Attract sales and profits
                                        </Link>

                                        <p className='blog-p-txt'>
                                            A job ravenously while Far much that one rank beheld after outside....
                                        </p>

                                        <Link to={'/blog/details'} className='blog-read-more space-x-5 mt-3'>
                                            <span className='read-more-txt'>Read more </span>
                                            <IoIosArrowForward />
                                        </Link>

                                    </div>

                                </div>

                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={recentImg3} alt="recents images" />
                                    </div>
                                    <div className='blog-info space-y-4'>

                                        <Link to={'/blog/details'} className='blog-date space-x-4'>
                                            <span>August 31, 2021</span>
                                            <li>12 comments</li>
                                        </Link>

                                        <Link to={'/blog/details'} className='blog-link'>
                                            Attract sales and profits
                                        </Link>

                                        <p className='blog-p-txt'>
                                            A job ravenously while Far much that one rank beheld after outside....
                                        </p>

                                        <Link to={'/blog/details'} className='blog-read-more space-x-5 mt-3'>
                                            <span className='read-more-txt'>Read more </span>
                                            <IoIosArrowForward />
                                        </Link>

                                    </div>

                                </div>

                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={recentImg} alt="recents images" />
                                    </div>
                                    <div className='blog-info space-y-4'>

                                        <Link to={'/'} className='blog-date space-x-4'>
                                            <span>August 31, 2021</span>
                                            <li>12 comments</li>
                                        </Link>

                                        <Link to={'/'} className='blog-link'>
                                            Attract sales and profits
                                        </Link>

                                        <p className='blog-p-txt'>
                                            A job ravenously while Far much that one rank beheld after outside....
                                        </p>

                                        <Link className='blog-read-more space-x-5 mt-3'>
                                            <span className='read-more-txt'>Read more </span>
                                            <IoIosArrowForward />
                                        </Link>

                                    </div>

                                </div>

                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={recentImg2} alt="recents images" />
                                    </div>
                                    <div className='blog-info space-y-4'>

                                        <Link to={'/'} className='blog-date space-x-4'>
                                            <span>August 31, 2021</span>
                                            <li>12 comments</li>
                                        </Link>

                                        <Link to={'/'} className='blog-link'>
                                            Attract sales and profits
                                        </Link>

                                        <p className='blog-p-txt'>
                                            A job ravenously while Far much that one rank beheld after outside....
                                        </p>

                                        <Link className='blog-read-more space-x-5 mt-3'>
                                            <span className='read-more-txt'>Read more </span>
                                            <IoIosArrowForward />
                                        </Link>

                                    </div>

                                </div>

                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={recentImg2} alt="recents images" />
                                    </div>
                                    <div className='blog-info space-y-4'>

                                        <Link to={'/'} className='blog-date space-x-4'>
                                            <span>August 31, 2021</span>
                                            <li>12 comments</li>
                                        </Link>

                                        <Link to={'/'} className='blog-link'>
                                            Attract sales and profits
                                        </Link>

                                        <p className='blog-p-txt'>
                                            A job ravenously while Far much that one rank beheld after outside....
                                        </p>

                                        <Link className='blog-read-more space-x-5 mt-3'>
                                            <span className='read-more-txt'>Read more </span>
                                            <IoIosArrowForward />
                                        </Link>

                                    </div>

                                </div>

                            </div>

                            {/* ----------- Cards Pagination ------------ */}
                            <Stack spacing={2} className='pagi-center'>
                                <Pagination
                                    sx={{
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
                                            fontSize: '14px',
                                            padding: '25px 20px',
                                            borderRadius: '200px',
                                        },
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover': {
                                            backgroundColor: 'rgb(0, 85, 182)',
                                            color: 'white',
                                            transition: '0.3s ease-in-out'
                                        },
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
                                            backgroundColor: 'rgb(0, 85, 182)',
                                            color: 'white',
                                        },
                                    }}
                                    count={3}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Stack>

                        </Col>

                        {/* ---------- Blog filter START -------- */}
                        <Col lg={4} md={12} sm={12} className='space-y-10 blog-side-filter-col-s-h'>
                            <div className='blog-filter'>

                                {/* ---------- Search by keywords -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Search by keywords</span>
                                    <ul className="filter-list">

                                        <li className="job-list flex-start">
                                            <div className="blog-list-s-inp" style={{ border: '0px', borderRadius: '10px', boxShadow: '0 0 5px #e3e0e0' }}>
                                                <IoSearchOutline id="location-icon-emp-list" className='ml-1' />
                                                <input
                                                    type="text"
                                                    placeholder='Keywords'
                                                    className="inp-txt ml-2 box-txt"
                                                    name="location"
                                                />
                                            </div>
                                        </li>

                                    </ul>
                                </section>
                                {/* ---------- Categories -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Categories</span>
                                    <ul className="b-category-list">
                                        <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                        <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                        <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                        <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                        <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                        <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                        <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                        <li className="b-list-ctgry"><a href="#" className='b-list-ctgry-link'>Education</a></li>
                                    </ul>

                                </section>
                                {/* ---------- Recents post -------- */}
                                <section className="recents-post space-y-4">
                                    <span className='f-titles'>Recents Post</span>

                                    <div className="blog-recent-card" onMouseEnter={handleRecentZoom} onMouseOut={handleRecentOut}>
                                        <div className="recnet-img">
                                            <img src={recentImg2} id='recentImg' alt="recents images" />
                                        </div>
                                        <div className='recent-info'>
                                            <Link to={'/'} className='recent-info-link'>Overworked Newspaper Editor</Link> <br />
                                            <span className='recent-info-txt'>August 9, 2021</span>
                                        </div>
                                    </div>

                                    <div className="blog-recent-card">
                                        <div className="recnet-img">
                                            <img src={recentImg3} alt="recents images" />
                                        </div>
                                        <div className='recent-info'>
                                            <Link to={'/'} className='recent-info-link'>Overworked Newspaper Editor</Link> <br />
                                            <span className='recent-info-txt'>August 9, 2021</span>
                                        </div>
                                    </div>

                                    <div className="blog-recent-card">
                                        <div className="recnet-img">
                                            <img src={recentImg} alt="recents images" />
                                        </div>
                                        <div className='recent-info'>
                                            <Link to={'/'} className='recent-info-link'>Overworked Newspaper Editor</Link> <br />
                                            <span className='recent-info-txt'>August 9, 2021</span>
                                        </div>
                                    </div>

                                </section>
                                {/* ---------- Tags -------- */}
                                <section className='tags'>
                                    <span className='f-titles tag-h'>Tags</span>

                                    <ul className='filter-list flex-tag'>
                                        <li className='job-list tags-li'>App</li>
                                        <li className='job-list tags-li'>Administrative</li>
                                        <li className='job-list tags-li'>Android</li>
                                        <li className='job-list tags-li'>Wordpress</li>
                                        <li className='job-list tags-li'>Design</li>
                                        <li className='job-list tags-li'>React</li>
                                    </ul><br /><br />

                                </section>

                            </div>

                            {/* ************* Blog filter END **************** */}
                        </Col>

                    </Row>

                </Container>
            </section>

            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}


        </div>
    )
}

export default BlogList