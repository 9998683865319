import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MdDone } from "react-icons/md";
import { Link } from 'react-router-dom';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';


const Pricing = () => {
    return (
        <div>
            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Employe banner start -------------- */}
            <section className='emp-banner'>

                <Container>

                    <div className='emp-banner-inner space-y-4'>
                        <h1 className='emp-page-title'>Pricing</h1>
                        <p className='emp-banner-txt space-x-2'>
                            <Link to={'/'} className='emp-home-link'>Home</Link> /
                            <span className='company-title-txt'>Pricing</span>
                        </p>
                    </div>

                </Container>

            </section>
            {/* *************** Employe banner end ***************** */}

            {/* ----------------- Faq sec heading start -------------- */}
            <div className="content-body">

                <div className='faq-sec-heading mt-5 mb-4'>
                    <h1>Pricing Packages</h1>
                    <p>Lorem ipsum dolor sit amet elit, sed do eiusmod tempor.</p>
                </div>
                {/* *************** Faq sec heading end ***************** */}


                {/* ----------------- Payments accordian start -------------- */}
                <Container>
                    <Row>
                        <Col lg={4} md={6} sm={12} className="mt-3">
                            <div className="pricing-card-outer">

                                <span className='p-card-title'>Basic</span>
                                <h2 className='p-plan-price'>$199</h2>
                                <span className='p-plan-year'> / monthly</span>

                                <ul className='p-plan-ul'>
                                    <li> <MdDone /> <span> 30 job posting </span> </li>
                                    <li> <MdDone /> <span> 3 featured job </span> </li>
                                    <li> <MdDone /> <span> Job displayed for 15 days </span> </li>
                                    <li> <MdDone /> <span> Premium Support 24/7 </span> </li>
                                </ul>

                                <button className='v-pro-btn' style={{ height: 45 }}>Add to Cart</button>

                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12} className="mt-3">
                            <div className="pricing-card-outer">

                                <span className='p-card-title'>Standard</span>
                                <h2 className='p-plan-price'>$499</h2>
                                <span className='p-plan-year'> / monthly</span>

                                <ul className='p-plan-ul'>
                                    <li> <MdDone /> <span> 30 job posting </span> </li>
                                    <li> <MdDone /> <span> 3 featured job </span> </li>
                                    <li> <MdDone /> <span> Job displayed for 15 days </span> </li>
                                    <li> <MdDone /> <span> Premium Support 24/7 </span> </li>
                                </ul>

                                <button className='v-pro-btn' style={{ height: 45 }}>Add to Cart</button>

                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12} className="mt-3">
                            <div className="pricing-card-outer">

                                <span className='p-card-title'>Extended</span>
                                <h2 className='p-plan-price'>$799</h2>
                                <span className='p-plan-year'> / monthly</span>

                                <ul className='p-plan-ul'>
                                    <li> <MdDone /> <span> 30 job posting </span> </li>
                                    <li> <MdDone /> <span> 3 featured job </span> </li>
                                    <li> <MdDone /> <span> Job displayed for 15 days </span> </li>
                                    <li> <MdDone /> <span> Premium Support 24/7 </span> </li>
                                </ul>

                                <button className='v-pro-btn' style={{ height: 45 }}>Add to Cart</button>

                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
            {/* *************** Content body end ***************** */}

            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}

        </div>
    )
}

export default Pricing