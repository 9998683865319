import React, { useState } from 'react';
import EmpDashHeader from '../Components/EmpDashHeader';
import EmpDashSideBar from '../Components/EmpDashSideBar';
import { Container, Row, Col } from 'react-bootstrap';
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SlHome } from "react-icons/sl";
import { LuUser2 } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineBell } from "react-icons/ai";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import { GoArrowUp } from "react-icons/go";
import { IoAddSharp } from "react-icons/io5";
import { GoPencil } from "react-icons/go";
import Select from 'react-select';
import { colourOptions } from '../Functions/data.ts';
import { NavLink, Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import CandiDashSideMenu from '../Components/CandiDashSideMenu';
import logo from '../Assets/Images/logo.png';

import { DashCopyRightFooter } from '../Components/Footer.jsx';

const MyResume = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <EmpDashHeader />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>
                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <CandiDashSideMenu />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>My Resume!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/candidate/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/my/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> My Profile </span></NavLink></li>
                                        <li><NavLink to={'/my/resume'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> My Resume </span></NavLink></li>
                                        <li><NavLink to={'/job/recommendation'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Recommendation </span></NavLink></li>
                                        <li><NavLink to={'/applied/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Applied Jobs </span></NavLink></li>
                                        <li><NavLink to={'/Job/Alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Alerts </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/jobs'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Jobs </span></NavLink></li>
                                        <li><NavLink to={'/cv/manager'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> CV Manager </span></NavLink></li>
                                        <li><NavLink to={'/candidate/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        <div className="empdash-profile-sec">

                            <h5 className='empdash-profile-heading'>My Resume</h5>

                            <form action="#">

                                <Row>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="age">Select your CV</label>
                                        <Form.Select aria-label="Default select example" id='age' className="b-dtls-name">
                                            <option value="">My CV</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="description">Description</label>
                                        <textarea type="text" id='description' className='b-dtls-message' placeholder='Write your message...' required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} className='mt-4'>
                                        <div className='cv-education mb-0'>

                                            <div className="edu-title dashResume-edu-title mb-5">
                                                <h4 className='candi-h'>Education</h4>
                                                <div className='dashResume-add-edu space-x-3'>
                                                    <span className='cv-add-edu-i'><IoAddSharp /></span>
                                                    <span className='cv-add-edu-txt'>Add education</span>
                                                </div>
                                            </div>

                                            <div className="cv-block">
                                                <div className="block-inner-redish">
                                                    <span className='initial-letter redish redish-bg'>M</span>
                                                    <div className="cv-title-box" style={{ rowGap: 20 }}>
                                                        <div className="place-name" style={{ lineHeight: 1 }}>
                                                            <h3>Bachlors in Fine Arts</h3>
                                                            <span className='cv-info-title redish'>Modern College</span>
                                                        </div>
                                                        <div className="from-to-box dashResume-from-to-year">
                                                            <span className='edu-year redish redish-bg'>2012-2014</span>
                                                            <div className="dash-resume-btn-sec">
                                                                <button type='button' className='dash-manage-btn'><GoPencil /></button>
                                                                <button type='button' className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='edu-txt'>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                                                        Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                                                        Alias amet odit excepturi nostrum aliquam facilis.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="cv-block">
                                                <div className="block-inn">
                                                    <span className='initial-letter redish redish-bg'>H</span>
                                                    <div className="cv-title-box" style={{ rowGap: 20 }}>
                                                        <div className="place-name" style={{ lineHeight: 1 }}>
                                                            <h3>Bachlors in Fine Arts</h3>
                                                            <span className='cv-info-title redish'>Modern College</span>
                                                        </div>
                                                        <div className="from-to-box dashResume-from-to-year">
                                                            <span className='edu-year redish redish-bg'>2012-2014</span>
                                                            <div className="dash-resume-btn-sec">
                                                                <button type='button' className='dash-manage-btn'><GoPencil /></button>
                                                                <button type='button' className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='edu-txt'>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                                                        Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                                                        Alias amet odit excepturi nostrum aliquam facilis.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <div className='cv-education mb-0'>

                                            <div className="edu-title dashResume-edu-title mb-5">
                                                <h4 className='candi-h'>Work & Experience</h4>
                                                <div className='dashResume-add-edu space-x-3'>
                                                    <span className='cv-add-edu-i'><IoAddSharp /></span>
                                                    <span className='cv-add-edu-txt'>Add Work</span>
                                                </div>
                                            </div>


                                            <div className="cv-block">
                                                <div className="block-inner-blueish">
                                                    <span className='initial-letter blueish blueish-bg'>M</span>
                                                    <div className="cv-title-box" style={{ rowGap: 20 }}>
                                                        <div className="place-name" style={{ lineHeight: 1 }}>
                                                            <h3>Bachlors in Fine Arts</h3>
                                                            <span className='cv-info-title blueish'>Modern College</span>
                                                        </div>
                                                        <div className="from-to-box dashResume-from-to-year">
                                                            <span className='edu-year blueish blueish-bg'>2012-2014</span>
                                                            <div className="dash-resume-btn-sec">
                                                                <button type='button' className='dash-manage-btn'><GoPencil /></button>
                                                                <button type='button' className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='edu-txt'>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                                                        Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                                                        Alias amet odit excepturi nostrum aliquam facilis.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="cv-block">
                                                <div className="block-inn">
                                                    <span className='initial-letter blueish blueish-bg'>H</span>
                                                    <div className="cv-title-box" style={{ rowGap: 20 }}>
                                                        <div className="place-name" style={{ lineHeight: 1 }}>
                                                            <h3>Bachlors in Fine Arts</h3>
                                                            <span className='cv-info-title blueish'>Modern College</span>
                                                        </div>
                                                        <div className="from-to-box dashResume-from-to-year">
                                                            <span className='edu-year blueish blueish-bg'>2012-2014</span>
                                                            <div className="dash-resume-btn-sec">
                                                                <button type='button' className='dash-manage-btn'><GoPencil /></button>
                                                                <button type='button' className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='edu-txt'>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                                                        Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                                                        Alias amet odit excepturi nostrum aliquam facilis.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg={6} md={12} sm={12}>
                                        <div className='dash-uploading-cover-outer mb-5'>

                                            <label htmlFor="upload" className="dash-upload-file">
                                                <input type="file" className='dash-upload-file-btn' name="attachments[]" id="upload" accept='image/*' required />
                                                <span id='upload' className='dash-browse-logo'>
                                                    <GoArrowUp className='dash-upload-i' />
                                                    <span style={{ color: 'black' }}> Add Portfolio </span>
                                                </span>
                                            </label>

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <div className='cv-education mb-0'>

                                            <div className="edu-title dashResume-edu-title mb-5">
                                                <h4 className='candi-h '>Awards</h4>
                                                <div className='dashResume-add-edu space-x-3'>
                                                    <span className='cv-add-edu-i'><IoAddSharp /></span>
                                                    <span className='cv-add-edu-txt'>Awards</span>
                                                </div>
                                            </div>

                                            <div className="cv-block">
                                                <div className="block-inner-yellowish">
                                                    <span className='initial-letter yellowish yellowish-bg'>M</span>
                                                    <div className="cv-title-box" style={{ rowGap: 20 }}>
                                                        <div className="place-name" style={{ lineHeight: 1 }}>
                                                            <h3>Bachlors in Fine Arts</h3>
                                                            <span className='cv-info-title yellowish'>Modern College</span>
                                                        </div>
                                                        <div className="from-to-box dashResume-from-to-year">
                                                            <span className='edu-year yellowish yellowish-bg'>2012-2014</span>
                                                            <div className="dash-resume-btn-sec">
                                                                <button type='button' className='dash-manage-btn'><GoPencil /></button>
                                                                <button type='button' className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='edu-txt'>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                                                        Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                                                        Alias amet odit excepturi nostrum aliquam facilis.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="cv-block">
                                                <div className="block-inn">
                                                    <span className='initial-letter yellowish yellowish-bg'>H</span>
                                                    <div className="cv-title-box" style={{ rowGap: 20 }}>
                                                        <div className="place-name" style={{ lineHeight: 1 }}>
                                                            <h3>Bachlors in Fine Arts</h3>
                                                            <span className='cv-info-title yellowish'>Modern College</span>
                                                        </div>
                                                        <div className="from-to-box dashResume-from-to-year">
                                                            <span className='edu-year yellowish yellowish-bg'>2012-2014</span>
                                                            <div className="dash-resume-btn-sec">
                                                                <button type='button' className='dash-manage-btn'><GoPencil /></button>
                                                                <button type='button' className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='edu-txt'>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel rem perferendis.
                                                        Iure tempore neque nobis reprehenderit. Laudantium ipsam cum explicabo fugiat ut. Odio, impedit.
                                                        Alias amet odit excepturi nostrum aliquam facilis.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="categories">Skills</label>
                                        <Select
                                            defaultValue={[colourOptions[2], colourOptions[3]]}
                                            isMulti
                                            id='categories'
                                            name="colors"
                                            options={colourOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} className="b-thoughts">
                                        <button type='submit' className='b-dtls-send-msg dash-save-btn'>Save</button>
                                    </Col>
                                </Row>

                            </form>

                        </div>

                    </div>
                    <DashCopyRightFooter />

                </Col>
            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default MyResume