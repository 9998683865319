import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { AiOutlineClose } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { PiSuitcaseSimpleThin } from "react-icons/pi";
import { RiFilter3Line } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import candiImg from '../Assets/Images/candidate-1.png';
import logo from '../Assets/Images/logo.png';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';



const Candidates = () => {

    let navigate = useNavigate();
    const minDistance = 10;
    const [value1, setValue1] = useState([0, 100]);
    let [sideFilterToggle, setSideFilterToggle] = useState(false);
    let [quantityJob, setQuantityJob] = useState("all");


    function valuetext(value) {
        return `${value}°C`;
    }
    const handleChange1 = (
        event,
        newValue,
        activeThumb,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
        } else {
            setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
        }
    };

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Choose a category");
    const [isRotated, setIsRotated] = useState(false);
    const dropdownRef = useRef(null);
    const [showOrganization, setShowOrganization] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState("Choose your gender");
    const [isRotatedOrgan, setIsRotatedOrgan] = useState(false);
    const dropOrganRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setIsRotated(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOrganOutside = (event) => {
            if (dropOrganRef.current && !dropOrganRef.current.contains(event.target)) {
                setShowOrganization(false);
                setIsRotatedOrgan(false);
            }
        };

        document.addEventListener("mousedown", handleOrganOutside);

        return () => {
            document.removeEventListener("mousedown", handleOrganOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setIsRotated(!isRotated);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setShowDropdown(false);
        setIsRotated(false);
    };

    const toggleOrganization = () => {
        setShowOrganization(!showOrganization);
        setIsRotatedOrgan(!isRotatedOrgan);
    };

    const handleOrganOption = (option) => {
        setSelectedOrganization(option);
        setShowOrganization(false);
        setIsRotatedOrgan(false);
    };

    let viewProfile = () => {
        navigate('/candidates/Single')
    }

    return (
        <div>
            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Employe banner start -------------- */}
            <section className='emp-banner'>

                <Container>

                    <div className='emp-banner-inner space-y-4'>
                        <h1 className='emp-page-title'>Candidates</h1>
                        <p className='emp-banner-txt space-x-2'>
                            <Link to={'/'} className='emp-home-link'>Home</Link> /
                            <span className='company-title-txt'>Candidates</span>
                        </p>
                    </div>

                </Container>

            </section>
            {/* *************** Employe banner end ***************** */}

            <section className="job-sec">

                <Container>

                    {/* ---------- FILTER SIDEBAR START -------- */}

                    {sideFilterToggle && (

                        <div className='flex-filter-outer-candi'>

                            {/* ---------- Menu filter START -------- */}
                            <div className='f-j-filter-header'>
                                <Link to={'/'}>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                                <div className='close-inner' onClick={() => setSideFilterToggle(false)}>
                                    <AiOutlineClose />
                                </div>
                            </div>
                            {/* ---------- Search by keywords START -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Search by keywords</span>
                                <ul className="filter-list">

                                    <li className="job-list flex-start">
                                        <div className="candi-list-filter-inp">
                                            <IoSearchOutline id="location-icon" style={{ fontSize: 20 }} />
                                            <input
                                                type="text"
                                                placeholder='Job title, keywords or company '
                                                className="candi-list-filter-inp-txt ml-2 box-txt"
                                                name="location"
                                            // onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                        {/* <span>Freelancer</span> */}
                                    </li>

                                </ul>
                            </section>
                            {/* ---------- Location START -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Location</span>
                                <ul className="filter-list">

                                    <li className="job-list flex-start">
                                        <div className="candi-list-filter-inp">
                                            <CiLocationOn id="location-icon" />
                                            <input
                                                type="text"
                                                placeholder='Job title, keywords or company '
                                                className="candi-list-filter-inp-txt ml-2 box-txt"
                                                name="location"
                                            // onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                        {/* <span>Freelancer</span> */}
                                    </li>

                                </ul>
                                <p className='radius-txt'>Radius around selected destination</p>
                                <Box sx={{ width: '95%' }}>
                                    <Slider
                                        getAriaLabel={() => 'Minimum distance'}
                                        value={value1}
                                        onChange={handleChange1}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                        disableSwap
                                    />
                                </Box>

                            </section>
                            {/* ---------- Category SECTION START -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Category</span>

                                <div className={`select-menu emp-select-ctg-filter ${showDropdown ? 'active' : ''}`} ref={dropdownRef} style={{ height: '57px' }}>

                                    <div className="emp-list-select-btn" onClick={toggleDropdown}>
                                        <div className="f-j-drop-down-name-i">
                                            <PiSuitcaseSimpleThin id="location-icon-emp-list" />
                                            <span className="f-j-sBtn-txt">{selectedOption}</span>
                                        </div>
                                        <IoIosArrowDown className={`a-icon ${isRotated ? 'rotated' : ''}`} />
                                    </div>

                                    {showDropdown && (
                                        <ul className="options">

                                            <li className="option" onClick={() => handleOptionClick("Choose a category")}>
                                                <span className={`option-txt ${selectedOption === "Choose a category" ? 'selected' : ''}`}>Choose a category</span>
                                            </li>
                                            <li className="option" onClick={() => handleOptionClick("Movie & Film Name")}>
                                                <span className={`option-txt ${selectedOption === "Movie & Film Name" ? 'selected' : ''}`}>Movie & Film Name</span>
                                            </li>
                                            <li className="option" onClick={() => handleOptionClick("Graphic Design")}>
                                                <span className={`option-txt ${selectedOption === "Graphic Design" ? 'selected' : ''}`}>Graphic Design</span>
                                            </li>
                                            <li className="option" onClick={() => handleOptionClick("Option 3")}>
                                                <span className={`option-txt ${selectedOption === "Option 3" ? 'selected' : ''}`}>Option 3</span>
                                            </li>

                                        </ul>
                                    )}
                                </div>

                            </section>
                            {/* ---------- Candidates gender SECTION START -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Candidates Gender</span>

                                <div className={`select-menu emp-select-ctg-filter ${showOrganization ? 'active' : ''}`} ref={dropOrganRef} style={{ height: '57px' }}>

                                    <div className="emp-list-select-btn" onClick={toggleOrganization}>
                                        <div className="f-j-drop-down-name-i">
                                            <PiSuitcaseSimpleThin id="location-icon-emp-list" />
                                            <span className="f-j-sBtn-txt">{selectedOrganization}</span>
                                        </div>
                                        <IoIosArrowDown className={`a-icon ${isRotatedOrgan ? 'rotated' : ''}`} />

                                    </div>
                                    {showOrganization && (
                                        <ul className="options">

                                            <li className="option" onClick={() => handleOrganOption("Choose Your gender")}>
                                                <span className={`option-txt ${selectedOrganization === "Choose Your gender" ? 'selected' : ''}`}>Choose Your gender</span>
                                            </li>
                                            <li className="option" onClick={() => handleOrganOption("Male")}>
                                                <span className={`option-txt ${selectedOrganization === "Male" ? 'selected' : ''}`}>Male</span>
                                            </li>
                                            <li className="option" onClick={() => handleOrganOption("Female")}>
                                                <span className={`option-txt ${selectedOrganization === "Female" ? 'selected' : ''}`}>Female</span>
                                            </li>
                                            <li className="option" onClick={() => handleOrganOption("Other")}>
                                                <span className={`option-txt ${selectedOrganization === "Other" ? 'selected' : ''}`}>Other</span>
                                            </li>

                                        </ul>
                                    )}
                                </div>

                            </section>
                            {/* ---------- Date posted SECTION START -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Date Posted</span>
                                <ul className="filter-list">

                                    <li className="job-list flex-start">
                                        <div className='radio-sec space-x-2'>
                                            <input type="radio" name="datePosted" value="" id="all" className='radio-crsr m-0' />
                                            <label htmlFor="all" className='radio-crsr'>All</label>
                                        </div>
                                    </li>
                                    <li className="job-list">
                                        <div className='radio-sec space-x-2'>
                                            <input type="radio" name="datePosted" value="" id="lasthour" className='radio-crsr m-0' />
                                            <label htmlFor='lasthour' className='radio-crsr'>LastHour</label>
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div className='radio-sec space-x-2'>
                                            <input type="radio" name="datePosted" value="" id="last24" className='radio-crsr m-0' />
                                            <label htmlFor="last24" className='radio-crsr'>Last24Hour</label>
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div className='radio-sec space-x-2'>
                                            <input type="radio" name="datePosted" value="" id="last7" className='radio-crsr m-0' />
                                            <label htmlFor="last7" className='radio-crsr'>Last7Days</label>
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div className='radio-sec space-x-2'>
                                            <input type="radio" name="datePosted" value="" id="last14" className='radio-crsr m-0' />
                                            <label htmlFor="last14" className='radio-crsr'>Last14Days</label>
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div className='radio-sec space-x-2'>
                                            <input type="radio" name="datePosted" value="" id="last30" className='radio-crsr m-0' />
                                            <label htmlFor="last30" className='radio-crsr'>Last30Days</label>
                                        </div>
                                    </li>

                                </ul>
                            </section>
                            {/* ---------- Experience SECTION START -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Experience</span>
                                <ul className="filter-list">

                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="fresh"
                                                label="Fresh"
                                            />
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="year1"
                                                label="1 Year"
                                            />
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="year2"
                                                label="2 Year"
                                            />
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="year3"
                                                label="3 Year"
                                            />
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="year4"
                                                label="4 Year"
                                            />
                                        </div>
                                    </li>

                                </ul>
                            </section>
                            {/* ---------- Qualification SECTION START -------- */}
                            <section className="job-typ">
                                <span className='f-titles'>Qualification</span>
                                <ul className="filter-list">

                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="fresh"
                                                label="Fresh"
                                            />
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="year1"
                                                label="1 Year"
                                            />
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="year2"
                                                label="2 Year"
                                            />
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="year3"
                                                label="3 Year"
                                            />
                                        </div>
                                    </li>
                                    <li className="job-list flex-start">
                                        <div>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="year4"
                                                label="4 Year"
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </section>
                            <br /><br />

                        </div>

                    )}

                    {/* ************* FILTER SIDEBAR END **************** */}

                    <Row>
                        {/* ---------- SIDE FILTER START -------- */}
                        <Col lg={4} className='space-y-10 side-hide'>
                            <div className='flex-filter'>

                                {/* ---------- Job type START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Search by keywords</span>
                                    <ul className="filter-list">

                                        <li className="job-list flex-start">
                                            <div className="emp-list-inp space-x-2" id="emp-search-filter-inp">
                                                <IoSearchOutline id="location-icon-emp-list" className='ml-5' />
                                                <input
                                                    type="text"
                                                    placeholder='Job title, keywords or company '
                                                    className="inp-txt ml-2 box-txt"
                                                    name="location"
                                                // onClick={inpBorderChg}
                                                // onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </li>

                                    </ul>
                                </section>
                                {/* ---------- Location START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Location</span>
                                    <ul className="filter-list">

                                        <li className="job-list flex-start">
                                            <div className="emp-list-inp space-x-2" id="emp-search-filter-inp">
                                                <CiLocationOn id="location-icon-emp-list" className='ml-5' />
                                                <input
                                                    type="text"
                                                    placeholder='Find Location'
                                                    className="inp-txt ml-2 box-txt"
                                                    name="location"
                                                // onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>

                                    </ul>
                                    <p className='radius-txt'>Radius around selected destination</p>
                                    <Box sx={{ width: '100%' }}>
                                        <Slider
                                            getAriaLabel={() => 'Minimum distance'}
                                            value={value1}
                                            onChange={handleChange1}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            disableSwap
                                        />
                                    </Box>

                                </section>
                                {/* ---------- Category SECTION START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Category</span>

                                    <div className={`select-menu emp-select-ctg-filter ${showDropdown ? 'active' : ''}`} ref={dropdownRef}>

                                        <div className="emp-list-select-btn" onClick={toggleDropdown}>
                                            <div className="f-j-drop-down-name-i">
                                                <PiSuitcaseSimpleThin id="location-icon-emp-list" />
                                                <span className="f-j-sBtn-txt">{selectedOption}</span>
                                            </div>
                                            <IoIosArrowDown className={`a-icon ${isRotated ? 'rotated' : ''}`} />
                                        </div>

                                        {showDropdown && (
                                            <ul className="options">

                                                <li className="option" onClick={() => handleOptionClick("Choose a category")}>
                                                    <span className={`option-txt ${selectedOption === "Choose a category" ? 'selected' : ''}`}>Choose a category</span>
                                                </li>
                                                <li className="option" onClick={() => handleOptionClick("Movie & Film Name")}>
                                                    <span className={`option-txt ${selectedOption === "Movie & Film Name" ? 'selected' : ''}`}>Movie & Film Name</span>
                                                </li>
                                                <li className="option" onClick={() => handleOptionClick("Graphic Design")}>
                                                    <span className={`option-txt ${selectedOption === "Graphic Design" ? 'selected' : ''}`}>Graphic Design</span>
                                                </li>
                                                <li className="option" onClick={() => handleOptionClick("Option 3")}>
                                                    <span className={`option-txt ${selectedOption === "Option 3" ? 'selected' : ''}`}>Option 3</span>
                                                </li>

                                            </ul>
                                        )}
                                    </div>
                                </section>
                                {/* ---------- Candidates gender SECTION START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Candidates Gender</span>

                                    <div className={`select-menu emp-select-ctg-filter ${showOrganization ? 'active' : ''}`} ref={dropOrganRef}>

                                        <div className="emp-list-select-btn" onClick={toggleOrganization}>
                                            <div className="f-j-drop-down-name-i">
                                                <PiSuitcaseSimpleThin id="location-icon-emp-list" />
                                                <span className="f-j-sBtn-txt">{selectedOrganization}</span>
                                            </div>
                                            <IoIosArrowDown className={`a-icon ${isRotatedOrgan ? 'rotated' : ''}`} />

                                        </div>
                                        {showOrganization && (
                                            <ul className="options">

                                                <li className="option" onClick={() => handleOrganOption("Choose Your gender")}>
                                                    <span className={`option-txt ${selectedOrganization === "Choose Your gender" ? 'selected' : ''}`}>Choose Your gender</span>
                                                </li>
                                                <li className="option" onClick={() => handleOrganOption("Male")}>
                                                    <span className={`option-txt ${selectedOrganization === "Male" ? 'selected' : ''}`}>Male</span>
                                                </li>
                                                <li className="option" onClick={() => handleOrganOption("Female")}>
                                                    <span className={`option-txt ${selectedOrganization === "Female" ? 'selected' : ''}`}>Female</span>
                                                </li>
                                                <li className="option" onClick={() => handleOrganOption("Other")}>
                                                    <span className={`option-txt ${selectedOrganization === "Other" ? 'selected' : ''}`}>Other</span>
                                                </li>

                                            </ul>
                                        )}
                                    </div>
                                </section>
                                {/* ---------- Date posted SECTION START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Date Posted</span>
                                    <ul className="filter-list">

                                        <li className="job-list flex-start">
                                            <div className='radio-sec space-x-2'>
                                                <input type="radio" name="datePosted" value="" id="all" className='radio-crsr m-0' />
                                                <label htmlFor="all" className='radio-crsr'>All</label>
                                            </div>
                                        </li>
                                        <li className="job-list">
                                            <div className='radio-sec space-x-2'>
                                                <input type="radio" name="datePosted" value="" id="lasthour" className='radio-crsr m-0' />
                                                <label htmlFor='lasthour' className='radio-crsr'>LastHour</label>
                                            </div>
                                        </li>
                                        <li className="job-list flex-start">
                                            <div className='radio-sec space-x-2'>
                                                <input type="radio" name="datePosted" value="" id="last24" className='radio-crsr m-0' />
                                                <label htmlFor="last24" className='radio-crsr'>Last24Hour</label>
                                            </div>
                                        </li>
                                        <li className="job-list flex-start">
                                            <div className='radio-sec space-x-2'>
                                                <input type="radio" name="datePosted" value="" id="last7" className='radio-crsr m-0' />
                                                <label htmlFor="last7" className='radio-crsr'>Last7Days</label>
                                            </div>
                                        </li>
                                        <li className="job-list flex-start">
                                            <div className='radio-sec space-x-2'>
                                                <input type="radio" name="datePosted" value="" id="last14" className='radio-crsr m-0' />
                                                <label htmlFor="last14" className='radio-crsr'>Last14Days</label>
                                            </div>
                                        </li>
                                        <li className="job-list flex-start">
                                            <div className='radio-sec space-x-2'>
                                                <input type="radio" name="datePosted" value="" id="last30" className='radio-crsr m-0' />
                                                <label htmlFor="last30" className='radio-crsr'>Last30Days</label>
                                            </div>
                                        </li>

                                    </ul>
                                </section>
                                {/* ---------- Experience SECTION START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Experience</span>
                                    <ul className="filter-list">

                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="fresh"
                                                    label="Fresh"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>
                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="year1"
                                                    label="1 Year"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>
                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="year2"
                                                    label="2 Year"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>
                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="year3"
                                                    label="3 Year"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>
                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="year4"
                                                    label="4 Year"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>

                                    </ul>
                                </section>
                                {/* ---------- Qualification SECTION START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Qualification</span>
                                    <ul className="filter-list">

                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="fresh"
                                                    label="Fresh"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>
                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="year1"
                                                    label="1 Year"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>
                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="year2"
                                                    label="2 Year"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>
                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="year3"
                                                    label="3 Year"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>
                                        <li className="job-list flex-start">
                                            <div>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="year4"
                                                    label="4 Year"
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>

                                    </ul>
                                </section>

                            </div>

                            {/* ************* FILTER SIDEBAR END **************** */}
                        </Col>

                        {/* ---------- Candidates cards Start -------- */}
                        <Col lg={8} md={12} sm={12} className=''>
                            <div className='job-tabs'>

                                {/* ---------- JOB Sorting buttons -------- */}
                                <div className='job-filter'>

                                    <button className='filter-btn' onClick={() => setSideFilterToggle(true)}>
                                        <RiFilter3Line className='filter-icon' />
                                        <span>Filter</span>
                                    </button>

                                    <div className='job-filter'>

                                        <Form.Select aria-label="Default select example" className='select-filter'>
                                            <option>Sort by (default)</option>
                                            <option value="new">Newest</option>
                                            <option value="old">Oldest</option>
                                        </Form.Select>

                                        <Form.Select aria-label="Default select example" className='select-filter' onChange={(e) => setQuantityJob(e.target.value)}>
                                            <option value="all">All</option>
                                            <option value="15">15 per page</option>
                                            <option value="20">20 per pageo</option>
                                            <option value="30">30 per page</option>
                                        </Form.Select>

                                    </div>

                                </div>

                                <div className='jobs-q'>
                                    <span>Show <span className='qty-txt'>{quantityJob}</span> jobs</span>
                                </div>

                                <div className='job-tabs-list space-y-8'>

                                    {/* ----------- Candidate CARD START ------------ */}

                                    <section className='comp-card-sec'>

                                        <Row className='comp-card-row'>

                                            <Col lg={6} md={6} sm={12} className='cards-col'>

                                                <div className='company-inner-card'>

                                                    <div className='comp-center-details'>

                                                        <div className='card-featu'>Featured</div>

                                                        <div className='details-sec'>

                                                            <img src={candiImg} alt="Company Logo" />
                                                            <Link to={'/candidates/Single'} className='candidate-name'>Robert Decoster</Link>
                                                            <span className='candi-prof'>UI Designer</span>
                                                            <div className='candi-flex space-x-7'>

                                                                <div className='candi-flex-tags space-x-2'>
                                                                    <CiLocationOn className='candi-card-i' />
                                                                    <span className='candi-details'>London, UK</span>
                                                                </div>
                                                                <div className='candi-flex-tags space-x-2'>
                                                                    <LiaMoneyBillWaveSolid className='candi-card-i' />
                                                                    <span className='candi-details'>$99 / hour</span>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <ul className='candidates-tags'>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>App</Link></li>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>Design</Link></li>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>Digital</Link></li>
                                                    </ul>

                                                    <button className='v-pro-btn' onClick={viewProfile}>View Profile</button>

                                                </div>

                                            </Col>

                                            <Col lg={6} md={6} sm={12} className='cards-col'>

                                                <div className='company-inner-card'>

                                                    <div className='comp-center-details'>

                                                        <div className='card-featu'>Featured</div>

                                                        <div className='details-sec'>

                                                            <img src={candiImg} alt="Company Logo" />
                                                            <Link to={'/candidates/Single'} className='candidate-name'>Robert Decoster</Link>
                                                            <span className='candi-prof'>UI Designer</span>
                                                            <div className='candi-flex space-x-7'>

                                                                <div className='candi-flex-tags space-x-2'>
                                                                    <CiLocationOn className='candi-card-i' />
                                                                    <span className='candi-details'>London, UK</span>
                                                                </div>
                                                                <div className='candi-flex-tags space-x-2'>
                                                                    <LiaMoneyBillWaveSolid className='candi-card-i' />
                                                                    <span className='candi-details'>$99 / hour</span>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <ul className='candidates-tags'>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>App</Link></li>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>Design</Link></li>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>Digital</Link></li>
                                                    </ul>

                                                    <button className='v-pro-btn' onClick={viewProfile}>View Profile</button>

                                                </div>

                                            </Col>

                                            <Col lg={6} md={6} sm={12} className='cards-col'>

                                                <div className='company-inner-card'>

                                                    <div className='comp-center-details'>

                                                        <div className='card-featu'>Featured</div>

                                                        <div className='details-sec'>

                                                            <img src={candiImg} alt="Company Logo" />
                                                            <Link to={'/candidates/Single'} className='candidate-name'>Robert Decoster</Link>
                                                            <span className='candi-prof'>UI Designer</span>
                                                            <div className='candi-flex space-x-7'>

                                                                <div className='candi-flex-tags space-x-2'>
                                                                    <CiLocationOn className='candi-card-i' />
                                                                    <span className='candi-details'>London, UK</span>
                                                                </div>
                                                                <div className='candi-flex-tags space-x-2'>
                                                                    <LiaMoneyBillWaveSolid className='candi-card-i' />
                                                                    <span className='candi-details'>$99 / hour</span>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <ul className='candidates-tags'>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>App</Link></li>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>Design</Link></li>
                                                        <li className='candi-tag'><Link to={'/'} className='candi-link'>Digital</Link></li>
                                                    </ul>

                                                    <button className='v-pro-btn' onClick={viewProfile}>View Profile</button>

                                                </div>

                                            </Col>

                                        </Row>

                                    </section>

                                </div>

                            </div>
                            {/* ----------- Cards Pagination ------------ */}
                            <Stack spacing={2} className='pagi-center'>
                                <Pagination
                                    sx={{
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
                                            fontSize: '14px',
                                            padding: '25px 20px',
                                            borderRadius: '200px',
                                        },
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover': {
                                            backgroundColor: 'rgb(0, 85, 182)',
                                            color: 'white',
                                            transition: '0.3s ease-in-out'
                                        },
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
                                            backgroundColor: 'rgb(0, 85, 182)',
                                            color: 'white',
                                        },
                                    }}
                                    count={3}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Stack>

                        </Col>

                    </Row>

                </Container>
            </section>

            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}
        </div>
    )
}

export default Candidates;