import React from 'react';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';

const HelpApplyJobs = () => {
    return (
        <div>
            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Content body start -------------- */}
            <div className="content-body">

                {/* ----------------- Terms heading -------------- */}
                <div className='faq-sec-heading'>
                    <h1>Apply Jobs</h1>
                    <p>Help & Support / Apply Jobs</p>
                </div>

                {/* ----------------- Terms points -------------- */}
                <div className='auto-container'>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>1. Job Application Process Overview</h2>
                        <p className='about-txt'>
                            Provide a clear step-by-step guide on how users can apply for jobs through your platform. Include details
                            such as creating an account, uploading a resume, and submitting applications.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>2. Profile Setup Assistance</h2>
                        <p className='about-txt'>
                            Offer guidance on how users can create and optimize their profiles for job applications. This might include tips on
                            filling out personal information, adding work experience, and highlighting skills.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>3. Resume Formatting Tips</h2>
                        <p className='about-txt'>
                            Offer suggestions on how to format resumes effectively for online submissions. Provide guidance on file types, size
                            limits, and any specific formatting requirements your platform might have.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>4. Job Search Tips</h2>
                        <p className='about-txt'>
                            Offer advice on how users can effectively search for jobs within your platform. This might include using filters,
                            keywords, and sorting options to narrow down search results.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>5. Application Status Tracking</h2>
                        <p className='about-txt'>
                            Explain how users can track the status of their job applications within your platform. Provide information on where they
                            can find updates, such as through email notifications or their user dashboard.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>6. Troubleshooting Common Issues</h2>
                        <p className='about-txt'>
                            Anticipate and address common problems users might encounter during the job application process. Provide troubleshooting
                            tips and links to additional resources or support channels for further assistance.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>7. Privacy and Security</h2>
                        <p className='about-txt'>
                            Reassure users about the privacy and security measures in place to protect their personal information during the job
                            application process. Explain how their data is handled and stored securely.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>8. Contact Information</h2>
                        <p className='about-txt'>
                            Provide clear contact information for users to reach out for help or support related to job applications.
                            This might include an email address, phone number, or live chat support option.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>9. Frequently Asked Questions (FAQs)</h2>
                        <p className='about-txt'>
                            Compile a list of frequently asked questions related to applying for jobs through your platform. Cover topics
                            such as account setup, resume submission, and application status inquiries.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>10. Feedback Mechanism</h2>
                        <p className='about-txt'>
                            Encourage users to provide feedback on their experience with the job application process. Provide a mechanism for
                            users to submit feedback, whether through a form, survey, or direct contact method.
                        </p>
                    </section>

                </div>

            </div>
            {/* *************** Content body end ***************** */}


            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}

        </div>
    )
}

export default HelpApplyJobs