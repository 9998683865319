import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import EmpDashHeader from '../Components/EmpDashHeader';
import EmpDashSideBar from '../Components/EmpDashSideBar';
import { Container, Row, Col } from 'react-bootstrap';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SlHome } from "react-icons/sl";
import { LuUser2 } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineBell } from "react-icons/ai";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import logo from '../Assets/Images/logo.png';

import { DashCopyRightFooter } from '../Components/Footer';

const ChangePassword = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <EmpDashHeader />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>

                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <EmpDashSideBar />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>Change Password!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/employer/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/company/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> Company Profile </span></NavLink></li>
                                        <li><NavLink to={'/post/job'} className='dashSideLink space-x-5'><VscSend className='dash-link-icon-d' /> <span className='dash-link-txt'> Post A New Job </span></NavLink></li>
                                        <li><NavLink to={'/manage/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Manage Jobs </span></NavLink></li>
                                        <li><NavLink to={'/all/applicants'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> All Applicants </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/resumes'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Resumes </span></NavLink></li>
                                        <li><NavLink to={'/packages'} className='dashSideLink space-x-5'><PiPackage className='dash-link-icon-d' /> <span className='dash-link-txt'> Packages </span></NavLink></li>
                                        <li><NavLink to={'/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/resume/alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Resume Alerts </span></NavLink></li>
                                        <li><NavLink to={'/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        <div className="dash-manage-job-sec">

                            <div className="dash-manage-top">
                                <h5 className='empdash-shortlist-heading'>Change Password</h5>
                            </div>

                            <form>
                                <Row>
                                    <Col lg={7} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="userSubject" className='dash-pass-label' style={{ fontSize: '16px' }}>Old password</label>
                                        <input type="password" name="" id="userSubject" required className='b-dtls-name' />
                                    </Col>
                                    <Col lg={7} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="userSubject" className='dash-pass-label' style={{ fontSize: '16px' }}>New password</label>
                                        <input type="password" name="" id="userSubject" required className='b-dtls-name' />
                                    </Col>
                                    <Col lg={7} md={12} sm={12} className="b-thoughts">
                                        <label htmlFor="userSubject" className='dash-pass-label' style={{ fontSize: '16px' }}>Confirm password</label>
                                        <input type="password" name="" id="userSubject" required className='b-dtls-name' />
                                    </Col>
                                    <Col lg={12} md={12} sm={12} className="b-thoughts">
                                        <button type='submit' className='b-dtls-send-msg dash-save-btn'>Update</button>
                                    </Col>
                                </Row>
                            </form>

                        </div>

                    </div>

                    <DashCopyRightFooter />

                </Col>

            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default ChangePassword