import React from 'react';
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { ImGoogle } from "react-icons/im";
import { IoIosArrowBack, IoIosArrowForward, IoMdCheckmarkCircle } from "react-icons/io";
import { Link } from 'react-router-dom';
import bgImg from '../Assets/Images/bg-img.jpg';
import commentUser from '../Assets/Images/candidate-1.png';
import userImg from '../Assets/Images/userImg.jpg';
import profileImg from '../Assets/Images/userProfileImg.png';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';

import { Col, Row } from 'react-bootstrap';

const BlogDetails = () => {
    return (
        <div>
            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Blog Details start -------------- */}

            <div className="blog-details-sec">

                <div className="auto-container">
                    <div className="b-dtls-heading">
                        <h3>Attract Sales And Profits toward the sunshine - and shadows will fall behind you.</h3>
                        <ul>
                            <li className='b-dtls-userinfo'> <img src={profileImg} alt="Profile image" /> <span className="b-dtls-userName"> Alison Dawn </span> </li>
                            <li className='b-dtls-userDate'>August 31, 2021</li>
                            <li className='b-dtls-userDate'>12 Comment</li>
                        </ul>
                    </div>
                </div>
                <figure className='b-dtls-userImg'>
                    <img src={userImg} alt="User Image" />
                </figure>

                <div className="auto-container">

                    {/* Discriptions start */}
                    <section className='b-dtls-disc-sec'>

                        <h5>Course Description</h5>
                        <p>
                            Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis,
                            at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a
                            text link libero tempus congue.
                        </p>
                        <p>
                            Duis mattis laoreet neque, et ornare neque sollicitudin at. Proin sagittis dolor sed mi elementum pretium.
                            Donec et justo ante. Vivamus egestas sodales est, eu rhoncus urna semper eu. Cum sociis natoque penatibus
                            et magnis dis parturient montes, nascetur ridiculus mus. Integer tristique elit lobortis purus bibendum,
                            quis dictum metus mattis. Phasellus posuere felis sed eros porttitor mattis. Curabitur massa magna, tempor
                            in blandit id, porta in ligula. Aliquam laoreet nisl massa, at interdum mauris sollicitudin et.
                        </p>
                        <section className='disc-b-quote'>
                            <blockquote>
                                Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis,
                                at malesuada orci congue.
                            </blockquote>
                            <span className='disc-b-quote-author'>Luis Pickford</span>
                        </section>

                    </section>

                    {/* What you'll learn start */}
                    <section className="b-dtls-learn">
                        <h5>What you'll learn</h5>
                        <ul className="b-dtls-learn-ul">
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                            <li className="b-dtls-learn-list space-x-3">
                                <IoMdCheckmarkCircle className='b-dtls-check-i' />
                                <span className='learn-li-txt'>Become a UI/UX designer.</span>
                            </li>
                        </ul>
                    </section>

                    {/* bg-image start */}
                    <figure className='b-dtls-bg-img'>
                        <img src={bgImg} alt="bg-image" />
                    </figure>

                    {/* Requirments start */}
                    <section className='b-dtls-req-sec'>

                        <h5>Requirements</h5>
                        <ul className='b-dtls-req'>
                            <li className="btls-req-li">
                                We do not require any previous experience or pre-defined skills to take this course.
                                A great orientation would be enough to master UI/UX design.
                            </li>
                            <li className="btls-req-li">
                                We do not require any previous.
                            </li>
                            <li className="btls-req-li">
                                We do not require any previous experience.
                            </li>
                        </ul>

                    </section>

                    {/* Social buttons start */}
                    <section className="b-dtls-social-sec">

                        <div className='b-dtls-share-this-job'>
                            <span className='single-title'>Share this job</span>
                            <div className='share-btns'>
                                <button className='share-btn-f'> <FaFacebookF /> <span> Facebook </span></button>
                                <button className='share-btn-t'> <FaTwitter /> <span> Twiter </span></button>
                                <button className='share-btn-g'> <ImGoogle /> <span> Google+ </span></button>
                            </div>
                        </div>
                        <ul className="b-dtls-tags-sec">
                            <li className="b-dtls-tag">App</li>
                            <li className="b-dtls-tag">Design</li>
                            <li className="b-dtls-tag">Digital</li>
                        </ul>

                    </section>

                    {/* Move buttons start */}
                    <section className='b-dtls-move'>
                        <div className='b-dtls-prev'>
                            <div className='prev-b-dtls'>
                                <IoIosArrowBack className='b-dtls-prev-i' />
                                <span className='prev-b-dtls-txt'>Previous Post</span>
                            </div>
                            <Link to={'/'} className='b-dtls-prev-link'>Given Set was without from god divide rule Hath</Link>
                        </div>
                        <div className='b-dtls-next'>
                            <div className='next-b-dtls'>
                                <span className='next-b-dtls-txt'>Next Post</span>
                                <IoIosArrowForward className='b-dtls-next-i' />
                            </div>
                            <Link to={'/'} className='b-dtls-next-link'>Tree earth fowl given moveth deep lesser After</Link>
                        </div>
                    </section>

                    {/* Comments start */}
                    <section className="b-dtls-com-sec">

                        <h5>Comment</h5>

                        <div className="b-dtls-com">
                            <div className="com-user-img">
                                <img src={commentUser} alt="User image" />
                            </div>
                            <div className='b-dtls-com-user'>
                                <h6>Oscar Cafeo</h6><br />
                                <span className='comment-title'>Beautiful courses</span>
                                <p className='b-dtls-com-p'>
                                    Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                                    vehement tantaneously eel valiantly petted this along across highhandedly much.
                                </p>
                            </div>
                        </div>

                        <div className="b-dtls-com">
                            <div className="com-user-img">
                                <img src={commentUser} alt="User image" />
                            </div>
                            <div className='b-dtls-com-user'>
                                <h6>Oscar Cafeo</h6><br />
                                <span className='comment-title'>Beautiful courses</span>
                                <p className='b-dtls-com-p'>
                                    Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                                    vehement tantaneously eel valiantly petted this along across highhandedly much.
                                </p>
                            </div>
                        </div>

                    </section>

                    {/* Suggestions start */}
                    <section className="b-dtls-sugg-sec">

                        <h5>Leave your thought here</h5>

                        <form>
                            <Row>
                                <Col lg={6} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userName">Your Name</label>
                                    <input type="text" name="" id="userName" placeholder='Your Name*' className='b-dtls-name' />
                                </Col>
                                <Col lg={6} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userEmail">Your Email</label>
                                    <input type="email" name="userEmail" id="" placeholder='Your Email*' className='b-dtls-name' />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userSubject">Subject</label>
                                    <input type="text" name="" id="userSubject" placeholder='Subject*' className='b-dtls-name' />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userMsg">Your Message</label>
                                    <textarea type="text" id='userMsg' className='b-dtls-message' placeholder='Write your message...' required />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <button type='submit' className='b-dtls-send-msg'>Send Message</button>
                                </Col>
                            </Row>
                        </form>

                    </section>


                </div>

            </div>

            {/* *************** Blog Details end ***************** */}

            {/* ----------------- Footer start -------------- */}
            <section className='b-dtls-footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}

        </div>
    )
}

export default BlogDetails