import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import EmpDashHeader from '../Components/EmpDashHeader';
import EmpDashSideBar from '../Components/EmpDashSideBar';
import { Container, Row, Col } from 'react-bootstrap';
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SlHome } from "react-icons/sl";
import { LuUser2 } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineBell } from "react-icons/ai";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import Form from 'react-bootstrap/Form';
import companyLogo from '../Assets/Images/feactureImg.png';
import { PiShoppingBagOpen } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import { GoEye } from "react-icons/go";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CandiDashSideMenu from '../Components/CandiDashSideMenu';
import logo from '../Assets/Images/logo.png';
import { DashCopyRightFooter } from '../Components/Footer';

const AppliedJobs = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <EmpDashHeader />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>
                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <CandiDashSideMenu />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>Applied jobs!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/candidate/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/my/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> My Profile </span></NavLink></li>
                                        <li><NavLink to={'/my/resume'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> My Resume </span></NavLink></li>
                                        <li><NavLink to={'/job/recommendation'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Recommendation </span></NavLink></li>
                                        <li><NavLink to={'/applied/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Applied Jobs </span></NavLink></li>
                                        <li><NavLink to={'/Job/Alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Alerts </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/jobs'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Jobs </span></NavLink></li>
                                        <li><NavLink to={'/cv/manager'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> CV Manager </span></NavLink></li>
                                        <li><NavLink to={'/candidate/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        <div className="dash-manage-job-sec">

                            <div className="dash-manage-top">
                                <h5 className='empdash-shortlist-heading'>My Applied Jobs</h5>
                                <Form.Select aria-label="Default select example" className='select-filter'>
                                    <option>Last 6 months</option>
                                    <option>Last 6 months</option>
                                    <option>Last 6 months</option>
                                    <option>Last 6 months</option>
                                    <option>Last 6 months</option>
                                </Form.Select>
                            </div>

                            {/* Job listing table */}
                            <div className="dash-j-list-sec">

                                <table className='dash-manage-table'>

                                    <thead className='dash-manage-th-sec'>
                                        <tr>
                                            <th className="dash-manage-th">Job Title</th>
                                            <th className="dash-manage-th">Date Applied</th>
                                            <th className="dash-manage-th">Status</th>
                                            <th className="dash-manage-th">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr className='dash-manage-tr'>
                                            <td>
                                                <div className='card-content dash-manage-card'>

                                                    <span className='comp-logo'>
                                                        <img src={companyLogo} alt="company logo" />
                                                    </span>
                                                    <h4 className='dash-card-h-link'>
                                                        <Link to={'/'} className='card-title dash-card-link'>
                                                            Software Engineer (Android), Libraries
                                                        </Link>
                                                    </h4>

                                                    <ul className='job-info'>
                                                        <li className="info-list">
                                                            <PiShoppingBagOpen className='i-display' />
                                                            <span>Segment</span>
                                                        </li>
                                                        <li className="job-info-list">
                                                            <IoLocationOutline className='i-display' />
                                                            <span>London, UK</span>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </td>
                                            <td>
                                                Dec 5, 2020
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                            <td>
                                                <div className="dash-manage-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication!</Tooltip>}>
                                                        <button className='dash-manage-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">delete Aplication!</Tooltip>}>
                                                        <button className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </td>
                                        </tr>

                                        <tr className='dash-manage-tr'>
                                            <td>
                                                <div className='card-content dash-manage-card'>

                                                    <span className='comp-logo'>
                                                        <img src={companyLogo} alt="company logo" />
                                                    </span>
                                                    <h4 className='dash-card-h-link'>
                                                        <Link to={'/'} className='card-title dash-card-link'>
                                                            Software Engineer (Android), Libraries
                                                        </Link>
                                                    </h4>

                                                    <ul className='job-info'>
                                                        <li className="info-list">
                                                            <PiShoppingBagOpen className='i-display' />
                                                            <span>Segment</span>
                                                        </li>
                                                        <li className="job-info-list">
                                                            <IoLocationOutline className='i-display' />
                                                            <span>London, UK</span>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </td>
                                            <td>
                                                Dec 5, 2020
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                            <td>
                                                <div className="dash-manage-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication!</Tooltip>}>
                                                        <button className='dash-manage-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">delete Aplication!</Tooltip>}>
                                                        <button className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </td>
                                        </tr>

                                        <tr className='dash-manage-tr'>
                                            <td>
                                                <div className='card-content dash-manage-card'>

                                                    <span className='comp-logo'>
                                                        <img src={companyLogo} alt="company logo" />
                                                    </span>
                                                    <h4 className='dash-card-h-link'>
                                                        <Link to={'/'} className='card-title dash-card-link'>
                                                            Software Engineer (Android), Libraries
                                                        </Link>
                                                    </h4>

                                                    <ul className='job-info'>
                                                        <li className="info-list">
                                                            <PiShoppingBagOpen className='i-display' />
                                                            <span>Segment</span>
                                                        </li>
                                                        <li className="job-info-list">
                                                            <IoLocationOutline className='i-display' />
                                                            <span>London, UK</span>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </td>
                                            <td>
                                                Dec 5, 2020
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                            <td>
                                                <div className="dash-manage-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication!</Tooltip>}>
                                                        <button className='dash-manage-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">delete Aplication!</Tooltip>}>
                                                        <button className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </td>
                                        </tr>

                                        <tr className='dash-manage-tr'>
                                            <td>
                                                <div className='card-content dash-manage-card'>

                                                    <span className='comp-logo'>
                                                        <img src={companyLogo} alt="company logo" />
                                                    </span>
                                                    <h4 className='dash-card-h-link'>
                                                        <Link to={'/'} className='card-title dash-card-link'>
                                                            Software Engineer (Android), Libraries
                                                        </Link>
                                                    </h4>

                                                    <ul className='job-info'>
                                                        <li className="info-list">
                                                            <PiShoppingBagOpen className='i-display' />
                                                            <span>Segment</span>
                                                        </li>
                                                        <li className="job-info-list">
                                                            <IoLocationOutline className='i-display' />
                                                            <span>London, UK</span>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </td>
                                            <td>
                                                Dec 5, 2020
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                            <td>
                                                <div className="dash-manage-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication!</Tooltip>}>
                                                        <button className='dash-manage-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">delete Aplication!</Tooltip>}>
                                                        <button className='dash-manage-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </td>
                                        </tr>

                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>

                    <DashCopyRightFooter />

                </Col>
            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default AppliedJobs;