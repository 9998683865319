const handleZoom =()=>{
    let elem = document.getElementById("plusIcon");
    elem.style.visibility= "visible";
  }
  const handleZoomOut =()=>{
    let elem = document.getElementById("plusIcon");
    elem.style.visibility= "hidden";
  }
  const handleZoom1 =()=>{
    let elem = document.getElementById("plusIcon1");
    elem.style.visibility= "visible";
  }
  const handleZoomOut1 =()=>{
    let elem = document.getElementById("plusIcon1");
    elem.style.visibility= "hidden";
  }
  const handleZoom2 =()=>{
    let elem = document.getElementById("plusIcon2");
    elem.style.visibility= "visible";
  }
  const handleZoomOut2 =()=>{
    let elem = document.getElementById("plusIcon2");
    elem.style.visibility= "hidden";
  }
  const handleZoom3 =()=>{
    let elem = document.getElementById("plusIcon3");
    elem.style.visibility= "visible";
  }
  const handleZoomOut3 =()=>{
    let elem = document.getElementById("plusIcon3");
    elem.style.visibility= "hidden";
  }

export {handleZoom, 
        handleZoomOut, 
        handleZoom1, 
        handleZoomOut1, 
        handleZoom2, 
        handleZoomOut2, 
        handleZoom3, 
        handleZoomOut3
}