import React from 'react';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';

const Terms = () => {
    return (
        <div>
            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Content body start -------------- */}
            <div className="content-body">

                {/* ----------------- Terms heading -------------- */}
                <div className='faq-sec-heading'>
                    <h1>Terms and Conditions</h1>
                    <p>Home / Terms and Conditions</p>
                </div>

                {/* ----------------- Terms points -------------- */}
                <div className='auto-container'>

                    <section className='about-txt-sec space-y-8' style={{marginBottom:35}}>
                        <h2 className='about-title' style={{fontSize:28}}>1. Terms</h2>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            dalmatian a glowered inset one echidna cassowary some parrot and much as goodness some froze the sullen much
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            vehement irresistibly fussy penguin insect additionally wow absolutely crud meretriciously hastily
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                    </section>
                    
                    <section className='space-y-8' style={{marginBottom:35}}>
                        <h2 className='about-title' style={{fontSize:28}}>2. Limitations</h2>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            dalmatian a glowered inset one echidna cassowary some parrot and much as goodness some froze the sullen much
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            vehement irresistibly fussy penguin insect additionally wow absolutely crud meretriciously hastily
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                    </section>

                    <section className=' space-y-8' style={{marginBottom:35}}>
                        <h2 className='about-title' style={{fontSize:28}}>3. Revisions and Errata</h2>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            dalmatian a glowered inset one echidna cassowary some parrot and much as goodness some froze the sullen much
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            vehement irresistibly fussy penguin insect additionally wow absolutely crud meretriciously hastily
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                    </section>

                    <section className='space-y-8' style={{marginBottom:35}}>
                        <h2 className='about-title' style={{fontSize:28}}>4. Site Terms of Use Modifications</h2>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            dalmatian a glowered inset one echidna cassowary some parrot and much as goodness some froze the sullen much
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            vehement irresistibly fussy penguin insect additionally wow absolutely crud meretriciously hastily
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                    </section>

                </div>

            </div>
            {/* *************** Content body end ***************** */}


            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec' style={{top:145}}>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}

        </div>
    )
}

export default Terms