import React, { useState } from 'react'
import {Link } from 'react-router-dom';
import logo from '../Assets/Images/logo white.png';
import { Container, Row, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

export const FooterSection = _ => {

    let [emailSubscribe, setEmailSubscribe] = useState("");
  
  
    return (
  
      <footer className='footer-row'>
  
        <Container><br /><br />
  
          <Row>
  
            <Col lg={4} md={6} sm={12} className='footer-col-padd'>
  
              <div className="foot-sec" style={{ padding: 0, }}>
  
                <Link to={'/'} className='footer-logo-link'>
                  <img src={logo} alt="Logo" className='footer_logo' />
                </Link><br />
                <span className='footer-h' style={{marginTop:0}}>
                  Call us
                </span>
                <p className='footer-h'>123 456 7890</p>
                <span className='footer-txt'>329 Queensberry Street, North Melbourne VIC</span>
                <span className='footer-txt'>3051, Australia.</span>
                <span className='footer-txt'>support@superio.com</span>
  
              </div>
  
            </Col>
            <Col lg={2} md={6} sm={12} className='footer-col-padd'>
  
              <div className="foot-sec">
                <p className='footer-h'>Find Jobs</p>
                <Link to={'/'} className='footer-link mt-2 mb-2 footer-txt'>Us jobs</Link>
                <Link to={'/aboutus'} className='footer-link mb-2 footer-txt'>Canada jobs us</Link>
                <Link to={'/'} className='footer-link mb-2 footer-txt'>UK jobs</Link>
                <Link to={'/contactus'} className='footer-link mb-2 footer-txt'>Emplois en france</Link>
                <Link to={'/'} className='footer-link mb-2 footer-txt'>UK jobs</Link>
                <Link to={'/contactus'} className='footer-link mb-2 footer-txt'>Emplois en US</Link>
              </div>
  
            </Col>
            <Col lg={3} md={6} sm={12} className='footer-col-padd'>
  
              <div className="foot-sec" >
                <p className='footer-h'>Frequently Asked Questions</p>
                <div className='sep-sections'>
  
                  <div className='foot-sec'>
                    <Link to={'/'} className='footer-link mt-2 mb-2 footer-txt'>Us jobs</Link>
                    <Link to={'/aboutus'} className='footer-link mb-2 footer-txt'>Canada jobs us</Link>
                    <Link to={'/'} className='footer-link mb-2 footer-txt'>UK jobs</Link>
                    <Link to={'/contactus'} className='footer-link mb-2 footer-txt'>Emplois en france</Link>
                    <Link to={'/'} className='footer-link mb-2 footer-txt'>UK jobs</Link>
                    <Link to={'/contactus'} className='footer-link mb-2 footer-txt'>Emplois en US</Link>
                  </div>
                  <div className='foot-sec' >
                    <Link to={'/'} className='footer-link mt-2 mb-2 footer-txt'>Us jobs</Link>
                    <Link to={'/aboutus'} className='footer-link mb-2 footer-txt'>Canada jobs us</Link>
                    <Link to={'/'} className='footer-link mb-2 footer-txt'>UK jobs</Link>
                    <Link to={'/contactus'} className='footer-link mb-2 footer-txt'>Emplois en france</Link>
                    <Link to={'/'} className='footer-link mb-2 footer-txt'>UK jobs</Link>
                    <Link to={'/contactus'} className='footer-link mb-2 footer-txt'>Emplois en US</Link>
                  </div>
  
                </div>
  
              </div>
  
            </Col>
            <Col lg={3} md={6} sm={12} className='f-last-col footer-col-padd'>
  
              <div className="foot-sec f-last-col" >
                <p className='footer-h'>Join Us On</p>
                <p className='footer-txt'>We don’t send spam so don’t worry.</p>
                <input
                  type="email"
                  className='foot-inp'
                  required
                  placeholder='Email'
                  style={{margin:0}}
                  id="email"
                  onChange={(e) => setEmailSubscribe(e.target.value)}
                />
              </div>
  
            </Col>
  
          </Row><br /><br /><br /><br />
  
        </Container>
  
      </footer>
  
    )
  
  }
  
  export const CopyRightFooter = _ => {
    return (
      <footer>
        <div className='footer-cright '> <br /> <br /><br />
          <p>
            <Container>
              Copyright © 2024 - All Right Reserved.
            </Container>
          </p>
        </div>
  
      </footer>
    )
  }

  export const DashCopyRightFooter = _ => {
    return (
      <footer>
        <div className='footer-cright-dash '> <br /> <br /><br />
          <p style={{fontSize:13}}>
            <Container>
              Copyright © 2024 - All Right Reserved.
            </Container>
          </p>
        </div>
  
      </footer>
    )
  }