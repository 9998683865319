import React from "react";
import { IoIosArrowRoundBack, IoIosInformationCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import { CopyRightFooter, FooterSection } from "../Components/Footer";
import NavBar from "../Components/Header";

const HelpContact = () => {
  return (
    <div>
      {/* ----------------- Header start -------------- */}
      <NavBar />
      {/* *************** Header end ***************** */}

      {/* ----------------- Faq sec heading start -------------- */}
      <div className="content-body">
        <div className="auto-container">
          <h2>User, we're here to help</h2>
          <p className="help-cont-p-text">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod
            eligendi eveniet dolor neque debitis veniam fugiat. Ducimus, ab
            fugit! Voluptate.
          </p>
          <div className="help-cont-caution">
            <IoIosInformationCircle className="help-cont-info-i" />

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
              corrupti voluptatem nemo perspiciatis totam, tempora consequuntur.
              Pariatur cum sequi temporibus?
            </p>
          </div>

          <div className="help-cont-form">
            <form action="">
              <label htmlFor="subject" className="help-cont-form-label">
                Subject
              </label>
              <input
                type="text"
                className="help-cont-subject-inp"
                id=""
                required
              />

              <label htmlFor="discription" className="help-cont-form-label">
                Description
              </label>
              <textarea id="" className="help-cont-discri-inp" required />

              {/* <input type="file" id="" className='help-cont-form-file-inp' /> */}
              <div className="">
                <label className="w-100 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-sm tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue mb-5">
                  <svg
                    className="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                  </svg>
                  <span className="mt-2 text-base leading-normal">
                    Select a file
                  </span>
                  <input type="file" className="hidden" />
                </label>
              </div>
              <div className="help-back-send-btns">
                <Link to={"/help&support"}>
                  <IoIosArrowRoundBack className="help-cont-back-i" />
                  <span className="help-cont-back-btn-txt">Back</span>
                </Link>
                <button type="submit" className="help-cont-send-req-btn">
                  Send Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* *************** Content body end ***************** */}

      {/* ----------------- Footer start -------------- */}
      <section className="footer-sec">
        <FooterSection />
        <CopyRightFooter />
      </section>
      {/* *************** Footer end ***************** */}
    </div>
  );
};

export default HelpContact;
