import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import EmpDashHeader from '../Components/EmpDashHeader';
import EmpDashSideBar from '../Components/EmpDashSideBar';
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import userImg from '../Assets/Images/candidate-1.png';
import { IoLocationOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { GoEye } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiDeleteOutline } from "react-icons/ti";
import { MdOutlineDone } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SlHome } from "react-icons/sl";
import { LuUser2 } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineBell } from "react-icons/ai";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import logo from '../Assets/Images/logo.png';
import CandiDashSideMenu from '../Components/CandiDashSideMenu';
import { DashCopyRightFooter } from '../Components/Footer';

const CandiAllNotifications = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const readUnreadNoti = (value) => {

        let readBtn = document.getElementById("readBtn");
        let unreadBtn = document.getElementById("unreadBtn");

        if (value == 'read') {
            readBtn.classList = "noti-s-read-btn";
            readBtn.remove.classList = "noti-read-btn";
            unreadBtn.classList = "noti-read-btn";
        }
        else if (value == "unread") {
            unreadBtn.classList = "noti-s-read-btn";
            unreadBtn.remove.classList = "noti-read-btn";
            readBtn.classList = "noti-read-btn";
        }
        else {
            readBtn.classList = "noti-s-read-btn";
            readBtn.remove.classList = "noti-read-btn";
            unreadBtn.classList = "noti-read-btn";
        }
    }

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <EmpDashHeader />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>
                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <CandiDashSideMenu />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>All Notifications!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/candidate/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/my/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> My Profile </span></NavLink></li>
                                        <li><NavLink to={'/my/resume'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> My Resume </span></NavLink></li>
                                        <li><NavLink to={'/applied/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Applied Jobs </span></NavLink></li>
                                        <li><NavLink to={'/Job/Alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Alerts </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/jobs'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Jobs </span></NavLink></li>
                                        <li><NavLink to={'/cv/manager'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> CV Manager </span></NavLink></li>
                                        <li><NavLink to={'/candidate/packages'} className='dashSideLink space-x-5'><PiPackage className='dash-link-icon-d' /> <span className='dash-link-txt'> Packages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        {/* All notification cards */}
                        <div className="dash-manage-job-sec">

                            <h5 className='empdash-shortlist-heading' style={{ marginBottom: 15 }}>Notifications</h5>

                            <div className="noti-read-unread">
                                <span className='noti-read-btn' id='readBtn' onClick={() => readUnreadNoti("read")}>Read</span>
                                <span className='noti-read-btn' id='unreadBtn' onClick={() => readUnreadNoti("unread")}>Unread</span>
                            </div>

                            <span className='noti-feed-heading'>New</span>

                            <Row>

                                <Col lg={12} md={12} sm={12}>

                                    <Link to={'/'} className='notif-page-link'>
                                        <div className='noti-feed-page-outer'>

                                            <span className='single-noti-page-img'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='single-noti-page-text-sec'>
                                                <p style={{ fontSize: 16 }}>
                                                    <span className='single-noti-page-txt-bold'> Darlene Robertson </span>
                                                    You are selected for gmg solution.
                                                </p>
                                            </div>

                                        </div>
                                    </Link>

                                </Col>

                                <Col lg={12} md={12} sm={12}>

                                    <Link to={'/'} className='notif-page-link'>
                                        <div className='noti-feed-page-outer'>

                                            <span className='single-noti-page-img'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='single-noti-page-text-sec'>
                                                <p style={{ fontSize: 16 }}>
                                                    <span className='single-noti-page-txt-bold'> Darlene Robertson </span>
                                                    You are selected for gmg solution.
                                                </p>
                                            </div>

                                        </div>
                                    </Link>

                                </Col>

                                <Col lg={12} md={12} sm={12}>

                                    <Link to={'/'} className='notif-page-link'>
                                        <div className='noti-feed-page-outer'>

                                            <span className='single-noti-page-img'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='single-noti-page-text-sec'>
                                                <p style={{ fontSize: 16 }}>
                                                    <span className='single-noti-page-txt-bold'> Darlene Robertson </span>
                                                    You are selected for gmg solution.
                                                </p>
                                            </div>

                                        </div>
                                    </Link>

                                </Col>

                                <Col lg={12} md={12} sm={12}>

                                    <Link to={'/'} className='notif-page-link'>
                                        <div className='noti-feed-page-outer'>

                                            <span className='single-noti-page-img'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='single-noti-page-text-sec'>
                                                <p style={{ fontSize: 16 }}>
                                                    <span className='single-noti-page-txt-bold'> Darlene Robertson </span>
                                                    You are selected for gmg solution.
                                                </p>
                                            </div>

                                        </div>
                                    </Link>

                                </Col>

                                <Col lg={12} md={12} sm={12}>

                                    <Link to={'/'} className='notif-page-link'>
                                        <div className='noti-feed-page-outer'>

                                            <span className='single-noti-page-img'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='single-noti-page-text-sec'>
                                                <p style={{ fontSize: 16 }}>
                                                    <span className='single-noti-page-txt-bold'> Darlene Robertson </span>
                                                    You are selected for gmg solution.
                                                </p>
                                            </div>

                                        </div>
                                    </Link>

                                </Col>

                                <Col lg={12} md={12} sm={12}>

                                    <Link to={'/'} className='notif-page-link'>
                                        <div className='noti-feed-page-outer'>

                                            <span className='single-noti-page-img'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='single-noti-page-text-sec'>
                                                <p style={{ fontSize: 16 }}>
                                                    <span className='single-noti-page-txt-bold'> Darlene Robertson </span>
                                                    You are selected for gmg solution.
                                                </p>
                                            </div>

                                        </div>
                                    </Link>

                                </Col>

                            </Row>

                        </div>


                    </div>

                    <DashCopyRightFooter />

                </Col>
            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default CandiAllNotifications