import React from "react";
import { AiOutlineBell } from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import { LuUser2 } from "react-icons/lu";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SlHome } from "react-icons/sl";
import { NavLink } from "react-router-dom";

const CandiDashSideMenu = () => {
  return (
    <div className="dashSideBar">
      <ul>
        <li>
          <NavLink
            to={"/candidate/dashboard"}
            className="dashSideLink space-x-5"
          >
            <SlHome className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/my/profile"} className="dashSideLink space-x-5">
            <LuUser2 className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> My Profile </span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to={"/my/resume"} className="dashSideLink space-x-5">
            <IoDocumentTextOutline className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> My Resume </span>
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to={"/job/recommendation"}
            className="dashSideLink space-x-5"
          >
            <PiShoppingBagOpenDuotone className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Job Recommendation </span>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/applied/jobs"} className="dashSideLink space-x-5">
            <PiShoppingBagOpenDuotone className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Applied Jobs </span>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/Job/Alerts"} className="dashSideLink space-x-5">
            <AiOutlineBell className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Job Alerts </span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to={"/shortlisted/jobs"} className="dashSideLink space-x-5">
            <FiBookmark className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Shortlisted Jobs </span>
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to={"/cv/manager"} className="dashSideLink space-x-5">
            <IoDocumentTextOutline className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> CV Manager </span>
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to={"/candidate/messages"}
            className="dashSideLink space-x-5"
          >
            <BiMessageDetail className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Messages </span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"/candidate/change/password"}
            className="dashSideLink space-x-5"
          >
            <GoLock className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Change Password </span>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/signin"} className="dashSideLink space-x-5">
            <IoMdLogOut className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Logout </span>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/register"} className="dashSideLink space-x-5">
            <RiDeleteBin6Line className="dash-link-icon-d" />{" "}
            <span className="dash-link-txt"> Delete Profile </span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default CandiDashSideMenu;
