import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import EmpDashHeader from '../Components/EmpDashHeader';
import EmpDashSideBar from '../Components/EmpDashSideBar';
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import userImg from '../Assets/Images/candidate-1.png';
import { IoLocationOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { GoEye } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiDeleteOutline } from "react-icons/ti";
import { MdOutlineDone } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SlHome } from "react-icons/sl";
import { LuUser2 } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineBell } from "react-icons/ai";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineBookmarkBorder } from "react-icons/md";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import logo from '../Assets/Images/logo.png';

import { DashCopyRightFooter } from '../Components/Footer';

const ShortlistedResumes = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <EmpDashHeader />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>
                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <EmpDashSideBar />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>Shortlisted Resumes!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/employer/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/company/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> Company Profile </span></NavLink></li>
                                        <li><NavLink to={'/post/job'} className='dashSideLink space-x-5'><VscSend className='dash-link-icon-d' /> <span className='dash-link-txt'> Post A New Job </span></NavLink></li>
                                        <li><NavLink to={'/manage/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Manage Jobs </span></NavLink></li>
                                        <li><NavLink to={'/all/applicants'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> All Applicants </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/resumes'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Resumes </span></NavLink></li>
                                        <li><NavLink to={'/packages'} className='dashSideLink space-x-5'><PiPackage className='dash-link-icon-d' /> <span className='dash-link-txt'> Packages </span></NavLink></li>
                                        <li><NavLink to={'/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/resume/alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Resume Alerts </span></NavLink></li>
                                        <li><NavLink to={'/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        {/* shortlist resumes */}
                        <div className="dash-manage-job-sec">

                            <div className="dash-manage-top">
                                <h5 className='empdash-shortlist-heading'>Shortlist Resumes</h5>
                                <div className="dashApp-drop-flow">

                                    <div className="shortlist-search-bar">
                                        <IoSearchOutline className='shortlist-search-icon' />
                                        <input
                                            type="text"
                                            placeholder='Search'
                                            className="shortlist-search-inp"
                                            style={{ width: '100%' }}
                                        // onChange={(e) => handleChange(e)}
                                        />
                                    </div>

                                    <Form.Select aria-label="Default select example" className='select-filter'>
                                        <option>Newest</option>
                                        <option>Last 6 months</option>
                                        <option>Last 6 months</option>
                                        <option>Last 6 months</option>
                                        <option>Last 6 months</option>
                                        <option>Last 6 months</option>
                                    </Form.Select>

                                </div>
                            </div>

                            <Row>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content dash-card-cont'>

                                            <span className='comp-logo dash-user-img-card'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='dash-card-partition'>
                                                <h4>
                                                    <Link to={'/'} className='dash-u-title card-title'>
                                                        Darlene Robertson
                                                    </Link>
                                                </h4>

                                                <ul className='job-info'>
                                                    <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                                                        <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <IoLocationOutline className='i-display' />
                                                        <span className='dash-u-info-txt'>London, UK</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <LiaMoneyBillWaveSolid className='i-display' />
                                                        <span className='dash-u-info-txt'>$44 / hour</span>
                                                    </li>
                                                </ul>

                                                <ul className='dash-card-tag-ul empdash-card-tags'>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                                                </ul>

                                                <div className="empdash-card-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><MdOutlineDone /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Favorite</Tooltip>}>
                                                        <button className='dash-applicant-btn'><MdOutlineBookmarkBorder /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </div>

                                        </div>

                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content dash-card-cont'>

                                            <span className='comp-logo dash-user-img-card'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='dash-card-partition'>
                                                <h4>
                                                    <Link to={'/'} className='dash-u-title card-title'>
                                                        Darlene Robertson
                                                    </Link>
                                                </h4>

                                                <ul className='job-info'>
                                                    <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                                                        <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <IoLocationOutline className='i-display' />
                                                        <span className='dash-u-info-txt'>London, UK</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <LiaMoneyBillWaveSolid className='i-display' />
                                                        <span className='dash-u-info-txt'>$44 / hour</span>
                                                    </li>
                                                </ul>

                                                <ul className='dash-card-tag-ul empdash-card-tags'>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                                                </ul>

                                                <div className="empdash-card-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><MdOutlineDone /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </div>

                                        </div>

                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content dash-card-cont'>

                                            <span className='comp-logo dash-user-img-card'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='dash-card-partition'>
                                                <h4>
                                                    <Link to={'/'} className='dash-u-title card-title'>
                                                        Darlene Robertson
                                                    </Link>
                                                </h4>

                                                <ul className='job-info'>
                                                    <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                                                        <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <IoLocationOutline className='i-display' />
                                                        <span className='dash-u-info-txt'>London, UK</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <LiaMoneyBillWaveSolid className='i-display' />
                                                        <span className='dash-u-info-txt'>$44 / hour</span>
                                                    </li>
                                                </ul>

                                                <ul className='dash-card-tag-ul empdash-card-tags'>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                                                </ul>

                                                <div className="empdash-card-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><MdOutlineDone /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </div>

                                        </div>

                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content dash-card-cont'>

                                            <span className='comp-logo dash-user-img-card'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='dash-card-partition'>
                                                <h4>
                                                    <Link to={'/'} className='dash-u-title card-title'>
                                                        Darlene Robertson
                                                    </Link>
                                                </h4>

                                                <ul className='job-info'>
                                                    <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                                                        <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <IoLocationOutline className='i-display' />
                                                        <span className='dash-u-info-txt'>London, UK</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <LiaMoneyBillWaveSolid className='i-display' />
                                                        <span className='dash-u-info-txt'>$44 / hour</span>
                                                    </li>
                                                </ul>

                                                <ul className='dash-card-tag-ul empdash-card-tags'>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                                                </ul>

                                                <div className="empdash-card-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><MdOutlineDone /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </div>

                                        </div>

                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content dash-card-cont'>

                                            <span className='comp-logo dash-user-img-card'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='dash-card-partition'>
                                                <h4>
                                                    <Link to={'/'} className='dash-u-title card-title'>
                                                        Darlene Robertson
                                                    </Link>
                                                </h4>

                                                <ul className='job-info'>
                                                    <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                                                        <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <IoLocationOutline className='i-display' />
                                                        <span className='dash-u-info-txt'>London, UK</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <LiaMoneyBillWaveSolid className='i-display' />
                                                        <span className='dash-u-info-txt'>$44 / hour</span>
                                                    </li>
                                                </ul>

                                                <ul className='dash-card-tag-ul empdash-card-tags'>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                                                </ul>

                                                <div className="empdash-card-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><MdOutlineDone /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </div>

                                        </div>

                                    </section>

                                </Col>

                                <Col lg={6} md={6} sm={12} className="dash-apply-card-sec">

                                    <section className='rel-card'>

                                        <div className='card-content dash-card-cont'>

                                            <span className='comp-logo dash-user-img-card'>
                                                <img src={userImg} alt="company logo" />
                                            </span>

                                            <div className='dash-card-partition'>
                                                <h4>
                                                    <Link to={'/'} className='dash-u-title card-title'>
                                                        Darlene Robertson
                                                    </Link>
                                                </h4>

                                                <ul className='job-info'>
                                                    <li className="dash-card-info-list" style={{ paddingLeft: 0 }}>
                                                        <span className='dash-u-info-txt dash-noti-blue-txt'>Ui designer</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <IoLocationOutline className='i-display' />
                                                        <span className='dash-u-info-txt'>London, UK</span>
                                                    </li>
                                                    <li className="dash-card-info-list">
                                                        <LiaMoneyBillWaveSolid className='i-display' />
                                                        <span className='dash-u-info-txt'>$44 / hour</span>
                                                    </li>
                                                </ul>

                                                <ul className='dash-card-tag-ul empdash-card-tags'>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>App</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Design</Link></li>
                                                    <li className='candi-tag'><Link to={'/'} className='dash-card-tag-txt candi-link'>Digital</Link></li>
                                                </ul>

                                                <div className="empdash-card-btn-sec">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><GoEye /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Aprove Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><MdOutlineDone /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><TiDeleteOutline /></button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Aplication</Tooltip>}>
                                                        <button className='dash-applicant-btn'><RiDeleteBin6Line /></button>
                                                    </OverlayTrigger>
                                                </div>

                                            </div>

                                        </div>

                                    </section>

                                </Col>

                            </Row>

                            {/* ----------- Cards Pagination ------------ */}
                            <Stack spacing={2} className='pagi-center' style={{ marginTop: '35px' }}>
                                <Pagination
                                    sx={{
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
                                            fontSize: '14px',
                                            padding: '25px 20px',
                                            borderRadius: '200px',
                                        },
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover': {
                                            backgroundColor: 'rgb(0, 85, 182)',
                                            color: 'white',
                                            transition: '0.3s ease-in-out'
                                        },
                                        '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
                                            backgroundColor: 'rgb(0, 85, 182)',
                                            color: 'white',
                                        },
                                    }}
                                    count={3}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Stack>

                        </div>

                    </div>

                    <DashCopyRightFooter />

                </Col>
            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default ShortlistedResumes