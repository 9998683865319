import React from 'react';
import {Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from '../Assets/Images/logo.png';

const PageNotFound = () => {
  
  let navigate = useNavigate();
  
  const handleMove =_=>{

    navigate('/')

  }

  return (
    <div className='not-found-bg'>

        <div className="n-f-body">

            <Link to={'/'}>
              <img src={Logo} alt="Logo" />
            </Link>            

            <h1>404!</h1>
            <p>The page you are looking for could not be found.</p>
            <button onClick={handleMove}>BACK TO HOME</button>

        </div>

    </div>
  )
}

export default PageNotFound;