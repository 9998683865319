import React from 'react';
import { BiMessageDetail } from "react-icons/bi";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import { LuUser2 } from "react-icons/lu";
import { PiPackage, PiShoppingBagOpenDuotone } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SlHome } from "react-icons/sl";
import { VscSend } from "react-icons/vsc";
import { NavLink } from 'react-router-dom';


const EmpDashSideBar = () => {
  return (
<div className='dashSideBar'>
      <ul>
        <li>
          <NavLink to='/employer/dashboard' className='dashSideLink space-x-5' activeClassName='active'>
            <SlHome className='dash-link-icon-d' /> 
            <span className='dash-link-txt'> Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/company/profile' className='dashSideLink space-x-5' activeClassName='active'>
            <LuUser2 className='dash-link-icon-d' />
            <span className='dash-link-txt'> Company Profile </span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/post/job' className='dashSideLink space-x-5' activeClassName='active'>
            <VscSend className='dash-link-icon-d' />
            <span className='dash-link-txt'> Post A New Job </span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/manage/jobs' className='dashSideLink space-x-5' activeClassName='active'>
            <PiShoppingBagOpenDuotone className='dash-link-icon-d' />
            <span className='dash-link-txt'> Manage Jobs </span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/all/applicants' className='dashSideLink space-x-5' activeClassName='active'>
            <IoDocumentTextOutline className='dash-link-icon-d' />
            <span className='dash-link-txt'> All Applicants </span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to='/shortlisted/resumes' className='dashSideLink space-x-5' activeClassName='active'>
            <FiBookmark className='dash-link-icon-d' />
            <span className='dash-link-txt'> Shortlisted Resumes </span>
          </NavLink>
        </li> */}
        <li>
          <NavLink to='/packages' className='dashSideLink space-x-5' activeClassName='active'>
            <PiPackage className='dash-link-icon-d' />
            <span className='dash-link-txt'> Packages </span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/messages' className='dashSideLink space-x-5' activeClassName='active'>
            <BiMessageDetail className='dash-link-icon-d' />
            <span className='dash-link-txt'> Messages </span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to='/resume/alerts' className='dashSideLink space-x-5' activeClassName='active'>
            <AiOutlineBell className='dash-link-icon-d' />
            <span className='dash-link-txt'> Resume Alerts </span>
          </NavLink>
        </li> */}
        <li>
          <NavLink to='/change/password' className='dashSideLink space-x-5' activeClassName='active'>
            <GoLock className='dash-link-icon-d' />
            <span className='dash-link-txt'> Change Password </span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/signin' className='dashSideLink space-x-5' activeClassName='active'>
            <IoMdLogOut className='dash-link-icon-d' />
            <span className='dash-link-txt'> Logout </span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/register' className='dashSideLink space-x-5' activeClassName='active'>
            <RiDeleteBin6Line className='dash-link-icon-d' />
            <span className='dash-link-txt'> Delete Profile </span>
          </NavLink>
        </li>
      </ul>
    </div>  )
}

export default EmpDashSideBar