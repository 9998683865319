import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { BiBookmark } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { IoLocation, IoSearchOutline } from "react-icons/io5";
import { PiShoppingBagOpen, PiSuitcaseSimpleThin } from "react-icons/pi";
import { Link } from 'react-router-dom';
import compLogoImg from '../Assets/Images/udemy.png';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';




const Employers = () => {

    const minDistance = 10;
    const [value1, setValue1] = useState([0, 100]);
    let [quantityJob, setQuantityJob] = useState("all");

    function valuetext(value) {
        return `${value}°C`;
    }
    const handleChange1 = (
        event,
        newValue,
        activeThumb,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
        } else {
            setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
        }
    };

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Choose a category");
    const [isRotated, setIsRotated] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setIsRotated(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setIsRotated(!isRotated);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setShowDropdown(false);
        setIsRotated(false);
    };


    // let inpBorderChg = _ => {
    //     let inpDiv = document.getElementById("emp-search-filter-inp");
    //     inpDiv.style.border = '1px solid rgb(0, 102, 235)';
    // }


    return (
        <div>
            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Employe banner start -------------- */}
            <section className='emp-banner'>

                <Container>

                    <div className='emp-banner-inner space-y-4'>
                        <h1 className='emp-page-title'>Employers</h1>
                        <p className='emp-banner-txt space-x-2'>
                            <Link to={'/'} className='emp-home-link'>Home</Link> /
                            <span className='company-title-txt'>Employers</span>
                        </p>
                    </div>

                </Container>

            </section>
            {/* *************** Employe banner end ***************** */}

            <section className="job-sec">

                <Container>

                    <Row>
                        {/* ---------- SIDE FILTER START -------- */}
                        <Col lg={4} className='space-y-10 side-hide'>
                            <div className='flex-filter'>

                                {/* ---------- Job type START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Search by keywords</span>
                                    <ul className="filter-list">

                                        <li className="job-list flex-start">
                                            <div className="emp-list-inp space-x-2" id="emp-search-filter-inp">
                                                <IoSearchOutline id="location-icon-emp-list" className='ml-5' />
                                                <input
                                                    type="text"
                                                    placeholder='Job title, keywords or company '
                                                    className="inp-txt ml-2 box-txt"
                                                    name="location"
                                                // onClick={inpBorderChg}
                                                // onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </li>

                                    </ul>
                                </section>
                                {/* ---------- Location Search START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Location</span>
                                    <ul className="filter-list">

                                        <li className="job-list flex-start">
                                            <div className="emp-list-inp space-x-2">
                                                <CiLocationOn id="location-icon-emp-list" className='ml-5' />
                                                <input
                                                    type="text"
                                                    placeholder='City or postcode'
                                                    className="inp-txt ml-2 box-txt"
                                                    name="location"
                                                // onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            {/* <span>Freelancer</span> */}
                                        </li>

                                    </ul>
                                    <p className='radius-txt'>Radius around selected destination</p>
                                    <Box sx={{ width: '100%' }}>
                                        <Slider
                                            getAriaLabel={() => 'Minimum distance'}
                                            value={value1}
                                            onChange={handleChange1}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            disableSwap
                                        />
                                    </Box>

                                </section>
                                {/* ---------- Category START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles' style={{lineHeight:1}}>Category</span>

                                    <div className={`select-menu emp-select-ctg-filter ${showDropdown ? 'active' : ''}`} ref={dropdownRef} style={{marginTop:10}}>

                                        <div className="emp-list-select-btn" onClick={toggleDropdown}>
                                            <div className="f-j-drop-down-name-i">
                                                <PiSuitcaseSimpleThin id="location-icon-emp-list" />
                                                <span className="f-j-sBtn-txt">{selectedOption}</span>
                                            </div>
                                            <IoIosArrowDown className={`a-icon ${isRotated ? 'rotated' : ''}`} />
                                        </div>

                                        {showDropdown && (
                                            <ul className="options">

                                                <li className="option" onClick={() => handleOptionClick("Choose a category")}>
                                                    <span className={`option-txt ${selectedOption === "Choose a category" ? 'selected' : ''}`}>Choose a category</span>
                                                </li>
                                                <li className="option" onClick={() => handleOptionClick("Movie & Film Name")}>
                                                    <span className={`option-txt ${selectedOption === "Movie & Film Name" ? 'selected' : ''}`}>Movie & Film Name</span>
                                                </li>
                                                <li className="option" onClick={() => handleOptionClick("Graphic Design")}>
                                                    <span className={`option-txt ${selectedOption === "Graphic Design" ? 'selected' : ''}`}>Graphic Design</span>
                                                </li>
                                                <li className="option" onClick={() => handleOptionClick("Option 3")}>
                                                    <span className={`option-txt ${selectedOption === "Option 3" ? 'selected' : ''}`}>Option 3</span>
                                                </li>

                                            </ul>
                                        )}
                                    </div>




                                </section>
                                {/* ---------- Salary SECTION START -------- */}
                                <section className='range'>
                                    <span className='f-titles'>Foundation date</span>
                                    <Box sx={{ width: '100%' }}>
                                        <Slider
                                            getAriaLabel={() => 'Minimum distance'}
                                            value={value1}
                                            onChange={handleChange1}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            disableSwap
                                        />
                                    </Box>

                                </section>

                            </div>

                            {/* ************* FILTER SIDEBAR END **************** */}
                        </Col>

                        {/* ---------- JOB TABS START -------- */}
                        <Col lg={8} md={12} sm={12} className=''>
                            <div className='job-tabs'>

                                <div className='job-filter'>

                                    <div className='job-filter'>

                                        <Form.Select aria-label="Default select example" className='select-filter'>
                                            <option>Sort by (default)</option>
                                            <option value="new">Newest</option>
                                            <option value="old">Oldest</option>
                                        </Form.Select>

                                        <Form.Select aria-label="Default select example" className='select-filter' onChange={(e) => setQuantityJob(e.target.value)}>
                                            <option value="all">All</option>
                                            <option value="15">15 per page</option>
                                            <option value="20">20 per pageo</option>
                                            <option value="30">30 per page</option>
                                        </Form.Select>

                                    </div>

                                </div>

                                <div className='jobs-q'>
                                    <span>Show <span className='qty-txt'>{quantityJob}</span> jobs</span>
                                </div>

                                <div className='job-tabs-list space-y-8'>
                                    {/* ----------- COMPANY CARD START ------------ */}
                                    <section className='comp-card-sec'>

                                        <Row className='comp-card-row'>

                                            <Col lg={4} md={6} sm={12} className='cards-col'>

                                                <div className='company-inner-card'>

                                                    <div className='company-bm-btn'><BiBookmark /></div>

                                                    <div className='comp-center-details'>

                                                        <div className='card-featu'>Feature</div>

                                                        <div className='details-sec'>

                                                            <img src={compLogoImg} alt="Company Logo" />
                                                            <Link to={'/employers/single'} className='comp-title'>Udemy</Link>
                                                            <div className='detail-txt space-x-4'>
                                                                <IoLocation className='comp-card-i' />
                                                                <span className='comp-details'>London, UK</span>
                                                            </div>
                                                            <div className='detail-txt space-x-4'>
                                                                <PiShoppingBagOpen className='comp-card-i' />
                                                                <span className='comp-details'>Accounting / Finance</span>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className='open-jobs'>
                                                        <span className='comp-details'> Open Jobs – 21 </span>
                                                    </div>

                                                </div>

                                            </Col>

                                            <Col lg={4} md={6} sm={12} className='cards-col'>

                                                <div className='company-inner-card'>

                                                    <div className='company-bm-btn'><BiBookmark /></div>

                                                    <div className='comp-center-details'>

                                                        <div className='card-featu'>Feature</div>

                                                        <div className='details-sec'>

                                                            <img src={compLogoImg} alt="Company Logo" />
                                                            <Link to={'/employers/single'} className='comp-title'>Udemy</Link>
                                                            <div className='detail-txt space-x-4'>
                                                                <IoLocation className='comp-card-i' />
                                                                <span className='comp-details'>London, UK</span>
                                                            </div>
                                                            <div className='detail-txt space-x-4'>
                                                                <PiShoppingBagOpen className='comp-card-i' />
                                                                <span className='comp-details'>Accounting / Finance</span>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className='open-jobs'>
                                                        <span className='comp-details'> Open Jobs – 21 </span>
                                                    </div>

                                                </div>

                                            </Col>

                                            <Col lg={4} md={6} sm={12} className='cards-col'>

                                                <div className='company-inner-card'>

                                                    <div className='company-bm-btn'><BiBookmark /></div>

                                                    <div className='comp-center-details'>

                                                        <div className='card-featu'>Feature</div>

                                                        <div className='details-sec'>

                                                            <img src={compLogoImg} alt="Company Logo" />
                                                            <Link to={'/employers/single'} className='comp-title'>Udemy</Link>
                                                            <div className='detail-txt space-x-4'>
                                                                <IoLocation className='comp-card-i' />
                                                                <span className='comp-details'>London, UK</span>
                                                            </div>
                                                            <div className='detail-txt space-x-4'>
                                                                <PiShoppingBagOpen className='comp-card-i' />
                                                                <span className='comp-details'>Accounting / Finance</span>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className='open-jobs'>
                                                        <span className='comp-details'> Open Jobs – 21 </span>
                                                    </div>

                                                </div>

                                            </Col>

                                            {/* <Col lg={4} md={6} sm={12} className='cards-col'>

                                                <div className='inner-card-struc'>

                                                    <div className='btn-bm-icn'><BiBookmark /></div>
                                                    <div className='card-center-details'>
                                                        <div className='fattu'> Feature </div>
                                                        <div className='card-info-center'>

                                                            <img src={compLogoImg} alt="Company Logo" />
                                                            <h4>Udemy</h4>
                                                            <div className='icon-and-txt'>
                                                                <IoLocation className='comp-card-i' />
                                                                <span className='comp-details'>London, UK</span>
                                                            </div>
                                                            <div className='icon-and-txt'>
                                                                <PiShoppingBagOpen className='comp-card-i' />
                                                                <span className='comp-details'>Accounting / Finance</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='open-hi-open'>
                                                        <span className='comp-details'> Open Jobs – 21 </span>
                                                    </div>

                                                </div>

                                            </Col> */}

                                        </Row>

                                    </section>
                                </div>

                                {/* ----------- Cards Pagination ------------ */}
                                <Stack spacing={2} className='pagi-center'>
                                    <Pagination
                                        sx={{
                                            '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
                                                fontSize: '14px',
                                                padding: '25px 20px',
                                                borderRadius: '200px',
                                            },
                                            '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover': {
                                                backgroundColor: 'rgb(0, 85, 182)',
                                                color: 'white',
                                                transition: '0.3s ease-in-out'
                                            },
                                            '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
                                                backgroundColor: 'rgb(0, 85, 182)',
                                                color: 'white',
                                            },
                                        }}
                                        count={3}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Stack>

                            </div>

                        </Col>

                    </Row>

                </Container>
            </section>

            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}
        </div>
    )
}

export default Employers