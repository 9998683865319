import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { ImProfile } from "react-icons/im";
import { IoPeopleOutline, IoSearchOutline, IoSendOutline } from "react-icons/io5";
import { MdNotificationsNone, MdOutlineBusinessCenter, MdOutlineNotificationsActive, MdOutlinePersonSearch, MdPostAdd } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import contImg from '../Assets/Images/helpSupportImg.png';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';


const HelpAndSupport = () => {

    let navigate = useNavigate();

    let viewProfile = () => {
        navigate('/candidates/Single')
    }


    return (
        <div>
            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Employe banner start -------------- */}
            <section className='emp-banner'>

                <div className="auto-container help-bennar-center">

                    <div className='emp-banner-inner help-bennar-center space-y-4'>
                        <h1 className='emp-page-title'>How can we help you?</h1>
                        <div className="help-search-outer">
                            <input
                                type="text"
                                placeholder='Search'
                                className="help-search-inp"
                            // onChange={(e) => handleChange(e)}
                            />
                            <div className="help-search-i-outer">
                                <IoSearchOutline className='help-search-icon' />
                            </div>
                        </div>

                    </div>

                    <div className="help-popular-sec">
                        <h6 className="help-popular-h" style={{ color: 'black' }}>Popular Searches</h6>
                        <div className="help-p-search-tabs">
                            <span className="help-p-tabs">Other</span>
                            <span className="help-p-tabs">Account</span>
                            <span className="help-p-tabs">Freelancer</span>
                        </div>
                    </div>

                </div>

            </section>
            {/* *************** Employe banner end ***************** */}

            <section className="job-sec">
                <Container>
                    <Row>
                        <Col lg={3} md={6} sm={12} className="help-card-col">
                            <Link to={'/help&support/applyjobs'} className="help-card-outer">
                                <IoSendOutline className="help-card-i" />
                                <span className="help-card-text">Apply Jobs</span>
                            </Link>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="help-card-col">
                            <Link to={'/help&support/alertjobs'} className="help-card-outer">
                                <MdOutlineNotificationsActive className="help-card-i" />
                                <span className="help-card-text">Alert Jobs</span>
                            </Link>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="help-card-col">
                            <Link to={'/help&support/candidates'} className="help-card-outer">
                                <IoPeopleOutline className="help-card-i" />
                                <span className="help-card-text">Candidates</span>
                            </Link>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="help-card-col">
                            <Link to={'/help&support/employers'} className="help-card-outer">
                                <MdOutlineBusinessCenter className="help-card-i" />
                                <span className="help-card-text">Employers</span>
                            </Link>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="help-card-col">
                            <Link to={'/help&support/jobposts'} className="help-card-outer">
                                <MdPostAdd className="help-card-i" />
                                <span className="help-card-text">Job Posts</span>
                            </Link>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="help-card-col">
                            <Link to={'/help&support/workers'} className="help-card-outer">
                                <MdOutlinePersonSearch className="help-card-i" />
                                <span className="help-card-text">Find Jobs</span>
                            </Link>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="help-card-col">
                            <Link to={'/help&support/resumealerts'} className="help-card-outer">
                                <MdNotificationsNone className="help-card-i" />
                                <span className="help-card-text">Resume Alerts</span>
                            </Link>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="help-card-col">
                            <Link to={'/help&support/cvmanager'} className="help-card-outer">
                                <ImProfile  className="help-card-i" />
                                <span className="help-card-text">CV manager</span>
                            </Link>
                        </Col>
                    </Row>
                </Container>

                    <div className="cant-find-sec">
                    <Container>
                        <Row>
                            <Col lg={8} md={12} sm={12} className="help-cant-find-col">
                                <div className="help-cant-find">
                                    <h2>Can't find what are you looking for? </h2>
                                    <p>We're here to help you.</p>
                                    <Link to={'/help&support/contactus'}>
                                        Contact us
                                    </Link>
                                </div>
                            </Col>
                            <Col lg={4} md={12} sm={12} className="help-cant-find-col">
                                <img src={contImg} alt="Contact Svg" style={{width:255}}/>
                            </Col>
                        </Row>
                    </Container>
                    </div>
            </section>

            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec' style={{top:111}}>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}
        </div>
    )
}

export default HelpAndSupport