import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import locIcon from '../Assets/Images/mapLocationIcon.svg';
import mobIcon from '../Assets/Images/mapMobIcon.svg';
import emailIcon from '../Assets/Images/mapThird.svg';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';


const Contact = () => {

    return (
        <div>

            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Contact body start -------------- */}
            <div className="contact-body">

                {/* ----------------- Google Map -------------- */}
                <div className="contact-map">

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.0360503765!2d-74.30932697674075!3d40.
                        69753996663266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62
                        !2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1712404123957!5m2!1sen!2s"
                        width="600" height="450" style={{ border: 0 }} allowFullScreen loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    >
                    </iframe>

                </div>

                {/* ----------------- Map cards -------------- */}
                <section className='map-cards-sec'>
                    <Container>

                        <div className="cont-map-cards">
                            <Row style={{ width: '90%', margin: 'auto', paddingBottom: 30 }}>

                                <Col className='map-card-flow space-y-6' lg={4} md={6} sm={12}>
                                    <img src={locIcon} alt="Location icon" />
                                    <span className='map-card-h'>Address</span>
                                    <p className='map-card-p'>329 Queensberry Street, North Melbourne VIC 3051, Australia.</p>
                                </Col>
                                <Col className='map-card-flow space-y-6' lg={4} md={6} sm={12}>
                                    <img src={mobIcon} alt="Location icon" />
                                    <span className='map-card-h'>Call Us</span>
                                    <p className='map-card-p'>+1(620)213-4144 <br /> +1(741)314-6255</p>
                                </Col>
                                <Col className='map-card-flow space-y-6' lg={4} md={6} sm={12}>
                                    <img src={emailIcon} alt="Location icon" />
                                    <span className='map-card-h'>Email</span>
                                    <p className='map-card-p'>support@worker.com <br /> info@worker.com </p>
                                </Col>

                            </Row>
                        </div>

                    </Container>
                </section>

                {/* Contact section start */}
                <div className='auto-container'>
                    <section className="b-dtls-sugg-sec cont-form-sec">

                        <h5>Leave A Message</h5>

                        <form>
                            <Row>
                                <Col lg={6} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userName">Your Name</label>
                                    <input type="text" name="" id="userName" placeholder='Your Name*' className='c-inp-dtls-name' required />
                                </Col>
                                <Col lg={6} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userEmail">Your Email</label>
                                    <input type="email" name="userEmail" id="" placeholder='Your Email*' className='c-inp-dtls-name' required />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userSubject">Subject</label>
                                    <input type="text" name="" id="userSubject" placeholder='Subject*' className='c-inp-dtls-name' required />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userMsg">Your Message</label>
                                    <textarea type="text" id='userMsg' className='b-dtls-message' placeholder='Write your message...' required />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <button type='submit' className='b-dtls-send-msg cont-msg-btn' style={{width:182}}>
                                        Send Message
                                    </button>
                                </Col>
                            </Row>
                        </form>

                    </section>
                </div>

            </div>
            {/* *************** Contact body end ***************** */}


            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}


        </div>
    )
}

export default Contact