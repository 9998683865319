import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import EmpDashHeader from '../Components/EmpDashHeader';
import EmpDashSideBar from '../Components/EmpDashSideBar';
import { Container, Row, Col } from 'react-bootstrap';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SlHome } from "react-icons/sl";
import { LuUser2 } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineBell } from "react-icons/ai";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import CandiDashSideMenu from '../Components/CandiDashSideMenu';
import logo from '../Assets/Images/logo.png';

import { DashCopyRightFooter } from '../Components/Footer';

const CandidatePackages = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <EmpDashHeader />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>

                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <CandiDashSideMenu />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>Packages!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/candidate/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/my/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> My Profile </span></NavLink></li>
                                        <li><NavLink to={'/my/resume'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> My Resume </span></NavLink></li>
                                        <li><NavLink to={'/job/recommendation'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Recommendation </span></NavLink></li>
                                        <li><NavLink to={'/applied/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Applied Jobs </span></NavLink></li>
                                        <li><NavLink to={'/Job/Alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Job Alerts </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/jobs'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Jobs </span></NavLink></li>
                                        <li><NavLink to={'/cv/manager'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> CV Manager </span></NavLink></li>
                                        <li><NavLink to={'/candidate/packages'} className='dashSideLink space-x-5'><PiPackage className='dash-link-icon-d' /> <span className='dash-link-txt'> Packages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/candidate/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        <div className="dash-manage-job-sec">

                            <div className="dash-manage-top">
                                <h5 className='empdash-shortlist-heading'>My Packages</h5>
                            </div>

                            {/* Job listing table */}
                            <div className="dash-j-list-sec">

                                <table className='dash-manage-table'>

                                    <thead className='dash-manage-th-sec'>
                                        <tr>
                                            <th className="dash-manage-th">#</th>
                                            <th className="dash-manage-th">Transaction id</th>
                                            <th className="dash-manage-th">Package</th>
                                            <th className="dash-manage-th">Expiry</th>
                                            <th className="dash-manage-th">Total Jobs/CVs</th>
                                            <th className="dash-manage-th">Used</th>
                                            <th className="dash-manage-th">Remaining</th>
                                            <th className="dash-manage-th">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='dash-manage-tr'>
                                            <td>
                                                1
                                            </td>
                                            <td>
                                                #593677663
                                            </td>
                                            <td>
                                                <a href="#" className='dash-manage-applied-a'>Super CV Pack</a>
                                            </td>
                                            <td>
                                                Jan 11, 2021
                                            </td>
                                            <td>
                                                50
                                            </td>
                                            <td>
                                                8
                                            </td>
                                            <td>
                                                42
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                        </tr>
                                        <tr className='dash-manage-tr'>
                                            <td>
                                                2
                                            </td>
                                            <td>
                                                #593677663
                                            </td>
                                            <td>
                                                <a href="#" className='dash-manage-applied-a'>Super CV Pack</a>
                                            </td>
                                            <td>
                                                Jan 11, 2021
                                            </td>
                                            <td>
                                                50
                                            </td>
                                            <td>
                                                8
                                            </td>
                                            <td>
                                                42
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                        </tr>
                                        <tr className='dash-manage-tr'>
                                            <td>
                                                3
                                            </td>
                                            <td>
                                                #593677663
                                            </td>
                                            <td>
                                                <a href="#" className='dash-manage-applied-a'>Super CV Pack</a>
                                            </td>
                                            <td>
                                                Jan 11, 2021
                                            </td>
                                            <td>
                                                50
                                            </td>
                                            <td>
                                                8
                                            </td>
                                            <td>
                                                42
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                        </tr>
                                        <tr className='dash-manage-tr'>
                                            <td>
                                                4
                                            </td>
                                            <td>
                                                #593677663
                                            </td>
                                            <td>
                                                <a href="#" className='dash-manage-applied-a'>Super CV Pack</a>
                                            </td>
                                            <td>
                                                Jan 11, 2021
                                            </td>
                                            <td>
                                                50
                                            </td>
                                            <td>
                                                8
                                            </td>
                                            <td>
                                                42
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                        </tr>
                                        <tr className='dash-manage-tr'>
                                            <td>
                                                5
                                            </td>
                                            <td>
                                                #593677663
                                            </td>
                                            <td>
                                                <a href="#" className='dash-manage-applied-a'>Super CV Pack</a>
                                            </td>
                                            <td>
                                                Jan 11, 2021
                                            </td>
                                            <td>
                                                50
                                            </td>
                                            <td>
                                                8
                                            </td>
                                            <td>
                                                42
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                        </tr>
                                        <tr className='dash-manage-tr'>
                                            <td>
                                                6
                                            </td>
                                            <td>
                                                #593677663
                                            </td>
                                            <td>
                                                <a href="#" className='dash-manage-applied-a'>Super CV Pack</a>
                                            </td>
                                            <td>
                                                Jan 11, 2021
                                            </td>
                                            <td>
                                                50
                                            </td>
                                            <td>
                                                8
                                            </td>
                                            <td>
                                                42
                                            </td>
                                            <td>
                                                <span className='dash-manage-active'>Active</span>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>

                        </div>


                    </div>

                    <DashCopyRightFooter />

                </Col>

            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default CandidatePackages;