import React from 'react'
import Navigation from './Config/Navigation'
import ScrollToTop from './Components/ScrollToTOp'

const App = () => {
  return (
    <div>
      <ScrollToTop />
      <Navigation />
    </div>
  )
}

export default App