const handleBlogZoom =_=>{

    const blogImg = document.getElementById("blogImg");

    blogImg.style.transform= "scale(1.1)";
    blogImg.style.transition= "transition: 0.3s ease-in-out";
}
const handleBlogOut =_=>{

    const blogImg = document.getElementById("blogImg");

    blogImg.style.transform= "scale(1)";
    blogImg.style.transition= "transition: 0.3s ease-in-out";
}

const handleRecentZoom =_=>{

    const recentImg = document.getElementById("recentImg");

    recentImg.style.transform= "scale(1.1)";
    recentImg.style.transition= "transition: 0.3s ease-in-out";
}

const handleRecentOut =_=>{

    const recentImg = document.getElementById("recentImg");

    recentImg.style.transform= "scale(1)";
    recentImg.style.transition= "transition: 0.3s ease-in-out";
}

export { handleBlogZoom, handleBlogOut, handleRecentZoom, handleRecentOut }