import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import signInBg from '../Assets/Images/signin&upBg.jpg';
import Form from 'react-bootstrap/Form';
import { FaFacebookF } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import whiteLogo from '../Assets/Images/logo white.png';
import logo from '../Assets/Images/logo.png';

const ForgetPassword = () => {
    return (
        <div className='sign-in-body'>

            <Link to={'/'} className='sign-page-logo'>
                <img src={whiteLogo} alt="Logo" className='nav__logo' />
            </Link>

            <Row style={{ width: '100%' }}>

                {/* Sign In background image */}
                <Col lg={5} className='forget-in-bg'>
                    <img src={signInBg} alt="SignInBg" />
                </Col>

                {/* Sign In form */}
                <Col lg={7} sm={12} className='sign-in-form'>
                    <div className="sign-form-sec">

                        <Link to={'/'} className='sign-page-logo2'>
                            <img src={logo} alt="Logo" className='nav__logo' />
                        </Link>

                        <h3>Forget password</h3>

                        <form>
                            <Row>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userSubject">Verification email</label>
                                    <input type="email" name="" id="userSubject" placeholder='Verification email' required className='b-dtls-name' />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <button type='submit' className='signin-btn'>Verify</button>
                                </Col>
                            </Row>
                        </form>

                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default ForgetPassword