import React, { useEffect, useRef, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AiOutlineBell, AiOutlineUser } from "react-icons/ai";
import { CiHeart } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoIosArrowDown, IoLogoInstagram } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { TiArrowSortedUp } from "react-icons/ti";
import { Link, useNavigate } from 'react-router-dom';
import userImg from '../Assets/Images/dash-user-img.png';
import logo from '../Assets/Images/logo.png';


const EmpDashHeader = () => {

    let navigate = useNavigate();
    let [toogleBar, SetToogleBar] = useState(false);
    const [show, setShow] = useState(false);
    const [showNotif, setShowDropdown] = useState(false);
    const [selectedNotif, setSelectedNotif] = useState("Notification");
    const [isRotated, setIsRotated] = useState(false);
    const notifRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (notifRef.current && !notifRef.current.contains(event.target)) {
                setShowDropdown(false);
                setIsRotated(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const toggleDropdown = () => {
        setShowDropdown(!showNotif);
        setIsRotated(!isRotated);
    };

    const handleOptionClick = (option) => {
        setSelectedNotif(option);
        setShowDropdown(false);
        setIsRotated(false);
    };

    const readUnreadNoti = (value) => {

        let readBtn = document.getElementById("readBtn");
        let unreadBtn = document.getElementById("unreadBtn");

        if (value == 'read') {
            readBtn.classList = "noti-s-read-btn";
            readBtn.remove.classList = "noti-read-btn";
            unreadBtn.classList = "noti-read-btn";
        }
        else if (value == "unread") {
            unreadBtn.classList = "noti-s-read-btn";
            unreadBtn.remove.classList = "noti-read-btn";
            readBtn.classList = "noti-read-btn";
        }
        else {
            readBtn.classList = "noti-s-read-btn";
            readBtn.remove.classList = "noti-read-btn";
            unreadBtn.classList = "noti-read-btn";
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogin = _ => { navigate('/signin') }

    return (

        <div>

            {/* < !--==================== HEADER ==================== --> */}
            <header class="header" id="header">
                <nav class="nav container">

                    <div className="dash-header-nav">
                        <Link to={'/'} className='dash-logo-main'>
                            <img src={logo} alt="Logo" className='nav__logo' />
                        </Link>

                        <div class="nav__menu" id="nav-menu">

                            <ul class="nav__list" style={{ alignItems: 'center' }}>
                                <li class="nav__item">
                                    <Link to={'/'} className='nav__link'>
                                        Home
                                    </Link>
                                </li>

                                <li class="nav__item">
                                    <Link to={'/workers'} className='nav__link'>Find employee</Link>
                                </li>

                                <li class="nav__item nav__pages nav__link">
                                    <Link to={'/employers/list'} className='nav__link'>
                                        <div className="header-page-arrow">
                                            <span className=''>Employers</span>
                                            <IoIosArrowDown />
                                        </div>
                                    </Link>
                                    {/* employer dropdown */}
                                    <div className='nav__pages_link'>
                                        <ul>
                                            <span className='page-drop-arrow-i'><TiArrowSortedUp /></span>
                                            <li>
                                                <Link to={'/employer/dashboard'} className='nav-page-link'>Employer Dashboard</Link>
                                            </li>
                                        </ul>
                                    </div>

                                </li>

                                <li class="nav__item nav__pages nav__link">
                                    <Link to={'/candidates/list'} className='nav__link'>
                                        <div className="header-page-arrow">
                                            <span className=''>Candidates</span>
                                            <IoIosArrowDown />
                                        </div>
                                    </Link>
                                    {/* Candidate dropdown */}
                                    <div className='nav__pages_link'>
                                        <ul>
                                            <span className='page-drop-arrow-i'><TiArrowSortedUp /></span>
                                            <li>
                                                <Link to={'/candidate/dashboard'} className='nav-page-link'>Candidate Dashboard</Link>
                                            </li>
                                        </ul>
                                    </div>

                                </li>

                                <li class="nav__item">
                                    <Link to={'/blog/list'} className='nav__link'>Blog</Link>
                                </li>
                                <li class="nav__item nav__pages nav__link" style={{ cursor: 'pointer' }}>
                                    <div className="header-page-arrow">
                                        <span className=''>Pages</span>
                                        <IoIosArrowDown />
                                    </div>
                                    {/* Pages dropdown */}
                                    <div className='nav__pages_link'>
                                        <ul>
                                            <span className='page-drop-arrow-i'><TiArrowSortedUp /></span>
                                            <li>
                                                <Link to={'/about'} className='nav-page-link'>About</Link>
                                            </li>
                                            <li>
                                                <Link to={'/faq'} className='nav-page-link'>Faq</Link>
                                            </li>
                                            <li>
                                                <Link to={'/terms'} className='nav-page-link'>Terms</Link>
                                            </li>
                                            <li>
                                                <Link to={'/pricing'} className='nav-page-link'>Pricing</Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className='nav-page-link'>Contact</Link>
                                            </li>
                                            <li>
                                                <Link to={'/help&support'} className='nav-page-link'>Help & support</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>

                            {/* Offcanva of navbar */}
                            <Offcanvas show={show} onHide={handleClose} className="toggle" style={{ width: 320, transition: '.4s ease-in-out' }}>
                                <Offcanvas.Header className='toggle-header'>

                                    <Link to={'/'}>
                                        <img src={logo} alt="Logo" className='offcanva-logo' />
                                    </Link>

                                    <div className='close-toggle' onClick={handleClose}>
                                        <IoClose className='close-icon' />
                                    </div>

                                </Offcanvas.Header>
                                <Offcanvas.Body style={{ width: '100%' }}>

                                    <ul className='toggle-list-style mb-4'>
                                        <li class="nav__item">
                                            <Link to={'/'} className='nav__link'>
                                                Home
                                            </Link>
                                        </li>

                                        <li class="nav__item">
                                            <Link to={'/workers'} className='nav__link'>Find Jobs</Link>
                                        </li>

                                        <li class="nav__item">
                                            <Link to={'/employers/list'} className='nav__link'>Employers</Link>
                                        </li>

                                        <li class="nav__item">
                                            <Link to={'/candidates/list'} className='nav__link'>Candidates</Link>
                                        </li>
                                        <li class="nav__item">
                                            <Link to={'/blog/list'} className='nav__link'>Blog</Link>
                                        </li>
                                        <li class="nav__item">
                                            <Link to={'/about'} className='nav__link'>About</Link>
                                        </li>
                                        <li class="nav__item">
                                            <Link to={'/faq'} className='nav__link'>Faq</Link>
                                        </li>
                                        <li class="nav__item">
                                            <Link to={'/terms'} className='nav__link'>Terms</Link>
                                        </li>
                                        <li class="nav__item">
                                            <Link to={'/contact'} className='nav__link'>Contact</Link>
                                        </li>
                                        <li class="nav__item">
                                            <Link to={'/employer/dashboard'} className='nav__link'>
                                                <div className='j-post_btn'>
                                                    Job Post
                                                </div>
                                            </Link>
                                        </li>
                                        <li class="nav__item">
                                            <div className="toogle-menu-foot-sec" style={{ padding: 0, }}>

                                                <span className='toogle-menu-footer-h'>
                                                    Call us
                                                </span>
                                                <p className='toogle-menu-footer-h-num'>123 456 7890</p>
                                                <span className='toogle-menu-footer-txt'>329 Queensberry Street, North Melbourne VIC</span>
                                                <span className='toogle-menu-footer-txt'>3051, Australia.</span>
                                                <span className='toogle-menu-footer-txt'>support@superio.com</span>

                                            </div>
                                        </li>
                                        <li class="nav__item">
                                            <div className='s-emp-social-icons' style={{ justifyContent: 'flex-start', columnGap: '35px' }}>
                                                <FaFacebookF className='s-emp-social-i' />
                                                <FaTwitter className='s-emp-social-i' />
                                                <IoLogoInstagram className='s-emp-social-i' />
                                                <FaLinkedinIn className='s-emp-social-i' />
                                            </div>

                                        </li>
                                    </ul>


                                </Offcanvas.Body>
                            </Offcanvas>
                            {/* ************************** */}

                        </div>
                    </div>

                    <div className="dash-profile-bell-noti-sec">

                        <div className="dash-like-heart">
                            <div className="dash-like-count">2</div>
                            <CiHeart />
                        </div>

                        <div className={`dash-bell-notify-i ${showNotif ? 'active' : ''}`} ref={notifRef}>
                            <AiOutlineBell onClick={toggleDropdown} />

                            {showNotif && (
                                <div className='notif-options'>

                                    <h3 className='noti-drop-heading'>Notifications</h3>

                                    <div className="noti-read-unread">
                                        <span className='noti-read-btn' id='readBtn' onClick={() => readUnreadNoti("read")}>Read</span>
                                        <span className='noti-read-btn' id='unreadBtn' onClick={() => readUnreadNoti("unread")}>Unread</span>
                                    </div>

                                    <div className="noti-new-showall">
                                        <span className='noti-feed-heading'>New</span>
                                        <Link to={'/all/notifications'} className='noti-showall'>See all</Link>
                                    </div>

                                    <ul className="">

                                        <li className="" onClick={() => handleOptionClick("Job Categories")}>
                                            <Link to={'/'} className='notif-option'>
                                                <div className='noti-feed-outer'>

                                                    <span className='single-noti-img'>
                                                        <img src={userImg} alt="company logo" />
                                                    </span>

                                                    <div className='single-noti-text-sec'>
                                                        <p>
                                                            <span className='single-noti-txt-bold'> Darlene Robertson </span>
                                                            You are selected for gmg solution.
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                        </li>

                                        <li className="" onClick={() => handleOptionClick("Job Categories")}>
                                            <Link to={'/'} className='notif-option'>
                                                <div className='noti-feed-outer'>

                                                    <span className='single-noti-img'>
                                                        <img src={userImg} alt="company logo" />
                                                    </span>

                                                    <div className='single-noti-text-sec'>
                                                        <p>
                                                            <span className='single-noti-txt-bold'> Darlene Robertson </span>
                                                            dfjhdskhgfkjdvfk jdsvjfsd jkfbkdsj vbfjlsd bfjdsbjav sfkjvdskj fvjdsaf
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                        </li>

                                        <li className="" onClick={() => handleOptionClick("Job Categories")}>
                                            <Link to={'/'} className='notif-option'>
                                                <div className='noti-feed-outer'>

                                                    <span className='single-noti-img'>
                                                        <img src={userImg} alt="company logo" />
                                                    </span>

                                                    <div className='single-noti-text-sec'>
                                                        <p>
                                                            <span className='single-noti-txt-bold'> Darlene Robertson </span>
                                                            dfjhdskhgfkjdvfk jdsvjfsd jkfbkdsj vbfjlsd bfjdsbjav sfkjvdskj fvjdsaf
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                        </li>

                                        <li className="" onClick={() => handleOptionClick("Job Categories")}>
                                            <Link to={'/'} className='notif-option'>
                                                <div className='noti-feed-outer'>

                                                    <span className='single-noti-img'>
                                                        <img src={userImg} alt="company logo" />
                                                    </span>

                                                    <div className='single-noti-text-sec'>
                                                        <p>
                                                            <span className='single-noti-txt-bold'> Darlene Robertson </span>
                                                            dfjhdskhgfkjdvfk jdsvjfsd jkfbkdsj vbfjlsd bfjdsbjav sfkjvdskj fvjdsaf
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                        </li>

                                        <li className="" onClick={() => handleOptionClick("Job Categories")}>
                                            <Link to={'/'} className='notif-option'>
                                                <div className='noti-feed-outer'>

                                                    <span className='single-noti-img'>
                                                        <img src={userImg} alt="company logo" />
                                                    </span>

                                                    <div className='single-noti-text-sec'>
                                                        <p>
                                                            <span className='single-noti-txt-bold'> Darlene Robertson </span>
                                                            dfjhdskhgfkjdvfk jdsvjfsd jkfbkdsj vbfjlsd bfjdsbjav sfkjvdskj fvjdsaf
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                        </li>

                                        <li className="" onClick={() => handleOptionClick("Job Categories")}>
                                            <Link to={'/'} className='notif-option'>
                                                <div className='noti-feed-outer'>

                                                    <span className='single-noti-img'>
                                                        <img src={userImg} alt="company logo" />
                                                    </span>

                                                    <div className='single-noti-text-sec'>
                                                        <p>
                                                            <span className='single-noti-txt-bold'> Darlene Robertson </span>
                                                            dfjhdskhgfkjdvfk jdsvjfsd jkfbkdsj vbfjlsd bfjdsbjav sfkjvdskj fvjdsaf
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            )}

                        </div>

                        <div className="dash-user-profile-drop">

                            <span className='dash-user-img-drop'>
                                <img src={userImg} alt="user image" />
                            </span>

                            <span className='dash-user-name-drop'>
                                My Account
                            </span>

                            <IoIosArrowDown className='dash-user-arrow-drop' />
                        </div>

                    </div>

                    <div class="dsah-nav-action-s-h nav__actions">
                        {/* <!-- Login button --> */}
                        <AiOutlineUser className="nav__login" id="login-btn" onClick={handleLogin} />

                        {/* <!-- Toggle button --> */}
                        {
                            !toogleBar ?
                                <div class="nav__toggle" onClick={handleShow} >
                                    <HiOutlineMenuAlt3 className='nav_toggle_icon' />
                                </div>
                                :
                                <div class="nav__toggle" onClick={handleClose} >
                                    <HiOutlineMenuAlt3 className='nav_toggle_icon' />
                                </div>

                        }

                    </div>

                </nav>
            </header>

        </div>

    )
}

export default EmpDashHeader