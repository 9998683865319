import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { CiClock2 } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6";
import { FiBookmark } from "react-icons/fi";
import { IoLogoInstagram } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { PiShoppingBagOpen } from "react-icons/pi";
import { Link } from 'react-router-dom';
import companyLogo from '../Assets/Images/company-2.png';
import singleImg1 from '../Assets/Images/employers-single-1.png';
import singleImg2 from '../Assets/Images/employers-single-2.png';
import singleImg3 from '../Assets/Images/employers-single-3.png';
import singleImg4 from '../Assets/Images/employers-single-4.png';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';


const EmployersSingle = () => {

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);


    return (
        <div>

            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Employers single start -------------- */}
            <div className='content-body'>
                <Container>
                    <Row>

                        {/* About Comoany */}
                        <Col className='space-y-4 s-emp-left-side-col' lg={8} md={12} sm={12}>
                            <span className='s-emp-title'>About Company</span>
                            
                            <section className='s-emp-discrip space-y-8'>

                                <p className='s-emp-discrip-p s-emp-txt'>
                                    As a Product Designer, you will work within a Product Delivery Team fused with UX,
                                    engineering, product and data talent. You will help the team design beautiful interfaces that
                                    solve business challenges for our clients. We work with a number of Tier 1 banks on building web-based
                                    applications for AML, KYC and Sanctions List management workflows. This role is ideal if you are looking
                                    to segue your career into the FinTech or Big Data arenas.
                                </p>
                                <p className='s-emp-discrip-p s-emp-txt'>
                                    As a Product Designer, you will work within a Product Delivery Team fused with UX,
                                    engineering, product and data talent. You will help the team design beautiful interfaces that
                                    solve business challenges for our clients. We work with a number of Tier 1 banks on building web-based
                                    applications for AML, KYC and Sanctions List management workflows. This role is ideal if you are looking
                                    to segue your career into the FinTech or Big Data arenas.
                                </p>

                                <Row>

                                    <Col className='s-emp-img p-2' lg={3} md={6} sm={12}>
                                        <img src={singleImg1} alt="employerImg" className='emp-s-lightbox-img' />
                                    </Col>
                                    <Col className='s-emp-img p-2' lg={3} md={6} sm={12}>
                                        <img src={singleImg2} alt="employerImg" className='emp-s-lightbox-img' />
                                    </Col>
                                    <Col className='s-emp-img p-2' lg={3} md={6} sm={12}>
                                        <img src={singleImg3} alt="employerImg" className='emp-s-lightbox-img' />
                                    </Col>
                                    <Col className='s-emp-img p-2' lg={3} md={6} sm={12}>
                                        <img src={singleImg4} alt="employerImg" className='emp-s-lightbox-img' />
                                    </Col>

                                </Row>

                                <p className='s-emp-discrip-p s-emp-txt'>
                                    As a Product Designer, you will work within a Product Delivery Team fused with UX,
                                    engineering, product and data talent. You will help the team design beautiful interfaces that
                                    solve business challenges for our clients. We work with a number of Tier 1 banks on building web-based
                                    applications for AML, KYC and Sanctions List management workflows. This role is ideal if you are looking
                                    to segue your career into the FinTech or Big Data arenas.
                                </p>

                                <p className='s-emp-discrip-p s-emp-txt'>
                                    As a Product Designer, you will work within a Product Delivery Team fused with UX,
                                    engineering, product and data talent. You will help the team design beautiful interfaces that
                                    solve business challenges for our clients. We work with a number of Tier 1 banks on building web-based
                                    applications for AML, KYC and Sanctions List management workflows. This role is ideal if you are looking
                                    to segue your career into the FinTech or Big Data arenas.
                                </p>

                            </section>

                            <section className='s-emp-other-jobs emp-s-about-comp-three-jobs'>

                                <h2 className='related-title'>3 Others jobs available</h2>
                                <p className='rel-p sec-txt'>2020 jobs live - 293 added today.</p>
                                {/* ----------- Three Cards here ------------ */}
                                <div className='rel-card-sec'>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/employers/single'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>

                                    </section>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/employers/single'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>

                                    </section>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/employers/single'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>

                                    </section>

                                </div>
                                {/* ************* Three Cards end ************* */}
                            </section>

                        </Col>

                        {/* Job & company details & Job location */}
                        <Col className='space-y-10 s-emp-right-side-col' lg={4} md={12} sm={12}>

                            {/* ---------- Apply for job and bookmark buttons -------- */}
                            <div className='emp-msg-bm-btn'>
                                {/* <div className='apply-for-btn'></div> */}
                                <button className='msg-btn-s-emp' onClick={handleShowModal}>Private Message</button>
                                <button className='bm-btn-s-emp'><FiBookmark /></button>

                                {/* ----------- APPLY JOB MODAL -------------- */}
                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Send message to Stripe</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <input type="text" name="" id="" placeholder='Your Name' className='s-emp-name' />
                                        <textarea type="text" className='s-emp-message' placeholder='Message' required />
                                        <div className='s-emp-term'>
                                            <a href="#" className='s-emp-terms-link'>
                                                <span className='s-emp-terms-txt'> You accept our </span> <p className='s-emp-msg-hover-txt'> &nbsp; Terms and Conditions and Privacy Policy</p>
                                            </a>
                                        </div>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className='s-emp-send-msg' onClick={handleCloseModal}> Send message </button>
                                    </Modal.Footer>
                                </Modal>

                            </div>

                            {/* ---------- Job Details start -------- */}
                            <div className='flex-single'>

                                <section className='single-emp-sec'>

                                    <div className='s-emp-card-cont'>

                                        <div className='s-emp-comp-logo'>
                                            <img src={companyLogo} alt="company logo" />
                                        </div>
                                        <h5 className="s-emp-title">Stripe</h5>
                                        <div className='s-emp-open-job'>
                                            <Link to={'/'} className='s-emp-info-link'>
                                                Open Jobs – 22
                                            </Link>
                                        </div>

                                    </div>

                                    <div className="s-emp-info">

                                        <div className='s-emp-company-info'>

                                            <span className='s-emp-comp-title'>Primary industry:</span>
                                            <span className='s-emp-comp-sec-title'>Software</span>

                                        </div>

                                        <div className='s-emp-company-info'>

                                            <span className='s-emp-comp-title'>Company size:</span>
                                            <span className='s-emp-comp-sec-title'>501-1,000</span>

                                        </div>

                                        <div className='s-emp-company-info'>

                                            <span className='s-emp-comp-title'>Founded in:</span>
                                            <span className='s-emp-comp-sec-title'>2011</span>

                                        </div>

                                        <div className='s-emp-company-info'>

                                            <span className='s-emp-comp-title'>Phone:</span>
                                            <span className='s-emp-comp-sec-title'>123 456 7890</span>

                                        </div>

                                        <div className='s-emp-company-info'>

                                            <span className='s-emp-comp-title'>Email:</span>
                                            <span className='s-emp-comp-sec-title'>info@joio.com</span>

                                        </div>

                                        <div className='s-emp-company-info'>

                                            <span className='s-emp-comp-title'>Location:</span>
                                            <span className='s-emp-comp-sec-title'>London, UK</span>

                                        </div>

                                        <div className='s-emp-company-info'>

                                            <span className='s-emp-comp-title'>Social media:</span>
                                            <div className='s-emp-social-icons'>
                                                <FaFacebookF className='s-emp-social-i' />
                                                <FaTwitter className='s-emp-social-i' />
                                                <IoLogoInstagram className='s-emp-social-i' />
                                                <FaLinkedinIn className='s-emp-social-i' />
                                            </div>

                                        </div>

                                        <button className='s-emp-ref-btn'>
                                            https://segment.com
                                        </button>

                                    </div>

                                </section>

                            </div>

                            {/* ---------- Job Location start -------- */}
                            <div className='flex-single'>

                                <section className='location-sec'>

                                    <span className='f-titles'>Job Location</span>

                                    <div className='loc-map'>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.0728923992815!2d68.
                                            33833267531206!3d25.402370523360098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394
                                            c71003fbc4357%3A0x9c3e73c72da2b6c4!2sGMG%20Solutions!5e0!3m2!1sen!2s!4v1711105422547!5m2!1sen!2s"
                                            width="600" height="450" allowFullScreen loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        >

                                        </iframe>
                                    </div>

                                </section>

                            </div>


                        </Col>

                        {/* 3 Other Jobs */}
                        <Col lg={8} md={12} sm={12} className='emp-s-three-jobs-show-hide'>
                            <section className='s-emp-other-jobs'>

                                <h2 className='related-title'>3 Others jobs available</h2>
                                <p className='rel-p sec-txt'>2020 jobs live - 293 added today.</p>
                                {/* ----------- Three Cards here ------------ */}
                                <div className='rel-card-sec'>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/employers/single'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>

                                    </section>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/employers/single'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>

                                    </section>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/employers/single'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>

                                    </section>

                                </div>
                                {/* ************* Three Cards end ************* */}
                            </section>

                        </Col>

                    </Row>
                </Container>
            </div> <br /> <br /><br />
            {/* *************** Employers single end ***************** */}

            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}

        </div>
    )
}

export default EmployersSingle