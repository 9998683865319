import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AiOutlineUser } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoIosArrowDown, IoLogoInstagram } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { TiArrowSortedUp } from "react-icons/ti";
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/Images/logo.png';


const NavBar = () => {

  let [toogleBar, SetToogleBar] = useState(false);
  const [show, setShow] = useState(false);
  let navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLogin = _ => { navigate('/signin') }

  return (

    <div>

      {/* < !--==================== HEADER ==================== --> */}
      <header class="header" id="header">
        <nav class="nav container">

          <div className="dash-header-nav">

            <Link to={'/'} className='dash-logo-main'>
              <img src={logo} alt="Logo" className='nav__logo' />
            </Link>

            <div class="nav__menu" id="nav-menu">

              <ul class="nav__list" style={{ alignItems: 'center' }}>
                <li class="nav__item">
                  <Link to={'/'} className='nav__link'>
                    Home
                  </Link>
                </li>

                <li class="nav__item">
                  <Link to={'/workers'} className='nav__link'>Find Workers</Link>
                </li>

                <li class="nav__item nav__pages nav__link">
                  <Link to={'/employers/list'} className='nav__link'>
                    <div className="header-page-arrow">
                      <span className=''>Employers</span>
                    </div>
                  </Link>
                  {/* employer dropdown */}
                  {/* <div className='nav__pages_link'>
                    <ul>
                      <span className='page-drop-arrow-i'><TiArrowSortedUp /></span>
                      <li>
                        <Link to={'/employer/dashboard'} className='nav-page-link'>Employer Dashboard</Link>
                      </li>
                    </ul>
                  </div> */}

                </li>

                <li class="nav__item nav__pages nav__link">
                  <Link className='nav__link'>
                    <div className="header-page-arrow">
                      <span className=''>DropDown</span>
                      <IoIosArrowDown />
                    </div>
                  </Link>
                  {/* Candidate dropdown */}
                  <div className='nav__pages_link'>
                    <ul>
                      <span className='page-drop-arrow-i'><TiArrowSortedUp /></span>
                      <li>
                        <Link to={'/candidate/dashboard'} className='nav-page-link'>Candidate Dashboard</Link>
                      </li>
                      <li>
                        <Link to={'/employer/dashboard'} className='nav-page-link'>Employer Dashboard</Link>
                      </li>
                    </ul>
                  </div>

                </li>

                <li class="nav__item">
                  <Link to={'/blog/list'} className='nav__link'>Blog</Link>
                </li>
                <li class="nav__item nav__pages nav__link" style={{ cursor: 'pointer' }}>
                  <div className="header-page-arrow">
                    <span className=''>Pages</span>
                    <IoIosArrowDown />
                  </div>

                  {/* Pages dropdown */}
                  <div className='nav__pages_link'>
                    <ul>
                      <span className='page-drop-arrow-i'><TiArrowSortedUp /></span>
                      <li>
                        <Link to={'/about'} className='nav-page-link'>About</Link>
                      </li>
                      <li>
                        <Link to={'/faq'} className='nav-page-link'>Faq</Link>
                      </li>
                      <li>
                        <Link to={'/terms'} className='nav-page-link'>Terms</Link>
                      </li>
                      <li>
                        <Link to={'/pricing'} className='nav-page-link'>Pricing</Link>
                      </li>
                      <li>
                        <Link to={'/contact'} className='nav-page-link'>Contact</Link>
                      </li>
                      <li>
                        <Link to={'/help&support'} className='nav-page-link'>Help & support</Link>
                      </li>
                    </ul>

                  </div>
                </li>
              </ul>

              {/* Offcanva of navbar */}
              <Offcanvas show={show} onHide={handleClose} className="toggle" style={{ width: 320, transition: '.4s ease-in-out' }}>
                <Offcanvas.Header className='toggle-header'>

                  <Link to={'/'}>
                    <img src={logo} alt="Logo" className='offcanva-logo' />
                  </Link>

                  <div className='close-toggle' onClick={handleClose}>
                    <IoClose className='close-icon' />
                  </div>

                </Offcanvas.Header>
                <Offcanvas.Body style={{ width: '100%' }}>

                  <ul className='toggle-list-style mb-4'>
                    <li class="nav__item">
                      <Link to={'/'} className='nav__link'>
                        Home
                      </Link>
                    </li>

                    <li class="nav__item">
                      <Link to={'/workers'} className='nav__link'>Find Jobs</Link>
                    </li>

                    <li class="nav__item">
                      <Link to={'/employers/list'} className='nav__link'>Employers</Link>
                    </li>

                    <li class="nav__item">
                      <Link to={'/candidates/list'} className='nav__link'>Candidates</Link>
                    </li>
                    <li class="nav__item">
                      <Link to={'/blog/list'} className='nav__link'>Blog</Link>
                    </li>
                    <li class="nav__item">
                      <Link to={'/about'} className='nav__link'>About</Link>
                    </li>
                    <li class="nav__item">
                      <Link to={'/faq'} className='nav__link'>Faq</Link>
                    </li>
                    <li class="nav__item">
                      <Link to={'/terms'} className='nav__link'>Terms</Link>
                    </li>
                    <li class="nav__item">
                      <Link to={'/contact'} className='nav__link'>Contact</Link>
                    </li>
                    {/* <li class="nav__item">
                      <Link to={'/employer/dashboard'} className='nav__link'>
                        <div className='j-post_btn'>
                          Job Post
                        </div>
                      </Link>
                    </li> */}
                    <li class="nav__item">
                      <div className="toogle-menu-foot-sec" style={{ padding: 0, }}>

                        <span className='toogle-menu-footer-h'>
                          Call us
                        </span>
                        <p className='toogle-menu-footer-h-num'>123 456 7890</p>
                        <span className='toogle-menu-footer-txt'>329 Queensberry Street, North Melbourne VIC</span>
                        <span className='toogle-menu-footer-txt'>3051, Australia.</span>
                        <span className='toogle-menu-footer-txt'>support@superio.com</span>

                      </div>
                    </li>
                    <li class="nav__item">
                      <div className='s-emp-social-icons' style={{ justifyContent: 'flex-start', columnGap: '35px' }}>
                        <FaFacebookF className='s-emp-social-i' />
                        <FaTwitter className='s-emp-social-i' />
                        <IoLogoInstagram className='s-emp-social-i' />
                        <FaLinkedinIn className='s-emp-social-i' />
                      </div>

                    </li>
                  </ul>


                </Offcanvas.Body>
              </Offcanvas>
              {/* ************************** */}

            </div>

          </div>

          <div className="dash-profile-bell-noti-sec">

            {/* <div className="dash-like-heart"> */}
            <Link to={'/signin'} className='nav__item nav__link'>
              <div className='register_btn' >
                Login
              </div>
            </Link>
            {/* </div> */}

            <Link to={'/register'} className='nav__item nav__link'>
              <div className='j-post_btn'>
              Register
              </div>

            </Link>

          </div>

          <div class="dsah-nav-action-s-h nav__actions">
            {/* <!-- Login button --> */}
            <AiOutlineUser className="nav__login" id="login-btn" onClick={handleLogin} />

            {/* <!-- Toggle button --> */}
            {
              !toogleBar ?
                <div class="nav__toggle" onClick={handleShow} >
                  <HiOutlineMenuAlt3 className='nav_toggle_icon' />
                </div>
                :
                <div class="nav__toggle" onClick={handleClose} >
                  <HiOutlineMenuAlt3 className='nav_toggle_icon' />
                </div>

            }

          </div>

        </nav>
      </header>

    </div>

  )
}

export default NavBar;