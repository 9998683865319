import React from 'react';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';

const HelpAlertJobs = () => {
    return (
        <div>
            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Content body start -------------- */}
            <div className="content-body">

                {/* ----------------- Terms heading -------------- */}
                <div className='faq-sec-heading'>
                    <h1>Alert Jobs</h1>
                    <p>Help & Support / Alert Jobs</p>
                </div>

                {/* ----------------- Terms points -------------- */}
                <div className='auto-container'>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>1. Setting Up Job Alerts</h2>
                        <p className='about-txt'>
                            Provide instructions on how users can set up job alerts to receive notifications about new job postings
                            that match their criteria. Explain where they can find the option to set up alerts and how to customize their preferences.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>2. Alert Frequency and Preferences</h2>
                        <p className='about-txt'>
                            Allow users to customize their alert preferences, such as the frequency of notifications (daily, weekly) and the
                            types of jobs they want to be alerted about (based on location, industry, job title, etc.).
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>3. Managing Job Alerts</h2>
                        <p className='about-txt'>
                            Explain how users can manage their existing job alerts, including editing, pausing, or deleting alerts as needed.
                            Provide clear steps on how to access and modify alert settings.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>4. Optimizing Alert Criteria</h2>
                        <p className='about-txt'>
                            Offer tips on how users can optimize their alert criteria to receive relevant job notifications. Encourage them to
                            use specific keywords, location preferences, and other filters to refine their alerts.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>5. Alert Delivery Methods</h2>
                        <p className='about-txt'>
                            Inform users about the different delivery methods for job alerts, such as email notifications, mobile push
                            notifications, or in-app notifications. Allow users to choose their preferred method of receiving alerts.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>6. Unsubscribe Options</h2>
                        <p className='about-txt'>
                            Provide easy-to-find options for users to unsubscribe from job alerts if they no longer wish to receive them.
                            Explain how they can opt out of alerts and reassure them that their preferences will be respected.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>7. Technical Support for Alert System</h2>
                        <p className='about-txt'>
                            Offer technical support and troubleshooting assistance for users who encounter issues with receiving or managing
                            job alerts. Provide contact information or links to resources for further assistance.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>8. Privacy and Data Protection</h2>
                        <p className='about-txt'>
                            Assure users that their privacy is respected when setting up job alerts and that their personal information is
                            securely stored and used only for the purpose of delivering relevant job notifications.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>9. Feedback and Suggestions</h2>
                        <p className='about-txt'>
                            Encourage users to provide feedback and suggestions for improving the job alert system. Create a mechanism for
                            users to submit feedback easily, such as a feedback form or email address.
                        </p>
                    </section>

                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title' style={{ fontSize: 28 }}>10. FAQs about Job Alerts</h2>
                        <p className='about-txt'>
                            Compile a list of frequently asked questions specifically about job alert functionality. Address common queries
                            users may have about setting up, managing, and customizing their job alerts.
                        </p>
                    </section>

                </div>

            </div>
            {/* *************** Content body end ***************** */}


            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}

        </div>
    )
}

export default HelpAlertJobs