import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import signInBg from '../Assets/Images/signin&upBg.jpg';
import Form from 'react-bootstrap/Form';
import { FaFacebookF } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import whiteLogo from '../Assets/Images/logo white.png';
import logo from '../Assets/Images/logo.png';


const SignIn = () => {
    return (
        <div className='sign-in-body'>

            <Link to={'/'} className='sign-page-logo'>
                <img src={whiteLogo} alt="Logo" className='nav__logo' />
            </Link>

            <Row style={{ width: '100%' }}>

                {/* Sign In background image */}
                <Col lg={5} className='sign-in-bg'>
                    <img src={signInBg} alt="SignInBg" />
                </Col>

                {/* Sign In form */}
                <Col lg={7} sm={12} className='sign-in-form'>
                    <div className="sign-form-sec">

                        <Link to={'/'} className='sign-page-logo2'>
                            <img src={logo} alt="Logo" className='nav__logo' />
                        </Link>

                        <h3>Login to JobPortal</h3>

                        <Row className='mb-5'>
                            <form>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userSubject">User name</label>
                                    <input type="text" name="" id="userSubject" placeholder='Username' required className='b-dtls-name' />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <label htmlFor="userSubject">Password</label>
                                    <input type="text" name="" id="userSubject" placeholder='Password' required className='b-dtls-name' />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="sign-remember-forget">
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="remember"
                                        label="Remember me"
                                    />
                                    <Link to={'/forgetpassword'} className='Sign-forget-pass'>Forget Password?</Link>
                                </Col>
                                <Col lg={12} md={12} sm={12} className="b-thoughts">
                                    <button type='submit' className='signin-btn'>Log In</button>
                                </Col>
                            </form>
                            <Col lg={12} md={12} sm={12}>
                                <p className='sign-have-an-acc'>Don't have an account? <Link to={'/register'} className='sign-have-an-acc-link'>Signup</Link></p>
                            </Col>
                            <Col lg={12} md={12} sm={12} className='mb-4'>
                                <div className='flex flex-row space-x-2 text-gray-500'>

                                    <hr className='inline mt-3 border border-gray-300 w-1/2' />
                                    <span className='inline'>or</span>
                                    <hr className='inline mt-3 border border-gray-300 w-1/2' />

                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='sign-btn-col'>
                                <button className='signin-with-fb space-x-4'><FaFacebookF /> <span>Login Via Facebook</span></button>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='sign-btn-col'>
                                <button className='signin-with-gm space-x-4'><FaGoogle /> <span>Login Via Gmail</span></button>
                            </Col>

                        </Row>

                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default SignIn;