import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import aboutImg1 from '../Assets/Images/about-img-1.jpg';
import aboutImg2 from '../Assets/Images/about-img-2.jpg';
import aboutImg3 from '../Assets/Images/about-img-3.jpg';
import aboutImg4 from '../Assets/Images/about-img-4.jpg';
import aboutImg5 from '../Assets/Images/about-img-5.jpg';
import aboutImg6 from '../Assets/Images/about-img-6.jpg';
import accountImg from '../Assets/Images/accCreateImg.svg';
import jobSearch from '../Assets/Images/j-s-card-three.svg';
import resumeImg from '../Assets/Images/work-1.png';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';


const AboutUs = () => {

    let navigate = useNavigate();

    const moveOnSearch = _ => {
        navigate('/workers')
    }

    const moveOnRegister = _ => {
        navigate('/register')
    }

    return (
        <div>

            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Employe banner start -------------- */}
            <section className='emp-banner'>

                <Container>

                    <div className='emp-banner-inner space-y-4'>
                        <h1 className='emp-page-title'>About Us</h1>
                        <p className='emp-banner-txt space-x-2'>
                            <Link to={'/'} className='emp-home-link'>Home</Link> /
                            <span className='company-title-txt'>About Us</span>
                        </p>
                    </div>

                </Container>

            </section>
            {/* *************** Employe banner end ***************** */}

            {/* ----------------- Content body start -------------- */}
            <div className="about-body">

                {/* ---------- Colage image banner ----------- */}
                <Container>
                    <Row className='img-colage-banner'>

                        <Col className='colage-img' lg={3} md={6} sm={12}>
                            <figure className='about-fig'>
                                <img src={aboutImg1} alt="About image" />
                            </figure>
                        </Col>

                        <Col className='colage-img' lg={3} md={6} sm={12}>
                            <figure className='about-fig'>
                                <img src={aboutImg2} alt="About image" />
                            </figure>
                            <figure className='about-fig'>
                                <img src={aboutImg3} alt="About image" />
                            </figure>
                        </Col>

                        <Col className='colage-img' lg={3} md={6} sm={12}>
                            <figure className='about-fig'>
                                <img src={aboutImg4} alt="About image" />
                            </figure>
                            <figure className='about-fig'>
                                <img src={aboutImg5} alt="About image" />
                            </figure>
                        </Col>

                        <Col className='colage-img' lg={3} md={6} sm={12}>
                            <figure className='about-fig'>
                                <img src={aboutImg6} alt="About image" />
                            </figure>
                        </Col>

                    </Row>
                </Container>

                {/* ----------- Count box & about us ----------- */}
                <div className="auto-container">

                    {/* -------- Count box view ----------- */}
                    <section className='count-box-banner'>

                        <Row>

                            <Col lg={4} md={12} sm={12} className='count-box' >

                                <h1>4M</h1>
                                <span>4 million daily active users</span>

                            </Col>

                            <Col lg={4} md={12} sm={12} className='count-box' >

                                <h1>12M</h1>
                                <span>Over 12k open job positions</span>

                            </Col>

                            <Col lg={4} md={12} sm={12} className='count-box' >

                                <h1>20M</h1>
                                <span>Over 20 million stories shared</span>

                            </Col>

                        </Row>

                    </section>

                    {/* -------- About us text ----------- */}
                    <section className='about-txt-sec space-y-8'>
                        <h2 className='about-title'>About Superio</h2>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            vehement irresistibly fussy penguin insect additionally wow absolutely crud meretriciously hastily
                            dalmatian a glowered inset one echidna cassowary some parrot and much as goodness some froze the sullen much
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                        <p className='about-txt'>
                            Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly
                            vehement irresistibly fussy penguin insect additionally wow absolutely crud meretriciously hastily
                            dalmatian a glowered inset one echidna cassowary some parrot and much as goodness some froze the sullen much
                            dalmatian a glowered inset one echidna cassowary some parrot and much as goodness some froze the sullen much
                            connected bat wonderfully on instantaneously eel valiantly petted this along across highhandedly much.
                        </p>
                    </section>

                </div>

                {/* ----------- Dream Jobs are waiting banner ----------- */}
                <section className='about-dream-job-banner'>
                    <div className='foot-above-img'>

                        <h2 className='dream-banner-title'>Your Dream Jobs Are Waiting</h2><br />
                        <p className='w-color'>Over 1 million interactions, 50,000 success stories Make yours now.</p><br />

                        <div className='foot-flow space-x-5'>

                            <button className='b-light-btn a-dream-banner-btns px-4 py-3' onClick={moveOnSearch}>
                                Search job
                            </button>
                            <button className='b-warn-btn a-dream-banner-btns px-4 py-3' onClick={moveOnRegister}>
                                Apply job now
                            </button>

                        </div>

                    </div>

                </section>

                {/* ----------- Testimonials slider ----------- */}
                <section className="ezy__testimonial6 light">
                    <div className="container a-slider-bg">
                        <div class="row justify-content-center mb-4 mb-md-5">
                            <div className="col-lg-7 col-xl-6 text-center">
                                <h2 class="ezy__testimonial6-heading mb-3">Community Reviews</h2>
                                <p className="ezy__testimonial6-sub-heading mb-0">
                                    It’s easier to reach your savings goals when you have the right savings account. Take a look and find the
                                    right one for you!
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center">
                            <div className="col-md-6">
                                <div className="ezy__testimonial6-item mt-1">
                                    <div className="ezy__testimonial6-content">
                                        <h4 className="mb-1">Very Good Services</h4>
                                        <p className="opacity-50 mb-4">
                                            When it comes to booking a holiday, we know everyone likes something different - so we've designed our
                                            getaways with you in mind.
                                        </p>
                                        <h4 className="mb-1 fs-5">Akshay Kumar</h4>
                                        <p className="mb-4 ezy__testimonial6-rating">
                                            <span className="fas fa-star active"></span>
                                            <span className="fas fa-star active"></span>
                                            <span className="fas fa-star active"></span>
                                            <span className="fas fa-star-half-alt active"></span>
                                            <span className="fas fa-star"></span>
                                        </p>
                                    </div>
                                    <img src="https://cdn.easyfrontend.com/pictures/users/user27.jpg" style={{ margin: 'auto' }} alt=""
                                        className="img-fluid rounded-circle" width="110" />
                                </div>

                                <div className="ezy__testimonial6-dot-control mt-4"><span></span><span className="active"></span><span></span></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ------------ How it works cards ----------- */}
                <section className='banner-three a-cards py-12 mb-20'>

                    <h1>How it works</h1>
                    <p className='disc-work'>Job for anyone, anywhere</p> <br />

                    <div className='banner-three-card'>

                        <Container>

                            <Row>

                                <Col lg={4} md={6} sm={12} className='mb-4'>
                                    <div className='card-body a-cards-bg px-4'><br />

                                        <div className='card-section'>
                                            <img src={jobSearch} alt="Job Search logo" />
                                        </div><br />
                                        <h5>Search Job</h5>
                                        <p className='job-p-txt'>
                                            Searching for a job? Explore opportunities across various industries and find the perfect match for your skills today.
                                        </p><br />
                                    </div>
                                </Col>

                                <Col lg={4} md={6} sm={12} className='mb-4'>
                                    <div className='card-body a-cards-bg px-4'><br />

                                        <div className='card-section'>
                                            <img src={accountImg} alt="Accoiunt create tmage" />
                                        </div><br />
                                        <h5>Create Account</h5>
                                        <p className='job-p-txt'>
                                            Create your account today to unlock access to exclusive content, personalized recommendations, and a seamless user experience.
                                        </p><br />

                                    </div>
                                </Col>

                                <Col lg={4} md={6} sm={12} className='mb-4'>
                                    <div className='card-body a-cards-bg px-4'><br />

                                        <div className='card-section'>
                                            <img src={resumeImg} alt="ResumeLogo" />
                                        </div><br />
                                        <h5>Send Resume</h5>
                                        <p className='job-p-txt'>
                                            Send your resume to apply for your dream job and take the next step in your career journey.
                                        </p><br />

                                    </div>
                                </Col>

                            </Row>

                        </Container>


                    </div>

                </section>

            </div>

            {/* *************** Content body End *************** */}

            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec' style={{ top: 80 }}>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}


        </div>
    )
}

export default AboutUs