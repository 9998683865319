import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineUser } from "react-icons/ai";
import { CiCalendar, CiClock2 } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF, FaLinkedin, FaLinkedinIn } from "react-icons/fa6";
import { FiBookmark } from "react-icons/fi";
import { IoLogoInstagram } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { PiClockCountdownLight, PiHourglassLowFill, PiShoppingBagOpen } from "react-icons/pi";
import { RiCoinsLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import candiLogo from '../Assets/Images/candidate-1.png';
import companyLogo from '../Assets/Images/feactureImg.png';
import { CopyRightFooter, FooterSection } from '../Components/Footer';
import NavBar from '../Components/Header';





const JobSingle = () => {

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);


    return (
        <div>

            {/* ----------------- Header start -------------- */}
            <NavBar />
            {/* *************** Header end ***************** */}

            {/* ----------------- Company banner start -------------- */}
            {/* *************** Company banner end ***************** */}

            <div className='content-body'>
                <Container>
                    <Row>

                        <Col className='space-y-4 left-side-col' lg={8} md={8} sm={12}>

                            {/* ---------- job single banner user details ---------- */}
                            <section className='candi-banner'>

                                <div className='candi-s-job-content'>

                                    <span className='candi-single-company-logo'>
                                        <img src={candiLogo} alt="company logo" />
                                    </span>
                                    <h4 className='candi-title'>Salman Khan</h4>

                                    <ul className='candi-info-banner'>
                                        <li className="candi-info-list-1">
                                            <span>UI Designer</span>
                                        </li>
                                        <li className="candi-info-list">
                                            <IoLocationOutline className='candi-display-i' />
                                            <span>London, UK</span>
                                        </li>
                                        <li className="candi-info-list">
                                            <LiaMoneyBillWaveSolid className='candi-display-i' />
                                            <span>$99 / hour</span>
                                        </li>
                                        <li className="candi-info-list">
                                            <CiClock2 className='candi-display-i' />
                                            <span>Member Since,Aug 19, 2020</span>
                                        </li>
                                    </ul>

                                    <ul className='other-info-job'>

                                        <li className="candi-skill">App</li>
                                        <li className="candi-skill">Design</li>
                                        <li className="candi-skill">Digital</li>

                                    </ul>

                                </div>

                            </section>

                            {/* ---------- job single discription ---------- */}
                            <section className='discrip space-y-4'>

                                <span className='single-title'>Job Description</span>
                                <p className='discrip-p sec-txt'>
                                    As a Product Designer, you will work within a Product Delivery Team fused with UX,
                                    engineering, product and data talent. You will help the team design beautiful interfaces that
                                    solve business challenges for our clients. We work with a number of Tier 1 banks on building web-based
                                    applications for AML, KYC and Sanctions List management workflows. This role is ideal if you are looking
                                    to segue your career into the FinTech or Big Data arenas.
                                </p>

                            </section>

                            {/* ---------- job single Key responsiblities ---------- */}
                            <section className='key-respo space-y-4'>

                                <span className='single-title'>Key responsiblities</span>
                                <ul className='key-ul sec-txt'>
                                    <li>
                                        Be involved in every step of the product design cycle from discovery to developer handoff and user acceptance testing.
                                    </li>
                                    <li>
                                        Work with BAs, product managers and tech teams to lead the Product Design
                                    </li>
                                    <li>
                                        Maintain quality of the design process and ensure that when designs are translated into code they accurately reflect the design specifications.
                                    </li>
                                    <li>
                                        Accurately estimate design tickets during planning sessions.
                                    </li>
                                    <li>
                                        Contribute to sketching sessions involving non-designersCreate, iterate and maintain UI deliverables including sketch files, style guides, high fidelity prototypes, micro interaction specifications and pattern libraries.
                                    </li>
                                    <li>
                                        Ensure design choices are data led by identifying assumptions to test each sprint, and work with the analysts in your team to plan moderated usability test sessions.
                                    </li>
                                    <li>
                                        Design pixel perfect responsive UI’s and understand that adopting common interface patterns is better for UX than reinventing the wheel
                                    </li>
                                    <li>
                                        Present your work to the wider business at Show & Tell sessions.
                                    </li>
                                </ul>

                            </section>

                            {/* ---------- job single Skill & Experience ---------- */}
                            <section className='skill-exp space-y-4'>

                                <span className='single-title'>Skill & Experience</span>
                                <ul className='key-ul sec-txt'>
                                    <li>
                                        You have at least 3 years’ experience working as a Product Designer.                                    </li>
                                    <li>
                                        You have experience using Sketch and InVision or Framer X                                    </li>
                                    <li>
                                        You have some previous experience working in an agile environment – Think two-week sprints.                                    </li>
                                    <li>
                                        You are familiar using Jira and Confluence in your workflow                                    </li>
                                </ul>

                            </section>

                            {/* ---------- job single Share this job ---------- */}
                            <section className='share-this-job'>
                                <span className='single-title'>Share this job</span>
                                <div className='share-btns'>
                                    <button className='share-btn-f'> <FaFacebookF /> <span> Facebook </span></button>
                                    <button className='share-btn-t'> <FaTwitter /> <span> Twiter </span></button>
                                    <button className='share-btn-l'> <FaLinkedin /> <span> Linkedin </span></button>
                                </div>
                            </section>

                            {/* ---------- job single related-jobs ---------- */}
                            <section className='related-jobs'>

                                <h2 className='related-title'>Related jobs</h2>
                                <p className='rel-p sec-txt'>2020 jobs live - 293 added today.</p>
                                <div className='rel-card-sec'>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/job/single'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/job/single'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>

                                    <section className='rel-card'>

                                        <div className='card-content'>

                                            <span className='comp-logo'>
                                                <img src={companyLogo} alt="company logo" />
                                            </span>
                                            <h4>
                                                <Link to={'/'} className='card-title'>
                                                    Software Engineer (Android), Libraries
                                                </Link>
                                            </h4>

                                            <ul className='job-info'>
                                                <li className="info-list">
                                                    <PiShoppingBagOpen className='i-display' />
                                                    <span>Segment</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <IoLocationOutline className='i-display' />
                                                    <span>London, UK</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <CiClock2 className='i-display' />
                                                    <span>11 hours ago</span>
                                                </li>
                                                <li className="job-info-list">
                                                    <LiaMoneyBillWaveSolid className='i-display' />
                                                    <span>$35k - $45k</span>
                                                </li>
                                            </ul>

                                            <ul className='job-other-info'>

                                                <li className="time">Time</li>
                                                <li className="privacy">Private</li>
                                                <li className="required">Urgent</li>

                                            </ul>

                                        </div>

                                        <div className='rel-card-bm'>
                                            <button className='rel-bm-i'><FiBookmark /></button>
                                        </div>


                                    </section>


                                </div>

                            </section>

                        </Col>

                        <Col className='space-y-10 right-side-col' lg={4} md={4} sm={12}>

                            {/* ---------- Apply for job and bookmark buttons -------- */}
                            <div className='emp-msg-bm-btn'>
                                {/* <div className='apply-for-btn'></div> */}
                                <button className='msg-btn-s-emp' onClick={handleShowModal}>Apply for job</button>
                                <button className='bm-btn-s-emp'><FiBookmark /></button>

                                {/* ----------- APPLY JOB MODAL -------------- */}
                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Send message to Stripe</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <input type="text" name="" id="" placeholder='Your Name' className='s-emp-name' />
                                        <textarea type="text" className='s-emp-message' placeholder='Message' required />
                                        <div className='s-emp-term'>
                                            <a href="#" className='s-emp-terms-link'>
                                                <span className='s-emp-terms-txt'> You accept our </span> <p className='s-emp-msg-hover-txt'> &nbsp; Terms and Conditions and Privacy Policy</p>
                                            </a>
                                        </div>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className='s-emp-send-msg' onClick={handleCloseModal}> Send message </button>
                                    </Modal.Footer>
                                </Modal>

                            </div>

                            <div className='flex-single'>

                                {/* ---------- Job Overview START -------- */}
                                <section className="job-typ">
                                    <span className='f-titles'>Job Overview</span>
                                    <ul className="filter-list overview-rows">

                                        <li className="job-list flex-start">
                                            <div className='o-content'>
                                                <CiCalendar className='o-icon' />
                                                <div className='o-date'>
                                                    <span className='overview-h'>Date Posted:</span>
                                                    <span className='overview-txt'>Posted 1 hours ago</span>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="job-list flex-start">
                                            <div className='o-content'>
                                                <PiHourglassLowFill className='o-icon' />
                                                <div className='o-date'>
                                                    <span className='overview-h'>Date Posted:</span>
                                                    <span className='overview-txt'>Posted 1 hours ago</span>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="job-list flex-start">
                                            <div className='o-content'>
                                                <IoLocationOutline className='o-icon' />
                                                <div className='o-date'>
                                                    <span className='overview-h'>Date Posted:</span>
                                                    <span className='overview-txt'>Posted 1 hours ago</span>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="job-list flex-start">
                                            <div className='o-content'>
                                                <AiOutlineUser className='o-icon' />
                                                <div className='o-date'>
                                                    <span className='overview-h'>Date Posted:</span>
                                                    <span className='overview-txt'>Posted 1 hours ago</span>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="job-list flex-start">
                                            <div className='o-content'>
                                                <PiClockCountdownLight className='o-icon' />
                                                <div className='o-date'>
                                                    <span className='overview-h'>Date Posted:</span>
                                                    <span className='overview-txt'>Posted 1 hours ago</span>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="job-list flex-start">
                                            <div className='o-content'>
                                                <RiCoinsLine className='o-icon' />
                                                <div className='o-date'>
                                                    <span className='overview-h'>Date Posted:</span>
                                                    <span className='overview-txt'>Posted 1 hours ago</span>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="job-list flex-start">
                                            <div className='o-content'>
                                                <LiaMoneyBillWaveSolid className='o-icon' />
                                                <div className='o-date'>
                                                    <span className='overview-h'>Date Posted:</span>
                                                    <span className='overview-txt'>Posted 1 hours ago</span>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </section>

                                {/* ---------- Job Location START -------- */}
                                <section className='location-sec'>

                                    <span className='f-titles'>Job Location</span>

                                    <div className='loc-map'>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.0728923992815!2d68.
                                            33833267531206!3d25.402370523360098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394
                                            c71003fbc4357%3A0x9c3e73c72da2b6c4!2sGMG%20Solutions!5e0!3m2!1sen!2s!4v1711105422547!5m2!1sen!2s"
                                            width="600" height="450" allowFullScreen loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        >

                                        </iframe>
                                    </div>

                                </section>

                                {/* ---------- TAGS SECTION START -------- */}
                                <section className='tags'>
                                    <span className='f-titles tag-h'>Job Skills</span>

                                    <ul className='filter-list flex-tag'>
                                        <li className='job-list tags-li'>App</li>
                                        <li className='job-list tags-li'>Administrative</li>
                                        <li className='job-list tags-li'>Android</li>
                                        <li className='job-list tags-li'>Wordpress</li>
                                        <li className='job-list tags-li'>Design</li>
                                        <li className='job-list tags-li'>React</li>
                                    </ul><br /><br />

                                </section>

                            </div>

                            <div className='flex-single'>

                                <section className='segment-sec'>

                                    <div className='seg-card-cont'>

                                        <span className='seg-comp-logo'>
                                            <img src={companyLogo} alt="company logo" />
                                        </span>
                                        <h5 className="segment-title">Segment</h5>
                                        <h6>
                                            <Link to={'/'} className='seg-info-link'>
                                                View company profile
                                            </Link>
                                        </h6>

                                    </div>

                                    <div className="seg-info">

                                        <div className='seg-company-info'>

                                            <span className='comp-title'>Primary industry:</span>
                                            <span className='comp-sec-title'>Software</span>

                                        </div>

                                        <div className='seg-company-info'>

                                            <span className='comp-title'>Company size:</span>
                                            <span className='comp-sec-title'>501-1,000</span>

                                        </div>

                                        <div className='seg-company-info'>

                                            <span className='comp-title'>Founded in:</span>
                                            <span className='comp-sec-title'>2011</span>

                                        </div>

                                        <div className='seg-company-info'>

                                            <span className='comp-title'>Phone:</span>
                                            <span className='comp-sec-title'>123 456 7890</span>

                                        </div>

                                        <div className='seg-company-info'>

                                            <span className='comp-title'>Email:</span>
                                            <span className='comp-sec-title'>info@joio.com</span>

                                        </div>

                                        <div className='seg-company-info'>

                                            <span className='comp-title'>Location:</span>
                                            <span className='comp-sec-title'>London, UK</span>

                                        </div>

                                        <div className='seg-company-info'>

                                            <span className='comp-title'>Social media:</span>
                                            <div className='social-icons'>
                                                <FaFacebookF className='social-i' />
                                                <FaTwitter className='social-i' />
                                                <IoLogoInstagram className='social-i' />
                                                <FaLinkedinIn className='social-i' />
                                            </div>

                                        </div>

                                        <button className='seg-ref-btn'>
                                            https://segment.com
                                        </button>


                                    </div>



                                </section>

                            </div>

                        </Col>

                    </Row>
                </Container>
            </div> <br /> <br /><br />


            {/* ----------------- Footer start -------------- */}
            <section className='footer-sec'>
                <FooterSection />
                <CopyRightFooter />
            </section>
            {/* *************** Footer end ***************** */}


        </div>
    )
}

export default JobSingle;