import React, { useState } from 'react';
import EmpDashHeader from '../Components/EmpDashHeader';
import EmpDashSideBar from '../Components/EmpDashSideBar';
import { Container, Row, Col } from 'react-bootstrap';
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";
import { FiBookmark } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SlHome } from "react-icons/sl";
import { LuUser2 } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { PiShoppingBagOpenDuotone } from "react-icons/pi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineBell } from "react-icons/ai";
import { GoLock } from "react-icons/go";
import { IoMdLogOut } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { LuMenu } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import msgUserImg from '../Assets/Images/msg-user-img.png';
import { Link, NavLink } from 'react-router-dom';
import logo from '../Assets/Images/logo.png';

import { DashCopyRightFooter } from '../Components/Footer';

const Messages = () => {

    const [show, setShow] = useState(false);
    const [contactShow, setContactShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const handleCloseContacts = () => setContactShow(false);
    const toggleContactShow = () => setContactShow((c) => !c);

    return (
        <div className='empdash-bg'>

            {/* --------------- Header start --------------- */}
            <EmpDashHeader />
            {/* ****************** Header end ****************** */}

            {/* --------------- Dashboard body start --------------- */}
            <Row>
                {/* --------------- SideBar start --------------- */}
                <Col lg={3} className='dash-sidebar-col' style={{ padding: '0px' }}>
                    <EmpDashSideBar />
                </Col>

                {/* --------------- Dashboard details start --------------- */}
                <Col lg={9} md={12} sm={12} className='dash-body-set'>
                    <div className="emp-dash-main">

                        <h2 className='emp-dash-main-h'>Messages!</h2>
                        <p className='emp-dash-main-p'>Ready to jump back in?</p>

                        {/* Offcanva Button */}
                        <button className='dash-menu-btn me-2' onClick={toggleShow}>
                            <HiOutlineMenuAlt3 />
                            <span>Menu</span>
                        </button>

                        {/* Offcanva */}
                        <Offcanvas show={show} onHide={handleClose} style={{ transition: '.3s ease-in-out', width: 320 }}>
                            <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                <Link to={'/'} className='dash-filter-side-menu-logo'>
                                    <img src={logo} alt="Logo" className='nav__logo' />
                                </Link>

                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='dash-dashSideBar'>
                                    <ul>
                                        <li><NavLink to={'/employer/dashboard'} className='dashSideLink space-x-5'><SlHome className='dash-link-icon-d' /> <span className='dash-link-txt'> Dashboard</span></NavLink></li>
                                        <li><NavLink to={'/company/profile'} className='dashSideLink space-x-5'><LuUser2 className='dash-link-icon-d' /> <span className='dash-link-txt'> Company Profile </span></NavLink></li>
                                        <li><NavLink to={'/post/job'} className='dashSideLink space-x-5'><VscSend className='dash-link-icon-d' /> <span className='dash-link-txt'> Post A New Job </span></NavLink></li>
                                        <li><NavLink to={'/manage/jobs'} className='dashSideLink space-x-5'><PiShoppingBagOpenDuotone className='dash-link-icon-d' /> <span className='dash-link-txt'> Manage Jobs </span></NavLink></li>
                                        <li><NavLink to={'/all/applicants'} className='dashSideLink space-x-5'><IoDocumentTextOutline className='dash-link-icon-d' /> <span className='dash-link-txt'> All Applicants </span></NavLink></li>
                                        <li><NavLink to={'/shortlisted/resumes'} className='dashSideLink space-x-5'><FiBookmark className='dash-link-icon-d' /> <span className='dash-link-txt'> Shortlisted Resumes </span></NavLink></li>
                                        <li><NavLink to={'/packages'} className='dashSideLink space-x-5'><PiPackage className='dash-link-icon-d' /> <span className='dash-link-txt'> Packages </span></NavLink></li>
                                        <li><NavLink to={'/messages'} className='dashSideLink space-x-5'><BiMessageDetail className='dash-link-icon-d' /> <span className='dash-link-txt'> Messages </span></NavLink></li>
                                        <li><NavLink to={'/resume/alerts'} className='dashSideLink space-x-5'><AiOutlineBell className='dash-link-icon-d' /> <span className='dash-link-txt'> Resume Alerts </span></NavLink></li>
                                        <li><NavLink to={'/change/password'} className='dashSideLink space-x-5'><GoLock className='dash-link-icon-d' /> <span className='dash-link-txt'> Change Password </span></NavLink></li>
                                        <li><NavLink to={'/signin'} className='dashSideLink space-x-5'><IoMdLogOut className='dash-link-icon-d' /> <span className='dash-link-txt'> Logout </span></NavLink></li>
                                        <li><NavLink to={'/register'} className='dashSideLink space-x-5'><RiDeleteBin6Line className='dash-link-icon-d' /> <span className='dash-link-txt'> Delete Profile </span></NavLink></li>
                                    </ul>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>

                        <Row>
                            {/* User Contacts Column  */}
                            <Col xl={5} lg={5} md={12} sm={12} className='msg-cont-users-col'>
                                <div className="dash-msg-sec">
                                    {/* Contact Search */}
                                    <div className="dash-contact-search">

                                        <div className="shortlist-search-bar">
                                            <IoSearchOutline className='shortlist-search-icon' />
                                            <input
                                                type="text"
                                                placeholder='Search'
                                                className="shortlist-search-inp"
                                                style={{ width: '100%' }}
                                            // onChange={(e) => handleChange(e)}
                                            />
                                        </div>

                                    </div>
                                    {/* Contacts User List */}
                                    <div className="dashMsg-user-contacts">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#">
                                                    <div className="dash-msg-contact">
                                                        <div className="msg-con-img-name">
                                                            <span className='dashMsg-cont-img'>
                                                                <img src={msgUserImg} alt="User Image" />
                                                            </span>
                                                            <div className='msg-con-info'>
                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                            </div>
                                                        </div>
                                                        <div className="msg-when-arrive">
                                                            <span className='msg-when-time'>35 mins</span>
                                                            <span className="msg-quantity">
                                                                <span>2</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>

                                </div>

                            </Col>

                            <Col xl={7} lg={7} md={12} sm={12}>
                                <div className="dash-msg-sec">

                                    {/* Chat Header */}
                                    <div className='msg-chat-header'>

                                        <div className="msg-con-img-name">
                                            <span className='dashMsg-cont-img'>
                                                <img src={msgUserImg} alt="User Image" />
                                            </span>
                                            <div className='msg-con-info'>
                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                <span className='msg-con-occupation'>Active</span>
                                            </div>
                                        </div>
                                        <div className='msg-del-menu'>
                                            <span className='msg-del-conversation'>Delete Conversation</span>

                                            {
                                                !contactShow ?
                                                    <LuMenu className='msg-chat-menue' onClick={toggleContactShow} />
                                                :
                                                    <IoClose className='msg-chat-menue' />

                                            }

                                            {/* Offcanva */}
                                            <Offcanvas show={contactShow} onHide={handleCloseContacts} style={{ transition: '.3s ease-in-out', width: 320, opacity: 3 }}>
                                                <Offcanvas.Header closeButton style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                    <div className="shortlist-search-bar">
                                                        <IoSearchOutline className='shortlist-search-icon' />
                                                        <input
                                                            type="text"
                                                            placeholder='Search'
                                                            className="shortlist-search-inp"
                                                            style={{ width: '100%' }}
                                                        // onChange={(e) => handleChange(e)}
                                                        />
                                                    </div>

                                                </Offcanvas.Header>
                                                <Offcanvas.Body>
                                                    <div className='dashMsg-user-contacts-menu'>
                                                        <ul>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <div className="dash-msg-contact">
                                                                        <div className="msg-con-img-name">
                                                                            <span className='dashMsg-cont-img'>
                                                                                <img src={msgUserImg} alt="User Image" />
                                                                            </span>
                                                                            <div className='msg-con-info'>
                                                                                <span className='msg-con-name'>Darlene Robertson</span>
                                                                                <span className='msg-con-occupation'>Head of Development</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="msg-when-arrive-menu">
                                                                            <span className='msg-when-time'>35 mins</span>
                                                                            <span className="msg-quantity">
                                                                                <span>2</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>

                                                </Offcanvas.Body>
                                            </Offcanvas>


                                        </div>

                                    </div>

                                    {/* Chat Body */}
                                    <div className="msg-chat-body">

                                        {/* Person Message */}
                                        <div className="msg-person-chat mb-2">

                                            <div className="msg-con-img-userName">
                                                <span className='dashMsg-cont-img'>
                                                    <img src={msgUserImg} alt="User Image" />
                                                </span>
                                                <div className='msg-con-user-info'>
                                                    <span className='msg-con-name'>Darlene Robertson</span>
                                                    <span className='msg-con-occupation'>35 mins</span>
                                                </div>
                                            </div>
                                            <div className="userMsg-chat">
                                                How likely are you to recommend our company to your friends and family?
                                            </div>

                                        </div>
                                        {/* Own Message */}
                                        <div className="msg-own-chat mb-2">
                                            <div className="msg-con-img-own-userName">
                                                <span className='dashMsg-cont-img'>
                                                    <img src={msgUserImg} alt="User Image" />
                                                </span>
                                                <div className='msg-con-user-own-info'>
                                                    <span className='msg-con-name'>You</span>
                                                    <span className='msg-con-occupation'>35 mins</span>
                                                </div>
                                            </div>
                                            <div className="userMsg-own-chat">
                                                How likely are you to recommend our company to your friends and family?
                                            </div>

                                        </div>

                                        {/* Person Message */}
                                        <div className="msg-person-chat mb-2">

                                            <div className="msg-con-img-userName">
                                                <span className='dashMsg-cont-img'>
                                                    <img src={msgUserImg} alt="User Image" />
                                                </span>
                                                <div className='msg-con-user-info'>
                                                    <span className='msg-con-name'>Darlene Robertson</span>
                                                    <span className='msg-con-occupation'>35 mins</span>
                                                </div>
                                            </div>
                                            <div className="userMsg-chat">
                                                How likely are you to recommend our company to your friends and family?
                                            </div>

                                        </div>
                                        {/* Own Message */}
                                        <div className="msg-own-chat mb-2">
                                            <div className="msg-con-img-own-userName">
                                                <span className='dashMsg-cont-img'>
                                                    <img src={msgUserImg} alt="User Image" />
                                                </span>
                                                <div className='msg-con-user-own-info'>
                                                    <span className='msg-con-name'>You</span>
                                                    <span className='msg-con-occupation'>35 mins</span>
                                                </div>
                                            </div>
                                            <div className="userMsg-own-chat">
                                                How likely are you to recommend our company to your friends and family?
                                            </div>

                                        </div>

                                        {/* Person Message */}
                                        <div className="msg-person-chat mb-2">

                                            <div className="msg-con-img-userName">
                                                <span className='dashMsg-cont-img'>
                                                    <img src={msgUserImg} alt="User Image" />
                                                </span>
                                                <div className='msg-con-user-info'>
                                                    <span className='msg-con-name'>Darlene Robertson</span>
                                                    <span className='msg-con-occupation'>35 mins</span>
                                                </div>
                                            </div>
                                            <div className="userMsg-chat">
                                                How likely are you to recommend our company to your friends and family?
                                            </div>

                                        </div>
                                        {/* Own Message */}
                                        <div className="msg-own-chat mb-2">
                                            <div className="msg-con-img-own-userName">
                                                <span className='dashMsg-cont-img'>
                                                    <img src={msgUserImg} alt="User Image" />
                                                </span>
                                                <div className='msg-con-user-own-info'>
                                                    <span className='msg-con-name'>You</span>
                                                    <span className='msg-con-occupation'>35 mins</span>
                                                </div>
                                            </div>
                                            <div className="userMsg-own-chat">
                                                How likely are you to recommend our company to your friends and family?
                                            </div>

                                        </div>

                                    </div>

                                    {/* Chat Footer */}
                                    <div className="msg-chat-footer">
                                        <div className='msg-chat-footer-card'>
                                            <form action="#" style={{ width: '100%' }}>
                                                <textarea className='chat-msg-type-area' placeholder='Type a message' required />
                                                <button type='submit' className='msg-chat-send-btn'>Send Message</button>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>


                    </div>

                    <DashCopyRightFooter />

                </Col>
            </Row>
            {/* ****************** Dashboard body end ****************** */}

        </div>
    )
}

export default Messages;